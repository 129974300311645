<template>
  <main class="page games-page" ref="root">
    <div class="page__body games-page__body">

      <div class="first-screen offset-section_small first-screen_left">
        <div class="_container">
          <!-- NAVIGATION ============================================= -->
          <BreadCrumbs :links="[{title: 'Games'}]"
          />
          <!-- NAVIGATION ============================================= END -->

          <!-- PAGE DESCRIPTION ======================================= -->
          <div class="first-screen__body">
            <div class="first-screen__left">
              <div class="page-heading page-heading_colors">
                <h1>
                  <span>3 OAKS <em>GAMES</em></span>
                </h1>
              </div>
              <div class="page-description page-description_offset _icon-arrow-2">
                <p>
                    3 Oaks slots are crafted with entertainment at the forefront. They seamlessly combine engaging mechanics, balanced maths, captivating graphics and immersive music, resulting in the ultimate experience.
                </p>
              </div>
            </div>
            <div class="first-screen__right"></div>
          </div>
          <!-- PAGE DESCRIPTION ======================================= END -->
        </div>
      </div>

      <div class="_container">
        <GamesGrid :user="user"/>
      </div>
    </div>
  </main>
</template>


<script>
import BreadCrumbs from '@/components/breadcrumbs';
import GamesGrid from './games_grid';

export default {
    name: 'Games',
    props: {
        user: Object,
        isClientArea: Boolean,
    },
    components: {
        BreadCrumbs,
        GamesGrid,
    },
}
</script>



