<template>
    <section class="ntp-tournament__prize-pool"
             v-if="tournament">
        <div class="ntp-prize-pool-content"
            :class="{'ntp-is-stand-mode': isDemoStand}">
            <h4 class="ntp-prize-pool-title">Prizepool</h4>
            <h3 class="ntp-prize-pool-amount">
              {{ tournament?.prize_pool_banner_text }}
            </h3>
        </div>
    </section>
</template>

<script>
export default {
  name: "TournamentCommonPrizePoolSection",
  props: {
    tournament: {},
    isDemoStand: {
        type: Boolean
    }
  },
}
</script>

