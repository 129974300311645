export const appFetch = (url, options) => {

    options = options || {};
    if (!options.headers) options.headers = {};
    options.headers['Content-Type'] = 'application/json';

    let params = options.params;
    let method = options.method || 'GET';
    if (params && method === 'GET') {
        url = url + '?' + new URLSearchParams(params);
    }

    // let token = options['token'] || Vue.$cookies.get('token')
    // if (token) {
    //     options.headers['Authorization'] = `Bearer ${token}`;
    // }

    return fetch(`${process.env.VUE_APP_API_URL}${url}`, options);

};

export const appFetchJSON = (url, options) => {

    options = options || {};
    if (!options.headers) {
        options.headers = {};
    }
    options.headers['Content-Type'] = 'application/json';
    options.headers['Accept'] = 'application/json';

    if (options.body) {
        options.body = JSON.stringify(options.body);
    }

    return appFetch(url, options)
        .then(res => {
            if (!res.ok) {
                let err = new Error('Response status is not 2xx');
                err.response = res;
                throw err;
            }
            return res.json();
        });
};


export const processFetchError = (router, fetchErr) => {
    if (fetchErr && fetchErr.hasOwnProperty('response')) {
        let res = fetchErr.response;
        let status = res ? res.status : '';
        if (status === 403) {
            router.replace({name: 'page-forbidden'});
        } else if (status === 404) {
            router.replace({name: 'page-not-found'});
        } else if (status === 500) {
            router.replace({name: 'server-error'});
        } else {
            // TODO: other errors handling
            router.replace({name: 'server-error'});
        }
    } else {
        console.log('Unable to process fetch error: ', fetchErr);
    }
};
