<template>
  <div>
    <div class="site-preloader" v-if="fullAnimation">
      <div class="site-preloader__wrapper"></div>
    </div>
    <div class="page-transition"
         :class="{'page-transition_enter': !fullAnimation}"
    >
      <div class="page-transition__wrapper">
        <div></div>
      </div>
    </div>
  </div>
</template>


<script>
import lottie from 'lottie-web';
import {gsap} from '@/assets/js/gsap.min';
import EventBus from '@/lib/event_bus';

export default {
    name: 'Preloader',

    data() {
        return {
            fullAnimation: false,
            userLoaded: false
        }
    },

    created() {
        const isFirstLoad =
            !document.referrer.startsWith(window.location.origin);
        const isResetPasword =
            window.location.pathname === '/reset_password_confirm';

        this.fullAnimation = isFirstLoad && !isResetPasword;
    },

    mounted() {
        let userLoadedHandler = () => {
            this.userLoaded = true;
            EventBus.emit(EventBus.EVENTS.BODY_UNLOCK_REQUEST);
            if (!this.fullAnimation) {
                this.showMinimalLoadAnimation();
            }
        }
        EventBus.on(EventBus.EVENTS.USER_LOADED, (data) => {
            setTimeout(userLoadedHandler, 0);
        });

        EventBus.on(EventBus.EVENTS.USER_LOADING_ERROR, (data) => {
            setTimeout(userLoadedHandler, 0);
        });

        EventBus.emit(EventBus.EVENTS.BODY_LOCK_REQUEST);
        if (this.fullAnimation) {
            this.showFirstLoadAnimation();
            setTimeout(() => {
                if (this.userLoaded) {
                    this.removePreloader();
                } else {
                    let t = setInterval(() => {
                        if (this.userLoaded) {
                            clearInterval(t);
                            this.removePreloader();
                        }
                    }, 200);
                }
            }, 3000);
        }
    },

    methods: {
        removePreloader() {
            this.$el.querySelector('.site-preloader')
                .classList.add('done');
        },

        showFirstLoadAnimation() {
            lottie.loadAnimation({
                container: document.querySelector(
                    '.site-preloader__wrapper'),
                path: '/preloader.json',
                renderer: 'svg',
                loop: false,
                autoplay: true,
            })
        },

        showMinimalLoadAnimation() {
            gsap.to('.page-transition__wrapper div', {
                duration: 1.8,
                scaleX: 0,
                clipPath: 'polygon(0 0, 100% 0, 70% 100%, 0% 100%)',
                transformOrigin: 'top left',
                stagger: .1,
                delay: 0.5
            })
            setTimeout(() => {
                document.documentElement.classList.remove(
                    'is-loading')
            }, 1800);

        }
    }
}
</script>