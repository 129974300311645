<template>
  <header>
    <div class="header no-print _lp" :class="{ _scroll: (pageYOffset > 10) }">
      <div class=" header__content _container">
        <div class="header__logo">
          <div class="burger-icon" @click="onBurgerClick"
               :class="{ _open: isMenuOpened }"
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="logo">
            <a href="/">
              <img src="@/assets/img/svg/logo.svg" alt="3OAKS">
            </a>
          </div>
        </div>
        <div class="header__menu">
          <nav class="menu">
            <ul class="menu__list">
              <li v-for="menuItem of menuList">
              <span class="split-link">
                <a :href="menuItem.path" class="menu__link normal"
                   :target="menuItem.target"
                >
                  {{ menuItem.title }}
                </a>
                <a :href="menuItem.path" class="menu__link _hover"
                   :target="menuItem.target"
                >
                  {{ menuItem.title }}
                </a>
              </span>
              </li>
            </ul>
          </nav>
        </div>
        <div class="header__action">
          <a v-if="user.is_authorized" class="logout-button _icon-back"
             @click="onLogoutBtnClick">Log out</a>
          <a v-if="!user.is_authorized" class="login-button"
             @click="onLoginBtnClick">LOGIN</a>
        </div>
      </div>
    </div>
    <div class="header print-only _lp">
      <div class="print-only header__content _container">
        <div class="header__logo">
          <div class="logo">
            <img src="@/assets/img/print-logo.png">
          </div>
        </div>
      </div>
    </div>
  </header>
</template>


<script>
import {gsap} from '@/assets/js/gsap.min';
import {ScrollTrigger} from '@/assets/js/ScrollTrigger.min';
import {showLoginModal} from '@/lib/modal_utils'
import Auth from '@/lib/auth';
import EventBus from "@/lib/event_bus";


export default {
    name: 'Header',
    props: {
        user: Object,
        menuList: Array,
        isMenuOpened: Boolean,
    },

    data() {
        return {
            pageYOffset: null,
            showAnim: undefined,
        }
    },

    watch: {
        isMenuOpened() {
            const method = this.isMenuOpened ? 'reverse' : 'play';
            this.toggleBurgerMenuAnimations.forEach((item) => {
                item[method]();
            });
        }
    },

    created() {
        EventBus.on(EventBus.EVENTS.HEADER_HIDE, () => {
            if (this.showAnim.ratio !== 1) {
                this.showAnim.reverse();
            }
        });
    },

    mounted() {
        this.showAnim = gsap.from('.header.no-print', {
            yPercent: -100,
            paused: true,
            duration: 0.2
        }).progress(1);

        const toggleBurgerMenuAnimationParams = {
            opacity: 0,
            paused: true,
            duration: 0.1,
        }

        this.toggleBurgerMenuAnimations = [
            gsap.from('.header__menu', toggleBurgerMenuAnimationParams).progress(1),
            gsap.from('.header__action', toggleBurgerMenuAnimationParams).progress(1)
        ];

        ScrollTrigger.create({
            start: "top top",
            end: 99999,
            onUpdate: (self) => {
                self.direction === -1 ? this.showAnim.play() : this.showAnim.reverse()
            }
        });

        this.pageYOffset = window.pageYOffset;
        window.addEventListener('scroll', this.onScroll.bind(this));
    },

    methods: {
        onScroll() {
            this.pageYOffset = window.pageYOffset;
        },

        onBurgerClick() {
            this.$emit('burgerBtnClick');
        },

        onLoginBtnClick() {
            showLoginModal();
        },

        onLogoutBtnClick() {
            Auth.logout({
                success: this.onLogoutSuccess,
                error: this.onLogoutError,
            });
        },

        onLogoutSuccess: function () {
            window.location.href = '/';
        },

        onLogoutError: function () {
            console.log('------------ TODO AUTH LOGOUT HANDLE ERROR!')
        },
    }
}
</script>
