<template>
  <main class="page news-page">
    <div class="page__body news-page__body">

      <div class="first-screen offset-section_small first-screen_left">
        <div class="_container">

          <!-- ==================================== BREADCRUMBS ========================================= -->
          <BreadCrumbs :links="breadCrumbsLinks"/>
          <!-- ==================================== BREADCRUMBS ========================================= -->

          <div class="first-screen__body">
            <div class="first-screen__left">
              <div class="page-heading page-heading_colors">
                <h1>
                  <span>LATEST <em>NEWS</em></span>
                </h1>
              </div>
              <div class="page-description page-description_offset _icon-arrow-2">
                <p>
                  Players love our slots! Our games are crafted with a precise attention to every detail of the
                  gameplay experience, maximizing players’ retention and casino profit.
                </p>
              </div>
            </div>
            <div class="first-screen__right"></div>
          </div>
        </div>
      </div>

      <div class="_container">
        <NewsList
            :user="user"
            :show-pagination="true"
        />
      </div>

    </div>
  </main>
</template>

<script>

import BreadCrumbs from "@/components/breadcrumbs";
import NewsList from "@/components/news_list";

export default {
    name: "News",
    components: {
        BreadCrumbs,
        NewsList
    },
    props: {
        user: Object,
    },
    data() {
        return {
            breadCrumbsLinks: [
                {
                    title: 'News',
                    href: this.$router.resolve({name: 'news'}).path
                }
            ],
        }
    },
}
</script>
