<template>
  <div class="page-partners">
    <div class="page-partners__container">

      <a class="page-partners__item" href="#">
        <div>
          <img src="/assets/img/partners/1.png" alt="3oaks">
        </div>
      </a>
      <a class="page-partners__item" href="#">
        <div>
          <img src="/assets/img/partners/2.png" alt="3oaks">
        </div>
      </a>
      <a class="page-partners__item" href="#">
        <div>
          <img src="/assets/img/partners/3.png" alt="3oaks">
        </div>
      </a>
      <a class="page-partners__item" href="#">
        <div>
          <img src="/assets/img/partners/4.png" alt="3oaks">
        </div>
      </a>
      <a class="page-partners__item" href="#">
        <div>
          <img src="/assets/img/partners/5.png" alt="3oaks">
        </div>
      </a>
      <a class="page-partners__item" href="#">
        <div>
          <img src="/assets/img/partners/6.png" alt="3oaks">
        </div>
      </a>

      <a class="page-partners__item" href="#">
        <div>
          <img src="/assets/img/partners/2.png" alt="3oaks">
        </div>
      </a>
      <a class="page-partners__item" href="#">
        <div>
          <img src="/assets/img/partners/5.png" alt="3oaks">
        </div>
      </a>
      <a class="page-partners__item" href="#">
        <div>
          <img src="/assets/img/partners/1.png" alt="3oaks">
        </div>
      </a>
      <a class="page-partners__item" href="#">
        <div>
          <img src="/assets/img/partners/4.png" alt="3oaks">
        </div>
      </a>
      <a class="page-partners__item" href="#">
        <div>
          <img src="/assets/img/partners/6.png" alt="3oaks">
        </div>
      </a>
      <a class="page-partners__item" href="#">
        <div>
          <img src="/assets/img/partners/3.png" alt="3oaks">
        </div>
      </a>

    </div>
  </div>
</template>

<!-- script>
export default {
    name: "PagePartners"
}
</script -->
