<template>
  <div id="app"
       :staff="user.is_staff? true : undefined"
       :mobile="isMobile? true : undefined"
       :desktop="!isMobile? true : undefined"
       :class="isFullScreen? 'fullscreen' : 'embedded'">

    <!-- PRELOADER  ============================= -->
    <Preloader/>
    <!-- PRELOADER  ============================= END -->

    <!-- FullScreenPage  ============================= -->
    <FullScreenPage
        v-if="isFullScreen === true"
        v-bind:user="user"/>
    <!-- FullScreenPage  ============================= END -->

    <!-- EmbdedPage  ============================= -->
    <EmbeddedPage
        v-if="isFullScreen === false"
        v-bind:user="user"/>
    <!-- EmbdedPage  ============================= END -->

    <!-- MODAL WINDOWS ROOT ===================== -->
    <ModalRoot />
    <!-- MODAL WINDOWS ROOT ===================== END -->

    <!-- ENTRY POINT ============================ -->
    <EntryPoint
        v-bind:user="user"
    />
    <!-- ENTRY POINT ============================ END -->

    <GDPRCookieBanner privacyNoticeURL="/privacy-notice" />
    <ResponsibleGamingView />
  </div>
</template>


<script>
import { useHead } from '@vueuse/head';

import '@/assets/css/style.scss';

import {isIE, isMozilla, isMobile, testWebP, ibg} from '@/lib/browser';
import EventBus from '@/lib/event_bus';

import Preloader from '@/components/layout/preloader';
import EmbeddedPage from '@/components/layout/embedded_page/_index';
import FullScreenPage from '@/components/layout/fullscreen_page/_index';
import ModalRoot from '@/components/layout/modal_root';
import EntryPoint from '@/components/layout/entry_point';

import GDPRCookieBanner from "@/components/gdpr_cookie_banner";
import ResponsibleGamingView from './components/responsible_gaming_view';


export default {
    name: 'App',
    components: {
        Preloader,
        EmbeddedPage,
        FullScreenPage,
        ModalRoot,
        EntryPoint,
        GDPRCookieBanner,
        ResponsibleGamingView,
    },

    data() {
        return {
            user: {
                is_staff: false,
                is_authorized: false
            },
            isFullScreen: null,
            isMobile: false
        }
    },

    watch: {
        $route(to_route) {
            this.isFullScreen = (to_route.meta || {}).fullScreen || false;
        }
    },

    setup() {
        useHead({
            meta:[{
                name: 'description',
                content: '' +
                    '3 Oaks Gaming distributes the best quality online ' +
                    'slots from new & existing high-value studios, ' +
                    'producing innovative concepts that will evolve the ' +
                    'online casino experience. For operators, we provide ' +
                    'a gateway to exciting new content and tools that will ' +
                    'propel your online offering'
            }],
            link: [{
                type: 'image/x-icon',
                rel: 'shortcut icon',
                href: require('@/assets/img/icons/favicon-ico-258x258.ico.png')
            }, {
                type: 'image/png',
                sizes: "16x16",
                rel: 'icon',
                href: require('@/assets/img/icons/favicon-16x16.png')
            }, {
                type: 'image/png',
                sizes: "32x32",
                rel: 'icon',
                href: require('@/assets/img/icons/favicon-32x32.png')
            }, {
                type: 'image/png',
                sizes: "48x48",
                rel: 'icon',
                href: require('@/assets/img/icons/favicon-48x48.png')
            }, {
                sizes: "180x180" ,
                rel: 'apple-touch-icon',
                href: require('@/assets/img/icons/apple-touch-icon-180x180.png')
            }, {
                color: "#088ECB",
                rel: 'mask-icon',
                href: require('@/assets/img/icons/safari-pinned-tab-16x16.svg')
            }]
        });
    },

    mounted() {
        EventBus.on(EventBus.EVENTS.USER_LOADED, (data) => {
            this.user = data;
        });
        EventBus.on(EventBus.EVENTS.USER_LOADING_ERROR, (data) => {
            this.user = data;
        });
        EventBus.on(EventBus.EVENTS.BODY_LOCK_REQUEST, (data) => {
            this.lockBody();
        });
        EventBus.on(EventBus.EVENTS.BODY_UNLOCK_REQUEST, (data) => {
            this.unlockBody();
        })

        if (isMobile.any()) {
            this.isMobile = true;
            document.querySelector('body').classList.add('_touch');
        }
        if (isIE()) {
            document.querySelector('body').classList.add('ie');
        }
        isMozilla();
        ibg();

    },

    methods: {
        lockBody() {
            let lock_padding = document.querySelectorAll('._lp');
            let wrapper = document.querySelector('.wrapper');
            let padding;
            if(wrapper) {
                padding = window.innerWidth - wrapper.offsetWidth + 'px';
            } else {
                padding = '0px';
            }

            for (let index = 0; index < lock_padding.length; index++) {
                lock_padding[index].style.paddingRight = padding;
            }
            document.body.style.paddingRight = padding;
            document.body.classList.add('_lock');
        },

        unlockBody() {
            let lock_padding = document.querySelectorAll('._lp');
            setTimeout(() => {
                for (let index = 0; index < lock_padding.length; index++) {
                    lock_padding[index].style.paddingRight = '0px';
                }
                document.body.style.paddingRight = '0px';
                document.body.classList.remove('_lock');
            }, 0);
        }
    }
}
</script>


<style lang="scss">

</style>

<!-- style>
body._lock .header {
    padding-right: 15px !important;
}
body._lock._popup .header__content {
    opacity: 0.4;
    pointer-events: none;
}

</style -->
=======
<style>
#app {
    height: 100%;
}
</style>

