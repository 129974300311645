<template>
  <div class="ntp-table-wrapper ntp-leaderboard" :class="{'ntp-is-stand-mode': isStandMode}">
      <table>
        <thead>
          <tr>
            <th class="ntp-cell-place"></th>
            <th class="ntp-cell-place">
                #
            </th>
            <th class="ntp-cell-player">
                Player
            </th>
            <th class="ntp-cell-score">
                Score
            </th>
            <th class="ntp-cell-prize">
                Prize
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in leaderboardData"
            :class="{top3: item.place <= 3, top10: item.place >= 4 && item.place <= 10}">
            <td class="ntp-cell-icon">
                <img v-if="item.place === 1" src="@/assets/img/tournaments/medal-1.png" class="ntp-table-icon" />
                <img v-if="item.place === 2" src="@/assets/img/tournaments/medal-2.png" class="ntp-table-icon" />
                <img v-if="item.place === 3" src="@/assets/img/tournaments/medal-3.png" class="ntp-table-icon" />
                <img v-if="item.place >= 4 && item.place <= 10" src="@/assets/img/tournaments/medal-common.png" class="ntp-table-icon" />
            </td>
            <td class="ntp-cell-place">
                {{ item.place }}
            </td>
            <td class="ntp-cell-player">
                {{ item.nick }}
            </td>
            <td class="ntp-cell-score">
                {{ item.score }}
            </td>
            <td class="ntp-cell-prize">
                <TournamentPrizeComponent :prize="item.prize"
                 :date-locale="dateLocale"
                 :date-time-zone="dateTimeZone" />
            </td>
          </tr>
        </tbody>
      </table>
  </div>
</template>

<script>
import TournamentPrizeComponent from "@/pages/tournament/components/tournament_prize_component";

export default {
    name: "TournamentLeaderboardComponent",

    components: {
        TournamentPrizeComponent,
    },

    props: {
        leaderboardData: {
            type: Array,
            default: [],
        },
        dateLocale: {
            type: String,
            default: undefined,
        },
        dateTimeZone: {
            type: String,
            default: undefined,
        },
        isStandMode: {
            type: Boolean
        }
    },
}
</script>

<style scoped>

</style>