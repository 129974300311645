<template>
    <div class="panel">
        <div class="bg-left"></div>
        <div class="bg-right"></div>
        <div class="state-logo">
            <img src="@/assets/img/game-verification/accorns-logo.svg" />
            <img class="text-logo" src="@/assets/img/game-verification/3oaks-gaming-text-logo.svg" />
            <img src="@/assets/img/game-verification/verification-error.svg" />
        </div>
        <div class="state-content">
            <div class="title">
                <img src="@/assets/img/game-verification/title-icon-error.svg" />
                <span>
                    Caution!
                    <br />
                    Game Is Non-Authentic
                </span>
            </div>
            <div>
                The game you are playing is an unauthorised duplicate and has no connection to 3&nbsp;Oaks&nbsp;Gaming.
                <br /><br />
                Please fill in the form below so we can address this issue.
            </div>
            <div class="feedback-form">
                <form class="form game-verification-feedback-form">
                    <div class="form-group">
                        <span class="helper" for="__all__"></span>
                    </div>

                    <div class="form-group" :class="{ '_error': !!formErrors.name }">
                        <label class="label" for="name">
                            Name <sup>*</sup>
                        </label>
                        <div class="form-control">
                            <input
                                type="text"
                                name="name"
                                placeholder="Your name"
                                v-model.trim="formData.name"
                                :disabled="requestInProgress"
                            />
                            <div class="error-text">
                                {{ (formErrors.name || []).join(', ') }}
                            </div>
                        </div>
                    </div>

                    <div class="form-group" :class="{ '_error': !!formErrors.email }">
                        <label class="label" for="email">
                            Email <sup>*</sup>
                        </label>
                        <div class="form-control">
                            <input
                                type="text"
                                name="email"
                                placeholder="example@gmail.com"
                                v-model.trim="formData.email"
                                :disabled="requestInProgress"
                            />
                            <div class="error-text">
                                {{ (formErrors.email || []).join(', ') }}
                            </div>
                        </div>
                    </div>


                    <div class="form-group" :class="{ '_error': !!formErrors.website }">
                        <label class="label" for="website">
                            Reported website <sup>*</sup>
                        </label>
                        <div class="form-control">
                            <input
                                type="text"
                                name="website"
                                placeholder="Reported website"
                                v-model.trim="formData.website"
                                :disabled="requestInProgress"
                            />
                            <div class="error-text">
                                {{ (formErrors.website || []).join(', ') }}
                            </div>
                        </div>
                    </div>

                    <div class="form-group" :class="{ '_error': !!formErrors.message }">
                        <label class="label" for="message">
                            Message <sup>*</sup>
                        </label>
                        <div class="form-control">
                            <textarea
                                name="message"
                                placeholder="Describe your request"
                                v-model.trim="formData.message"
                                :disabled="requestInProgress"
                            >
                            </textarea>
                            <div class="error-text">
                                {{ (formErrors.message || []).join(', ') }}
                            </div>
                        </div>
                    </div>

                    <div class="form__controls">
                        <div class="form__submit">
                            <button type="button" class="page-button" v-if="!requestInProgress"
                                :disabled="requestInProgress" @click="onSendMessageBtnClick">
                                <span></span><span>SEND</span><span></span>
                            </button>
                            <span v-if="requestInProgress" class="text-mc-lighten loading-dots">Sending</span>
                        </div>
                        <div class="form__policy">
                            <div class="checkbox" :class="{ _error: !!formErrors.agreement}">
                                <input
                                    id="formAgreement"
                                    type="checkbox"
                                    name="agreement"
                                    class="form__input checkbox__input _req"
                                    v-model="formData.agreement"
                                    :disabled="requestInProgress"
                                >
                                <label for="formAgreement" class="checkbox__label">
                                    <span>
                                        I agree to the terms of the
                                        <a href="/privacy-notice" target="_blank">privacy policy</a>
                                    </span>
                                </label>
                                <div class="error-text">
                                    {{ (formErrors.agreement || []).join(', ') }}
                                </div>
                            </div>
                        </div>
                    </div>

                </form>

            </div>
        </div>
    </div>
</template>

<script>

import { showMessageModal } from '@/lib/modal_utils';
import { appFetch } from '@/lib/request_utils';

export default {
    name: "GameVerificationInvalid",
    data() {
        return {
            requestInProgress: false,

            formData: {
                name: '',
                email: '',
                website: '',
                message: '',
                agreement: '',
            },

            formErrors: {
                name: '',
                email: '',
                website: '',
                message: '',
                agreement: '',
            }
        }
    },

    methods: {

        onSendMessageBtnClick() {
            const formData = this.formData;

            this.resetErrors();

            this.requestInProgress = true;

            let options = {
                method: 'POST',
                body: JSON.stringify({
                    name: formData.name,
                    email: formData.email,
                    website: formData.website,
                    message: formData.message,
                    agreement: formData.agreement
                }),
            }

            try {
                appFetch(`/games/verify/feedback`, options)
                    .then(res => res.json())
                    .then(res => {
                        this.onSendMessageSuccess(res);
                    })
                    .catch(this.onSendMessageError);
            } catch(e) {
                this.onSendMessageError();
                throw e;
            }

        },

        onSendMessageSuccess(res) {
            this.formErrors = res.errors || {};
            if (res.status === 'success') {
                showMessageModal({
                    title: 'Info',
                    text: 'Thank you for your request.<br />We will work on this issue.'
                });
                this.resetForm();
            }
            this.requestInProgress = false;
        },

        onSendMessageError() {
            showMessageModal({
                title: 'Oops! Something went wrong',
                text: 'We will work on fixing that right away.'
            });
            this.requestInProgress = false;
        },

        resetForm() {
            this.formData = {
                name: '',
                email: '',
                website: '',
                message: '',
                agreement: false,
            }
        },

        resetErrors() {
            this.formErrors = {
                name: '',
                email: '',
                website: '',
                message: '',
                agreement: '',
            }
        },



    }
};
</script>
