<template>
  <main class="page partners-page">
    <div class="page__body partners-page__body">

      <div class="first-screen offset-section_small first-screen_left">
        <div class="_container">

          <!-- ==================================== BREADCRUMBS ========================================= -->
          <BreadCrumbs :links="breadCrumbsLinks"/>
          <!-- ==================================== BREADCRUMBS ========================================= -->

          <div class="first-screen__body">
            <div class="first-screen__left">
              <div class="page-heading page-heading_colors">
                <h1>
                  <span>OUR <em>PARTNERS</em></span>
                </h1>
              </div>

              <div class="page-description _icon-arrow-2 page-description_offset">
                <p>
                  Players love our slots! Our games are crafted with a precise attention to every detail of the gameplay
                  experience, maximizing players’ retention and casino profit.
                </p>
              </div>
            </div>
            <div class="first-screen__right"></div>
          </div>
        </div>
      </div>

      <div class="_container">

        <PartnersListBlock/>

        <PartnersReviewsListBlock/>

      </div>


    </div>
  </main>
</template>

<script>
import BreadCrumbs from "@/components/breadcrumbs";
import {appFetchJSON, processFetchError} from "@/lib/request_utils";
import PartnersListBlock from "@/pages/partners/partners_list_block";
import PartnersReviewsListBlock from "@/pages/partners/partners_reviews_list_block";

export default {
    name: "Partners",
    components: {
        PartnersReviewsListBlock,
        PartnersListBlock,
        BreadCrumbs,
    },
    data() {
        return {
            breadCrumbsLinks: [
                {
                    title: 'Partners',
                    href: this.$router.resolve({name: 'partners'}).path,
                }
            ],
            reviews: [],
        }
    },
}
</script>

<style scoped>

</style>