<template>
  <div class="game-card">
    <div class="game-card__body">
      <a
        :class="{'disabled': !hasPage, 'game-card__image-preloading': isLoading}"
        :href="pageUrl"
        class="game-card__image _ibg"
        ref="image-container"
        >
      </a>
      <div class="game-card__info-preloading" v-if="!isDetailsHidden && isLoading"></div>
      <div class="game-card__info" v-if="!isDetailsHidden && !isLoading">
        <div>
            <span>
              <DateWidget :iso-date-string="releaseDate"/>
            </span>
        </div>
        <div class="game-card__download-links" v-if="!isDetailsHidden && !isLoading">
          <a
              v-if="guideUrl"
              :href="guideUrl"
              class="_icon-download">
            Guide
          </a>
          <a
              v-if="promoPackUrl"
              :href="promoPackUrl"
              class="_icon-download">
            Promo
          </a>
        </div>
      </div>
      <a
          :class="{'disabled': !hasPage, '_icon-arrow-3': hasPage}"
          :href="pageUrl"
          class="game-card__description"
          v-if="!isDetailsHidden && !isLoading"
        >
        <p v-html="text"></p>
      </a>
    </div>
  </div>
</template>


<script>
import {loadAndAppendImageAnimated} from "@/lib/common_utils";
import DateWidget from "@/components/date_widget";

export default {
    name: "GamesGridCard",
    components: {
        DateWidget,
    },
    props: {
        image: Image,
        text: String,
        hasPage: Boolean,
        pageUrl: String,
        releaseDate: String,
        guideUrl: String,
        promoPackUrl: String,
        isDetailsHidden: Boolean,
        isLoading: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        image(oldV, newV) {
            this.updateImg();
        }
    },

    mounted() {
        this.updateImg();
    },

    methods: {
        updateImg() {
            if (!this.image) {
                return;
            }
            const imgContainerEl = this.$refs["image-container"];
            imgContainerEl.innerHTML = '';
            imgContainerEl.appendChild(this.image);
        }
    }
}
</script>
