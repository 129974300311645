<template>
  <div class="icons-section">
    <div ref="swiper" class="icons-section__wrapper touch-slider _swiper">
      <div class="swiper-wrapper">
        <div class="icon-item swiper-slide">
          <div class="icon-item__head">
            <img src="@/assets/img/icons/icon-1.svg" alt="3oaks">
          </div>
          <div class="icon-item__title">
            <p>High quality content</p>
          </div>
          <p>
            Stunning quality art and high performance for player`s excitement
          </p>
        </div>
        <div class="icon-item swiper-slide">
          <div class="icon-item__head">
            <img src="@/assets/img/icons/icon-2.svg" alt="3oaks">
          </div>
          <div class="icon-item__title">
            <p>Robust & Reliable</p>
          </div>
          <p>Guarantee of a steady, secure and hassle free operations 365/24/7</p>
        </div>
        <div class="icon-item swiper-slide">
          <div class="icon-item__head">
            <img src="@/assets/img/icons/icon-3.svg" alt="3oaks">
          </div>
          <div class="icon-item__title">
            <p>Advanced backoffice</p>
          </div>
          <p>Going beyond your expectations</p>
        </div>
        <div class="icon-item swiper-slide">
          <div class="icon-item__head">
            <img src="@/assets/img/icons/icon-4.svg" alt="3oaks">
          </div>
          <div class="icon-item__title">
            <p>Powerfull Gamification</p>
          </div>
          <p>
            Unique & engaging promotools with precisely tailored UX & mechanics
          </p>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import Swiper from '@/assets/js/Swiper';
import {isMobile} from "@/lib/browser";

export default {
    name: "IconsSlider",
    props: {
        item: Object
    },

    data() {
        return {
            swiper: null
        }
    },

    mounted() {
        this.initSlider();
    },

    methods: {
        initSlider() {
            let swiper = new Swiper(this.$refs.swiper, {
                init: false,
                observer: true,
                observeParents: true,
                slidesPerView: 'auto',
                simulateTouch: true,
                grabCursor: true,
            });

            const matchMedia = window.matchMedia('(max-width: 1144px)')
            matchMedia.addListener(() => {
              swiper.init()
            })
            if(window.innerWidth <= 1144) {
              swiper.init()
            }
            if(isMobile.any()) {
              swiper.init()
            }
            this.swiper = swiper;

        },
    }
}
</script>
