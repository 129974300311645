<template>
  <div class="toolbar">
    <i class="toolbar_item material-icons"
       @click="clearStyles()">format_clear</i>
    <select class="toolbar_item"
            @change="setStyle('font-family', $event.target.value)"
    >
      <option value="Arial">Arial</option>
      <option value="Verdana">Verdana</option>
    </select>
    <select class="toolbar_item"
            @change="setStyle('font-size', $event.target.value)"
    >
      <option value="9px">9px</option>
      <option value="10px">10px</option>
      <option value="11px">11px</option>
      <option value="12px">12px</option>
      <option value="13px">13px</option>
      <option value="14px">14px</option>
      <option value="15px">15px</option>
      <option value="16px">16px</option>
      <option value="17px">17px</option>
      <option value="18px">18px</option>
      <option value="19px">19px</option>
      <option value="20px">20px</option>
    </select>
    <i class="toolbar_item material-icons"
       @click="setStyle('font-weight', 'bold')">format_bold</i>
    <i class="toolbar_item material-icons" ref="fontColor"
       data-k="color" data-v="">format_color_text</i>
    <i class="toolbar_item material-icons" ref="backColor"
       data-k="background-color" data-v="">format_color_fill</i>
  </div>
</template>

<script>
import jSuites from 'jsuites';
import jexcel from '@/lib/patches/jspreadsheet-ce';


export default {
    name: "Toolbar",

    data() {
        return {
            fontColorSuite: null,
            backColorSuite: null
        }
    },

    mounted () {
        let self = this;
        this.fontColorSuite = jSuites.color(this.$refs.fontColor, {
            doneLabel: '',
            resetLabel: '',
            onchange: function(o, v) {
                self.setStyle('color', v);
                self.clearJColorSelection();
            }
        });

        this.backColorSuite = jSuites.color(this.$refs.backColor, {
            doneLabel: '',
            resetLabel: '',
            onchange: function(o, v) {
                self.setStyle('background-color', v);
                self.clearJColorSelection();
            }
        });
    },

    methods: {
        clearStyles() {
            let grid = jexcel.current;
            if (!grid) {
                return;
            }
            let cells = grid.selectedCell;
            for (let i = 0; i < cells.length; i++) {
                cells[i] = parseInt(cells[i])
            }

            let beginC = cells[0] < cells[2] ? cells[0] : cells[2],
                beginR = cells[1] < cells[3] ? cells[1] : cells[3],
                endC = cells[0] < cells[2] ? cells[2] : cells[0],
                endR = cells[1] < cells[3] ? cells[3] : cells[1];

            for (let r = beginR; r <= endR; r++) {
                for (let c = beginC; c <= endC; c++) {
                    if (c > 0) {
                        grid.records[r][c].style.backgroundColor = '';
                        grid.records[r][c].style.color = '';
                        grid.records[r][c].style.fontWeight = '';
                        grid.records[r][c].style.fontSize = '';
                        grid.records[r][c].style.fontFamily = '';
                        //grid.records[r][c].setAttribute('style', '');
                        //grid.records[r][c].style.textAlign = 'center';
                    }
                }
            }
        },

        setStyle(k, v) {
            let obj = jexcel.current;
            if (obj) {
                obj.setStyle(obj.highlighted, k, v);
            }

        },

        clearJColorSelection() {
            this.$el.querySelectorAll('.jcolor-selected').forEach(el => {
                el.classList.remove('jcolor-selected');
            })
        }
    }
}
</script>

<style scoped>

</style>