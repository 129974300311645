<template>
  <div class="tournament-page__time-plate">
    <table v-if="isTournamentNew">
      <tr>
        <th><img src="@/assets/img/tournaments/start-date.png"/><span>Start</span></th>
        <th><img src="@/assets/img/tournaments/end-date.png"/><span>End</span></th>
      </tr>
      <tr>
        <td>
          <DateWidget
              :iso-date-string="tournament.start_at"
              :options="{day: 'numeric', month: 'numeric', year: 'numeric'}"
              :locale="dateLocale"
              :use-time-zone="dateTimeZone"
          />
        </td>
        <td>
          <DateWidget
              :iso-date-string="tournament.end_at"
              :options="{day: 'numeric', month: 'numeric', year: 'numeric'}"
              :locale="dateLocale"
              :use-time-zone="dateTimeZone"
          />
        </td>
      </tr>
      <tr class="bold">
        <td>
          <DateWidget
              :iso-date-string="tournament.start_at"
              :options="{hour: '2-digit', minute: '2-digit'}"
              :locale="dateLocale"
              :only-time="true"
              :use-time-zone="dateTimeZone"
          />
        </td>
        <td>
          <DateWidget
              :iso-date-string="tournament.end_at"
              :options="{hour: '2-digit', minute: '2-digit'}"
              :locale="dateLocale"
              :only-time="true"
              :use-time-zone="dateTimeZone"
          />
        </td>
      </tr>
      <tr class="bold">
        <td>
          <DateWidget
              :iso-date-string="tournament.start_at"
              :options="{timeZoneName: 'short'}"
              :locale="dateLocale"
              :only-time-zone="true"
              :use-time-zone="dateTimeZone"
          />
        </td>
        <td>
          <DateWidget
              :iso-date-string="tournament.end_at"
              :options="{timeZoneName: 'short'}"
              :locale="dateLocale"
              :only-time-zone="true"
              :use-time-zone="dateTimeZone"
          />
        </td>
      </tr>
    </table>

    <table v-if="isTournamentInProgress">
      <tr>
        <th><img src="@/assets/img/tournaments/end-date.png"/><span>Ends in</span></th>
      </tr>
      <tr>
        <td>
          <span v-if="timer">
            <b>{{ timerData.days }}</b> d : <b>{{ timerData.hours }}</b> h : <b>{{ timerData.minutes }}</b> m : <b>{{ timerData.seconds }}</b> s
          </span>
        </td>
      </tr>
    </table>

    <table v-if="isTournamentFinished">
      <tr>
        <th><img src="@/assets/img/tournaments/end-date.png"/><span>Finished</span></th>
      </tr>
      <tr>
        <td>
          <DateWidget
              :iso-date-string="tournament.end_at"
              :options="{day: 'numeric', month: 'numeric', year: 'numeric'}"
              :locale="dateLocale"
              :use-time-zone="dateTimeZone"
          />
        </td>
      </tr>
    </table>

  </div>
</template>

<script>

import DateWidget from "@/components/date_widget";

export default {
    name: "TournamentTimePlate",
    components: {
        DateWidget,
    },
    props: {
        tournament: Object,
        dateLocale: {
            type: String,
            default: undefined,
        },
        dateTimeZone: {
            type: String,
            default: undefined,
        }
    },

    data() {
        return {
            timerData: {
                total: 0,
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
                expired: false,
            },
            timer: undefined
        }
    },

    computed: {
        isTournamentNew() {
            const tournamentStatus = this.tournament?.status;
            return tournamentStatus === 'NEW'
                || tournamentStatus === 'PRE_NOTIFICATION';
        },

        isTournamentInProgress() {
            const tournamentStatus = this.tournament?.status;
            return tournamentStatus === 'STARTED' && !this.timerData.expired;
        },

        isTournamentFinished() {
            const tournamentStatus = this.tournament?.status;
            return tournamentStatus === 'EXPIRED'
                || tournamentStatus === 'POST_PROCESSING'
                || tournamentStatus === 'POST_NOTIFICATION'
                || tournamentStatus === 'FINISHED'
                || (tournamentStatus === 'STARTED' && this.timerData.expired);
        }
    },

    watch: {
        'tournament.status'(oldVal, newVal) {
            this.checkTimer();
        }
    },

    mounted() {
        this.checkTimer();
    },

    methods: {
        updateTimerData() {
            const total = Math.max(Date.parse(this.tournament.end_at) - Date.now(), 0);
            if (total > 0) {
                const days = Math.floor(total / (1000 * 60 * 60 * 24));
                const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
                const minutes = Math.floor((total / 1000 / 60) % 60);
                const seconds = Math.floor((total / 1000) % 60);
                this.timerData = {total, days, hours, minutes, seconds};
            } else if (!this.timerData.expired) {
                const timerData = this.timerData;
                timerData.total = 0;
                timerData.days = 0;
                timerData.hours = 0;
                timerData.minutes = 0;
                timerData.seconds = 0;
                timerData.expired = true;
            }
        },

        checkTimer() {
            if (this.isTournamentInProgress && !this.timer) {
                this.updateTimerData();
                this.timer = setInterval(() => {
                    if (!this.timerData.expired) {
                      this.updateTimerData();
                    } else {
                        clearInterval(this.timer);
                    }
                }, 1000);
            }
        },
    }


}
</script>

<style scoped>

</style>