<template>
  <div class="first-screen">
    <div class="_container">
      <div class="first-screen__body">
        <div class="first-screen__left">
          <div class="first-screen__slider _swiper">
            <div class="first-screen__slider-slide" v-for="obj of objects">
              <div class="page-heading page-heading_colors">
                <h1>
                  <span><em>GAMING</em></span>
                  <span>AT ITS BEST!</span>
                </h1>
              </div>
              <div class="page-description page-description_offset _icon-arrow-2">
                <p>
                Distributing premium player-favourite slots and promo tools from renowned high-value studios to licensed casino operators.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="first-screen__right">
          <div class="product-promo__slider">
            <div class="card-slider _swiper">
              <div class="card-slider__slide" v-for="obj of objects">
                <div class="game-card _overlay-none">
                  <a v-if="obj.has_page" class="game-card__body" :href="`/game/${obj.name}`">
                    <div class="game-card__image _ibg">
                      <img :src="obj.icon_file" alt="3oaks">
                    </div>
                    <div class="game-card__description _icon-arrow-3">
                      <p>{{ obj.title_text }}</p>
                    </div>
                  </a>
                  <div v-if="!obj.has_page" class="game-card__body">
                    <div class="game-card__image _ibg">
                      <img :src="obj.icon_file" alt="3oaks">
                    </div>
                    <div class="game-card__description">
                      <p>{{ obj.title_text }}</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="card-slider__pagging"></div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Swiper from '@/assets/js/Swiper';

export default {
    name: "MainBanner",
    components: {},
    props: ['objects'],

    watch: {
        objects(value) {
            if ( value.length > 0 ) {
                setTimeout(() => {
                    this.initSlider();
                }, 0)
            }
        }
    },
    methods: {
        _initSlider(slider) {
            if (!slider.classList.contains('swiper-build')) {
                let slider_items = slider.children;
                if (slider_items) {
                    for (let index = 0; index < slider_items.length; index++) {
                        let el = slider_items[index];
                        el.classList.add('swiper-slide');
                    }
                }
                let slider_content = slider.innerHTML;
                let slider_wrapper = document.createElement('div');
                slider_wrapper.classList.add('swiper-wrapper');
                slider_wrapper.innerHTML = slider_content;
                slider.innerHTML = '';
                slider.appendChild(slider_wrapper);
                slider.classList.add('swiper-build');
            }
        },

        initSlider() {

            // --- Banner settings
            const preloaderDelay = 0;
            // const preloaderDelay = 4000;
            // const delay = 333330;
            const delay = 4000;

            let left_slider = this.$el.querySelector('.first-screen__slider._swiper');
            let right_slider = this.$el.querySelector('.card-slider._swiper');

            this._initSlider(left_slider);
            this._initSlider(right_slider);

            let firstScreenTextSlider = new Swiper('.first-screen__slider', {
                init: false,
                observer: true,
                observeParents: true,
                slidesPerView: 1,
                autoHeight: true,
                speed: 2000,
                loop: true,
                effect: 'fade',
                allowTouchMove: false,
                fadeEffect: {
                    crossFade: true
                },
                slideActiveClass: '_active-slide',
            });

            let cardSlider = new Swiper('.card-slider', {
                init: false,
                observer: true,
                observeParents: true,
                slidesPerView: 1,
                simulateTouch: true,
                effect: 'fade',
                allowTouchMove: true,
                fadeEffect: {
                    crossFade: false
                },

                autoHeight: false,
                speed: 0,
                slideActiveClass: '_active-slide',
                autoplay: {
                    delay: delay,
                    disableOnInteraction: false
                },
                pagination: {
                    el: '.card-slider__pagging',
                    type: 'bullets',
                    clickable: true
                },
                edgeSwipeDetection: true
            });

            cardSlider.on('activeIndexChange', () => {
                const slides = cardSlider.slides

                if(cardSlider.realIndex < cardSlider.previousIndex) {
                    slides.forEach(slide => {
                        if(!slide.classList.contains('back')) {
                            slide.classList.add('back')
                        }
                    })
                } else {
                    slides.forEach(slide => {
                        if(slide.classList.contains('back')) {
                            slide.classList.remove('back')
                        }
                    })
                }
            })

            function setSlideClass() {
                const slider = cardSlider.el
                const slides = cardSlider.slides
                const slidesLength = slides.length - 1

                let secondSlide
                let thirdSlide

                slides.forEach(slide => {
                    if(slide.classList.contains('second')) {
                        slide.classList.remove('second')
                    }
                    if(slide.classList.contains('third'))  {
                        slide.classList.remove('third')
                    }
                })

                if(cardSlider.activeIndex === slidesLength || cardSlider.activeIndex === slidesLength - 1) {
                    if (slider.classList.contains('odd')) {
                        slider.classList.remove('odd')
                    }
                    slider.classList.add('even')
                } else {
                    if (slider.classList.contains('even')) {
                        slider.classList.remove('even')
                    }
                    slider.classList.add('odd')
                }

                if(cardSlider.activeIndex === slidesLength) {
                    secondSlide = slides[0];
                    thirdSlide = slides[1];
                } else if(cardSlider.activeIndex === slidesLength - 1) {
                    secondSlide = slides[cardSlider.activeIndex + 1];
                    thirdSlide = slides[0];
                } else {
                    secondSlide = slides[cardSlider.activeIndex + 1];
                    thirdSlide  = slides[cardSlider.activeIndex + 2];
                }

                secondSlide.classList.add('second')
                thirdSlide.classList.add('third')

            }
            cardSlider.on('slideChange', setSlideClass);

            function slideChangeAnimation () {
                const delayAutoplay = 3500;
                const progressDelay = delayAutoplay / 90;
                const bullets = document.querySelectorAll('.swiper-pagination-bullet');
                const activeBullet = document.querySelector('.swiper-pagination-bullet-active');

                firstScreenTextSlider.slideTo(cardSlider.activeIndex + 1)

                if(bullets && activeBullet) {
                    bullets.forEach(bullet => {
                        const circle = bullet.querySelector('.progress-ring');
                        if(circle !== null) {
                            circle.remove();
                        }
                    })
                    activeBullet.innerHTML = `
        <svg class="progress-ring" width="40" height="40">
          <circle class="progress-ring__circle" stroke="#fff" stroke-width="1" cx="20" cy="20" r="19" fill="transparent"/>
        </svg>
      `;

                    const circle = document.querySelector('.progress-ring__circle');
                    const radius = circle.r.baseVal.value;
                    const circumference = 2 * Math.PI * radius;

                    circle.style.strokeDasharray = `${circumference} ${circumference}`;
                    circle.style.strokeDashoffset = circumference;

                    function setProgress(percent) {
                        const offset = circumference - percent / 100 * circumference;
                        circle.style.strokeDashoffset = offset;
                    }
                    setProgress(0);

                    let i = 0;
                    let progress = setInterval(() => {

                        setProgress(i);
                        if(i === 100) {
                            clearInterval(progress)
                        }
                        i++;


                    }, progressDelay);
                }
            };

            function firstLoad() {
                const delayAutoplay = 3500;
                const progressDelay = delayAutoplay / 90;
                const bullets = document.querySelectorAll('.swiper-pagination-bullet');
                const activeBullet = document.querySelector('.swiper-pagination-bullet-active');

                if(bullets && activeBullet) {

                    activeBullet.innerHTML = `
      <svg class="progress-ring" width="40" height="40">
        <circle class="progress-ring__circle" stroke="#fff" stroke-width="1" cx="20" cy="20" r="19" fill="transparent"/>
      </svg>
    `;

                    const circle = document.querySelector('.progress-ring__circle');
                    const radius = circle.r.baseVal.value;
                    const circumference = 2 * Math.PI * radius;

                    circle.style.strokeDasharray = `${circumference} ${circumference}`;
                    circle.style.strokeDashoffset = circumference;

                    function setProgress(percent) {
                        const offset = circumference - percent / 100 * circumference;
                        circle.style.strokeDashoffset = offset;
                    }
                    setProgress(0);

                    let i = 31;
                    let progress = setInterval(() => {

                        setProgress(i);
                        if(i === 100) {
                            clearInterval(progress)
                        }
                        i++;

                    }, progressDelay);
                }

            };

            setTimeout(() => {
                cardSlider.init();
                firstScreenTextSlider.init();
                cardSlider.on('slideChange', slideChangeAnimation);
                firstLoad()
                setSlideClass()
            }, preloaderDelay)

        }
    }
}
</script>


<!-- style scoped>

.game-card__description p {
    text-transform: uppercase;
}

/*
.card-slider__slide._active-slide .game-card__body {
    filter: brightness(1) !important;
}*/
</style -->
