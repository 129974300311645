<template>
  <div class="game-card" >
    <a :class="{'disabled': !item.has_page}" class="game-card__body" :href="`/game/${item.name}`">
      <div class="game-card__image _ibg">
        <div class="game-card__labels">
          <div class="label label_date">
            <h4>{{ day }}</h4>
            <span>{{ month }}</span>
          </div>
        </div>
        <img :src="item.icon_file" alt="3oaks">
      </div>
      <div class="game-card__description" :class="{'_icon-arrow-3': item.has_page}">
        <p>{{ item.title_text }}</p>
      </div>
    </a>
  </div>
</template>


<script>
import {getDate} from "@/lib/common_utils";

export default {
    name: "GamesSliderCardWithDate",
    props: {
        item: {
            type: Object,
            default: {
                title_text: '',
                icon_file: '',
                release_date: '',
                has_page: false,
            }
        }
    },

    computed: {
        releaseDate() {
            return getDate(this.item.release_date);
        },

        day() {
            return this.getLocalizedDate(this.releaseDate, {'day': 'numeric'});
        },

        month() {
            return this.getLocalizedDate(this.releaseDate, {'month': 'short'});
        },
    },

    methods: {
        getLocalizedDate(date, options) {
            if (date instanceof Date) {
                return date.toLocaleDateString('en', options);
            }
        },
    },
}
</script>
