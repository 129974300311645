<template>
  <main class="page custom-page">
    <div class="page__body">
      <div class="first-screen offset-section_small first-screen_left">
        <div class="_container">
          <!-- BREAD CRUMBS ============================================= -->
          <BreadCrumbs :links="[{ title: breadCrumbTitle }]" />
          <!-- BREAD CRUMBS ========================================= END -->

          <!-- PAGE DESCRIPTION ======================================= -->
          <div class="first-screen__body">
            <div class="first-screen__left">
              <div class="page-heading page-heading_colors">
                <h1><span v-html="title"></span></h1>
              </div>
              <div
                v-if="showSummary"
                class="page-description page-description_offset _icon-arrow-2"
              >
                <p>
                  <slot name="summary"></slot>
                </p>
              </div>
            </div>
            <div class="first-screen__right"></div>
          </div>
          <!-- PAGE DESCRIPTION ======================================= END -->
        </div>
      </div>

      <div class="_container" v-if="documents?.length > 0">
        <div
          id="touch-slider-1"
          class="pdf-card-container touch-slider _swiper"
        >
          <div class="swiper-wrapper">
            <DocumentCard
              v-for="document of item['documents']"
              :title="document.title"
              :url="document.url"
              :show-card-title="true"
              class="swiper-slide"
              target="_blank"
              :show-badge="item['is_visible'] === false"
            />
          </div>
        </div>
      </div>

      <div class="_container offset-section">
        <div class="custom-page__body">
          <slot></slot>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import BreadCrumbs from "@/components/breadcrumbs";
import DocumentCard from "@/components/document_card";

export default {
  name: "StaticPage",
  components: {
    BreadCrumbs,
    DocumentCard,
  },

  props: {
    title: String,
    documents: {
        type: Array,
        default: []
    },
    showSummary: Boolean,

  },

  computed: {
    breadCrumbTitle() {
      const title = this.title || "";
      return title.replace(/(<([^>]+)>)/gi, "");
    },
  },
};
</script>
