<template>
  <!-- ROUTER ============================= -->
  <router-view :user="user" />
  <!-- ROUTER ============================= END -->
</template>

<script>


export default {
  name: 'FullScreenPage',
  props: {
    user: Object
  }
}
</script>
