<template>
  <router-view :user="user" is-client-area="true"></router-view>
</template>

<script>
export default {
    name: "ClientArea",
    props: ['user'],
}
</script>
