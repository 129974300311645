<template>
  <section class="offset-section_small">
    <div class="_container">
      <div class="section-container section-container_first">
        <div class="left-side">
          <h6>GIVE YOUR <span>PLAYERS</span> MORE!</h6>
        </div>
        <div class="right-side">
          <div class="contacts-switch">
            <p>
              Award your players with the best gaming experience!
              Start now with sending us a request for a live demo
            </p>
            <div class="contacts-switch__links">
              <a href="/contacts" class="_icon-arrow-2">Contact us today!</a>
              <!--
              <a href="contacts.html" class="_icon-arrow-2">
                Subscribe to our newsletter!
              </a>
              -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
export default {
    name: "ContactUs",
    components: {

    },
}
</script>
