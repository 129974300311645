<template>
  <div class="page-form">
    <form class="form">
      <div class="form__wrapper">
        <div class="form__item" :class="{ _error: !!errors.email }">
          <label class="form__label" for="formEmailLogin">
            Email
          </label>
          <input id="formEmailLogin" type="text" name="email"
                 class="form__input _email _req" placeholder="Your email"
                 v-model="email"
                 :disabled="requestInProgress"
                 :class="{ _error: !!errors.email }">
          <div class="form__error _icon-error">
            {{ (errors.email || []).join(', ') }}
          </div>
        </div>
      </div>
      <div class="form__controls">
        <div class="form__submit">
          <button
              v-if="!requestInProgress"
              type="button"
              class="page-button"
              :disabled="requestInProgress"
              @click="onResetBtnClick">
            <span></span>
            <span>Send Reset Password Request</span>
            <span></span>
          </button>
          <span
              v-if="requestInProgress"
              class="text-mc-lighten loading-dots">
            Sending request
          </span>
        </div>
      </div>
    </form>
  </div>
</template>


<script>
import Auth from '@/lib/auth';
import { showMessageModal } from '@/lib/modal_utils';

export default {
    name: "ForgotPasswordForm",

    data() {
        return {
            email: '',
            errors: {
                email: ''
            },
            requestInProgress: false,
        };
    },

    methods: {
        onResetBtnClick() {
            this.requestInProgress = true;
            Auth.sendEmailForReset({
                email: this.email,
                success: this.onResetSuccess,
                error: this.onResetError
            });
        },

        onResetSuccess(res) {
            this.errors = res.errors || {};
            if ( res.msg === 'mail sent') {
                showMessageModal({
                    title: 'Email sent',
                    text: "" +
                        "We've emailed you instructions for setting your " +
                        "password. If you don't receive an email, please " +
                        "make sure you've entered the address you " +
                        "registered with, and check your spam folder."
                });
            }
            this.requestInProgress = false;
        },

        onResetError(error) {
            console.log(error);
            showMessageModal({
                title: 'Oops! Something went wrong',
                text: "We will work on fixing that right away"
            });
            this.requestInProgress = false;
            console.log('------------ TODO AUTH RESET PASSWORD HANDLE ERROR!')
        }
    }
}
</script>

