<template>
  <div class="tournament-page__games-section" v-if="currentGame">
    <div class="tournament-page__section-title">Games</div>
    <div
        class="games-container"
        :class="{'one-game-mode': isOneGameMode, 'one-slide-swiper': isOneSlideSwiperMode(gamesChunked)}">
      <div class="main-game">
        <GameRunner
            v-if="isGameRunnerVisible"
            :banner="currentGame.banner_file"
            :languages="currentGame.languages"
            :gameUrl="currentGame.game_url"
            :lang="this.$route.query.lang || 'en'"
            :open-in-new-tab="true"
        />
        <div class="banner" v-if="!isGameRunnerVisible">
          <img :src="currentGame.banner_file"/>
          <span v-if="!player.is_authorized && isTournamentPlayable">
            Please <a href="" @click.prevent="onGameSectionLoginClick">login</a> to participate.
          </span>
          <span v-if="!isTournamentPlayable">
            Current campaign has been finished. <br />
            You may find more information about our products at <a :href="siteUrl">3Oaks</a>.<br/>
            Until the next time!
          </span>
        </div>

      </div>

      <div class="games-slider" v-if="isGamesSliderVisible"  :key="componentKey">
        <div class="_swiper swiper" ref="swiper">
          <div class="swiper-wrapper">
            <div
                class="swiper-slide slide"
                :class="{'one-row-mode': swiperOneRowMode}"
                v-for="chunk in gamesChunked"
            >
              <img
                  v-for="game in chunk"
                  :src="game.banner_file"
                  :class="{'active': game.name === currentGame.name}"
                  @click.prevent="onGameBannerClick(game)"
              />
            </div>
          </div>
          <div class="swiper-scrollbar" ref="swiperScrollbar"></div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import GameRunner from "@/components/game_runner";
import Swiper from "@/assets/js/Swiper";
import {settings} from "@/lib/settings";
import {nextTick} from "vue";

const TOURNAMENTS_PLAYABLE_STATUSES = ['NEW', 'PRE_NOTIFICATION', 'STARTED'];

export default {
    name: "TournamentGamesSection",
    components: {
        GameRunner,
    },
    props: {
        player: Object,
        tournament: Object,
    },

    emits: ['onLoginClick'],

    data() {
        return {
            currentGame: undefined,
            swiperOneRowMode: false,
            componentKey: 0,
        }
    },

    watch: {
        'tournament.main_game'() {
            this.initCurrentGame();
        },

        'tournament.games'() {
            this.initSlider();
        },

        componentKey() {
            this.$nextTick(() => this.initSlider())
        }
    },

    created() {
        window.addEventListener("resize", this.onResize);
    },

    destroyed() {
        window.removeEventListener("resize", this.onResize);
    },

    computed: {
        siteUrl() {
            const {protocol, port} = window.location;
            const portPostfix = port === '80' ? `:${port}` : '';
            return `${protocol}//${settings.SERVICE_NAME}${portPostfix}`;
        },

        isGameRunnerVisible() {
            if (!this.player.is_authorized) {
                return false;
            }
            return this.isTournamentPlayable;
        },

        isTournamentPlayable() {
            const tournamentStatus = this.tournament.status;
            return TOURNAMENTS_PLAYABLE_STATUSES.indexOf(tournamentStatus) >= 0;
        },

        isOneGameMode() {
            const tGames = this.tournament?.games || [];
            return tGames.length === 1;
        },

        isGamesSliderVisible() {
            const tournamentGames = this.tournament?.games || [];
            return tournamentGames.length > 1;
        },

        gamesChunked() {
            // rerender component hack
            this.componentKey;
            const result = [];
            const tGames = this.tournament?.games || [];
            // mock
            // if (tGames.length) {
            //     for (let l = 0; l < 30; l++) {
            //         tGames.push(tGames[l]);
            //     }
            // }

            this.swiperOneRowMode = this.isOneRowSwiperMode(tGames)

            if (this.swiperOneRowMode) {
                for (let i = 0; i <= tGames.length - 1; i++) {
                    result.push([tGames[i]]);
                }
            } else {
                for (let i = 1, ri = 0; i <= tGames.length; i++) {
                    if (result.length < ri + 1) {
                        result.push([]);
                    }
                    result[ri].push(tGames[i - 1]);
                    if (i % 3 === 0) {
                        ri++;
                    }
                }
            }
            console.log(result)
            return result;
        },
    },

    mounted() {
        this.initCurrentGame();
        this.initSlider();
    },

    methods: {

        initCurrentGame() {
            if (this.currentGame === undefined) {
                const mainGame = this.getMainGame();
                this.setCurrentGame(mainGame);
            }
        },

        getMainGame() {
            const tnmt = this.tournament || {};
            let game = tnmt['main_game'];
            if (game) {
                return game;
            }

            const tGames = tnmt.games || [];
            if (tGames.length) {
                game = tGames[0];
            }
            return game;
        },

        onGameBannerClick(game) {
            if (this.currentGame.name === game.name) {
                return;
            }
            this.setCurrentGame(game);
        },

        setCurrentGame(game) {
            this.currentGame = game;
        },

        onGameSectionLoginClick() {
            this.$emit('onLoginClick');
        },

        isOneRowSwiperMode(gamesList) {
            return window.innerWidth <= 992 && gamesList.length < 9;
        },

        isOneSlideSwiperMode(gamesList) {
            return !this.isOneRowSwiperMode(gamesList) && gamesList.length === 1;
        },

        onResize() {
            if (this.resizeTimeout) {
                clearInterval(this.resizeTimeout);
            }
            this.resizeTimeout = setTimeout(() => {
                this.componentKey++;
                // this.initSlider();
                this.resizeTimeout = undefined;
            }, 600);
        },

        initSlider() {
            if (this.swiper) {
                this.swiper.destroy();
            }
            if (!this.isGamesSliderVisible) {
                return;
            }
            nextTick(() => {
                this.swiper = new Swiper(
                    this.$refs.swiper,
                    {
                        observer: true,
                        observeParents: true,
                        slidesPerView: 2.6,
                        simulateTouch: true,
                        grabCursor: true,
                        autoHeight: true,
                        calculateHeight: true,
                        scrollbar: {
                            el: this.$refs.swiperScrollbar,
                            draggable: false,
                            // dragSize: dragSize
                        },
                        breakpoints: {
                            360: {
                                spaceBetween: 10,
                                slidesPerView: 2.55,
                            },
                            480: {
                                spaceBetween: 12,
                                slidesPerView: 3.35,
                            },
                            641: {
                                spaceBetween: 16,
                                slidesPerView: 4.4,
                            },

                            769: {
                                spaceBetween: 16,
                                slidesPerView: 3.5,
                            },

                            993: {
                                spaceBetween: 20,
                                slidesPerView: 1.1,
                            },
                            1366: {
                                spaceBetween: 32,
                                slidesPerView: 1.2,
                            },
                            1536: {
                                spaceBetween: 32,
                                slidesPerView: 1.4,
                            },
                            1728: {
                                spaceBetween: 32,
                                slidesPerView: 1.6,
                            },
                            1920: {
                                spaceBetween: 32,
                                slidesPerView: 2.2,
                            },
                            2048: {
                                spaceBetween: 32,
                                slidesPerView: 2.5,
                            }
                        }
                    }
                );
            });
        },
    }
}
</script>

<style scoped>
</style>