<template>
  <main class="page roadmap-user">
    <div class="page__body roadmap-user__body">

      <div class="first-screen offset-section_small first-screen_full">
        <div class="_container">

          <!-- ==================================== BREADCRUMBS ========================================= -->
          <Breadcrumbs :links="breadCrumbsLinks"/>
          <!-- ==================================== BREADCRUMBS ========================================= -->

          <div class="first-screen__body">
            <div class="first-screen__left">
              <div class="page-heading page-heading_colors">
                <h1>
                  <span>PRODUCT <em>ROADMAP</em></span>
                </h1>
              </div>

              <div class="page-description _icon-arrow-2 page-description_offset">
                <p>
                  We are showing our roadmap because we want our players & partners know that we still have much of
                  delight & fun to offer in the coming future. We guarantee consistently growing excitement from our
                  games!
                </p>
              </div>
            </div>
            <div class="first-screen__right"></div>
          </div>
        </div>
      </div>

      <!-- =================================== PAGE SLIDER ===================================  -->
      <ProductRoadmapComingSoonBlock/>
      <!-- =================================== PAGE SLIDER ===================================  -->

      <!-- =================================== NEWS-TABS SECTION ===================================  -->
      <div class="_container offset-section">
        <NewsList
            :user="user"
            :title-html="titleHtml"
            :show-pagination="false"
            item-per-page-odd="3"
            item-per-page-even="2"
        />

        <a :href="newsPageUrl" class="page-button">
          <div></div>
          <div>see all</div>
          <div></div>
        </a>
      </div>

      <!-- =================================== NEWS-TABS SECTION ===================================  -->

    </div>
  </main>
</template>

<script>

import Breadcrumbs from "@/components/breadcrumbs";
import NewsList from "@/components/news_list";
import ProductRoadmapComingSoonBlock from "@/pages/product_roadmap_auth/product_roadmap_coming_soon_block";

export default {
    name: "ProductRoadmapAuth",
    components: {
        Breadcrumbs,
        NewsList,
        ProductRoadmapComingSoonBlock,
    },

    props: {
        user: Object,
    },

    computed: {
        breadCrumbsLinks() {
            return [
                {
                    title: 'Product Roadmap',
                    href: this.$router.resolve({name: 'client-area-product-roadmap'}).path
                }
            ]
        },

        titleHtml() {
            return "What's <span>new</span>";
        },

        newsPageUrl() {
            const route = this.$router.resolve({'name': 'news'});
            return route.fullPath;
        }
    }
}
</script>

<style scoped>

</style>