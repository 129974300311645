<template>
  <div class="campaign-card game-card game-card_gradient">
    <a class="game-card__body" :href="campaign.url">
      <div class="game-card__image _ibg lbg">
        <div class="game-card__period">
          <span>
            <DateWidget
                :iso-date-string="campaign['start_date']"
                :options="dateOptions"
            />
             -
            <DateWidget
                :iso-date-string="campaign['end_date']"
                :options="dateOptions"
            />
          </span>
          <span class="badge" v-if="isUnpublished()">unpublished</span>
        </div>
        <!--            <img :src="item.logo" alt="3oaks">-->
        <div ref="logoImage" :class="{'opaque-logo':isUnpublished()}"/>
      </div>
      <div class="game-card__description _icon-arrow-3">
        <p v-html="campaign.name"></p>
      </div>
    </a>
  </div>
</template>

<script>

import DateWidget from "@/components/date_widget";

export default {
    name: "CampaignGridCard",
    components: {
        DateWidget,
    },
    props: {
        campaign: Object,
    },
    watch: {
        'campaign.logoImage'() {
            this.updateLogoEl();
        }
    },
    data() {
        return {
            dateOptions: {day: 'numeric', month: 'short', timeZone: 'UTC'}
        }
    },
    mounted() {
        this.updateLogoEl();
    },

    methods: {
        updateLogoEl() {
            this.$refs.logoImage.replaceChildren(this.campaign.logoImage);
        },
        
        isUnpublished() {
            return this.campaign['is_visible'] === false;
        }
    }
}
</script>

<style scoped>
.lbg {
    background: linear-gradient(180deg, rgba(35, 21, 123, 0.2) 0%, rgba(183, 40, 57, 0.2) 100%), #000000;
}

.opaque-logo {
    opacity: 0.3;
}

</style>