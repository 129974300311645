<template>
  <div class="entry-point">
    <div v-if="user.is_staff" class="entry-point-item">
      <a class="entry-point-admin" href="/admin/" target="_blank">A</a>
    </div>
    <div class="tournament"></div>
  </div>
</template>

<script>
export default {
    name: 'EntryPoint',
    props: {
        user: Object
    },
}
</script>
