<template>
  <main class="page not-found-page">
    <div class="_container">
      <div class="not-found-page__wrapper">
        <img src="@/assets/img/svg/404.svg" alt="3oaks">
        <h4>Oops! Page not found.</h4>
        <p>The page you are looking for cannot be found.
          Meanwhile, you may return to main page.</p>
        <a href="/" class="page-button">
          <div></div><div>BACK TO HOME</div><div></div>
        </a>
      </div>
    </div>
  </main>
</template>


<script>
export default {
    name: 'Error404',
    components: {},
}
</script>
