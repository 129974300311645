<template>
  <section class="ntp-tournament__showcase-and-login"
           :class="{
                'ntp-player-auth-or-stand-mode': (player.is_authorized || isDemoStand),
                'ntp-is-stand-mode': isDemoStand
           }">
    <div class="ntp-showcase">
      <div class="ntp-showcase-decoration">
        <div class="ntp-showcase-decoration-gradient"
            :class="{'ntp-is-stand-mode': isDemoStand}"></div>
        <div class="ntp-showcase-titles" :class="{'ntp-is-stand-mode': isDemoStand}">
          <h1 class="ntp-text" :class="{'ntp-is-stand-mode': isDemoStand}">
            <span class="ntp-text-3oaks">
                <span v-if="tournamentTitle.single !== null">
                    {{ tournamentTitle.single }}
                </span>
                <span
                    v-if="tournamentTitle.single === null"
                    class="ntp-3oaks-white">{{ tournamentTitle.first }}</span>
                <span
                    v-if="tournamentTitle.single === null"
                    class="ntp-3oaks-yellow">{{ tournamentTitle.second }}</span>
            </span>
          </h1>
        </div>
        <div class="ntp-decoration-banner"
             :class="{'ntp-is-stand-mode': isDemoStand}"
             :style="bannerStyle"></div>
      </div>
    </div>
    <div class="ntp-brown-decor" :class="{'ntp-is-stand-mode': isDemoStand}"></div>
    <TournamentLoginFormComponent
        v-if="!isDemoStand"
        :player="player"
        :tournament="tournament"
    />
  </section>
  <section class="ntp-tournament__countdown-container">
    <TournamentTimePlateComponent
      v-if="!isDemoStand"
      :tournament="tournament"
      :date-locale="dateLocale"
      :date-time-zone="dateTimeZone"
      :is-player-auth="player.is_authorized"
      :is-demo-stand="false"
    />
    <TournamentTimePlateComponent
      v-if="isDemoStand"
      :tournament="tournament"
      :date-locale="dateLocale"
      :date-time-zone="dateTimeZone"
      :is-player-auth="player.is_authorized"
      :is-demo-stand="true"
    />
  </section>
</template>

<script>
import TournamentTimePlateComponent from "@/pages/tournament/components/tournament_time_plate_component";
import TournamentLoginFormComponent from "@/pages/tournament/components/tournament_login_form_component";

export default {
    name: "TournamentHeaderSection",
    props: {
        tournament: {},
        player: {},
        dateLocale: {
            type: String,
            default: undefined,
        },
        dateTimeZone: {
            type: String,
            default: undefined,
        },
        isDemoStand: {
          type: Boolean
        }
    },

    components: {
        TournamentTimePlateComponent,
        TournamentLoginFormComponent,
    },

    computed: {
        tournamentTitle() {
            const titles = {
                single: null,
                first: null,
                second: null
            }
            const rawTitle = this.tournament?.title || '3OAKS Tournament';
            const words = rawTitle.split(' ')

            if (words.length > 1) {
                return {
                    ...titles,
                    first: words.slice(0, -1).join(" "),
                    second: words[words.length - 1]
                }
            } else {
                return {
                    ...titles,
                    single: rawTitle
                }
            }
        },

        // tournamentDescription() {
        //     let description = this.tournament?.description || 'Are you ready?';
        //     if (!this.tournament.is_published) {
        //         description = `${description} (NOT PUBLISHED)`;
        //     }
        //     return description;
        // },

        bannerStyle() {
            const bannerURL = this.tournament.banner_url;
            if (bannerURL) {
                return [
                    `background-image: radial-gradient(circle at center, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.97) 100%), url("${bannerURL}");`,
                    'background-size: cover;',
                    'background-repeat: no-repeat;',
                    'background-position: center center;'
                ].join(" ");
            }
            return ''
        },
    }
}
</script>

