<template>
  <main class="page custom-page" v-if="item.slug">
    <div class="page__body">
      <div class="first-screen offset-section_small first-screen_left">
        <div class="_container">
          <!-- BREAD CRUMBS ============================================= -->
          <BreadCrumbs
              :links="[{title: breadCrumbTitle}]"
          />
          <!-- BREAD CRUMBS ========================================= END -->

          <!-- PAGE DESCRIPTION ======================================= -->
          <div class="first-screen__body">
            <div class="first-screen__left">
              <div class="page-heading page-heading_colors">
                <h1><span v-html="itemTitle"></span></h1>
              </div>
              <div
                  v-if="item.summary"
                  class="page-description page-description_offset _icon-arrow-2">
                <p v-html="item.summary"></p>
              </div>
            </div>
            <div class="first-screen__right"></div>
          </div>
          <!-- PAGE DESCRIPTION ======================================= END -->
        </div>
      </div>

      <div class="_container" v-if="item['documents']?.length > 0">
        <div id="touch-slider-1" class="pdf-card-container touch-slider _swiper">
          <div class="swiper-wrapper">
            <DocumentCard
                v-for="document of item['documents']"
                :title="document.title"
                :url="document.url"
                :show-card-title="true"
                class="swiper-slide"
                target="_blank"
                :show-badge="item['is_visible'] === false"
            />
          </div>
        </div>
      </div>

      <div class="_container offset-section">
        <div class="custom-page__body" v-html="item.body"></div>
      </div>
    </div>
  </main>
</template>

<script>

import BreadCrumbs from "@/components/breadcrumbs";
import DocumentCard from "@/components/document_card";
import {appFetchJSON, processFetchError} from "@/lib/request_utils";

const DOCUMENT_TITLE_BY_TYPE = {
    'custom_page_tc': 'Terms & Conditions',
    'custom_page_promo': 'Promo Pack',
}

export default {
    name: 'CustomPageAuth',
    components: {
        BreadCrumbs,
        DocumentCard,
    },

    data() {
        return {
            item: {}
        }
    },

    mounted() {
        this.fetchItem();
    },

    methods: {
        fetchItem() {
            const slug = this.$route.params.slug;
            const url = `/custom-pages/${slug}`;
            return appFetchJSON(url)
                .then(res => {
                    const item = res.data.item;
                    const documents = item['documents'] || [];
                    documents.forEach((doc) => {
                        doc.title = DOCUMENT_TITLE_BY_TYPE[doc.type] || doc.title;
                    });
                    this.item = item;
                })
                .catch(err => {
                    processFetchError(this.$router, err);
                });
        }
    },

    computed: {
        breadCrumbTitle() {
            const title = this.item.title || '';
            return title.replace( /(<([^>]+)>)/ig, '');
        },

        itemTitle() {
            const title = this.item.title || '';
            if (title && !this.item['is_published']) {
              return `${title} (not published)`;
            }
            return title;
        }
    }
}
</script>