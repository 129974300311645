<template>
  <main>
    <div objname="restrictions_countries" class="restrictions-table">
      <SummaryGrid
        v-if="countries !== null"
        :options="countries"
        :user="user" />
    </div>
    <div objname="restrictions_currencies" class="restrictions-table">
      <SummaryGrid
        v-if="currencies !== null"
        :options="currencies"
        :user="user" />
    </div>
  </main>
</template>

<script>
import { appFetch } from '@/lib/request_utils';
import SummaryGrid from './grid';
import {isMobile} from "@/lib/browser";

const useFixedColumns = !isMobile.any();

let fullSchema = [
    {
        "name": "uid",
        "type": "text",
        "title": "pk",
        "width": "0"
    },
    {
        "name": "code",
        "type": "text",
        "title": "Code",
        "width": "100"
    },
    {
        "name": "name",
        "type": "text",
        "title": "Name",
        "width": "200",
        "cssClass": "restriction-cell-name"
    }
]

export default {
  name: 'SummaryTabRestrictions',
  components: {SummaryGrid},
  props: {
    user: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      tab: 'restrictions',
      currencies: null,
      countries: null
    }
  },
  mounted() {
    this.loadRestrictions();
  },
  methods: {
    loadRestrictions() {
      appFetch(`/summary/restrictions/`, {
          method: 'GET',
          credentials: 'include',
          params: {
          }
      }).then(
          response => response.json()
      ).then(response => {
        const schema = this.prepareSchema(response.data)
        this.countries = {...{
          title: 'COUNTRIES',
          csvFileName: 'restrictions_countries',
          pkColumn: 'uid',
          fixedColumnsNumber: useFixedColumns ? 1 : 0,
          activeTab: 'restrictions_countries',
          gridID: 'restrictions_countries',
          data: schema.countries,
          columns: schema.columns,
          nestedHeaders: schema.nestedHeaders
        }}
        this.currencies = {...{
          title: 'CURRENCIES',
          csvFileName: 'restrictions_currencies',
          pkColumn: 'uid',
          fixedColumnsNumber: useFixedColumns ? 1 : 0,
          activeTab: 'restrictions_currencies',
          gridID: 'restrictions_currencies',
          data: schema.currencies,
          columns: schema.columns,
          nestedHeaders: schema.nestedHeaders
        }}
      })
    },
    prepareSchema(response) {
      const {countries, currencies, columns} = response
      let allowedSchema = [],
          nestedHeaders = []

      fullSchema.forEach(cell => {
          if (columns.includes(cell.name)) {
              allowedSchema.push({...{}, ...cell})
              nestedHeaders.push({
                  colspan: 1,
                  title: "&nbsp;",
                  width: cell['width']
              }) ;
          }
      });

      return {
          columns: allowedSchema,
          nestedHeaders,
          countries,
          currencies
      }
    }
  },
}
</script>

<style>
.restrictions-table {
  float: left;
}
</style>