<template>
  <main class="page home-page">
    <div class="page__body home-page__body">

      <!-- MAIN BANNER =========================================== -->
      <section class="home-page__first-screen">
        <MainBanner :objects="bannerObjects"/>
      </section>
      <!-- MAIN BANNER =========================================== END -->

      <!-- COMPANY VISION ======================================== -->
      <section class="company-vision-block home-page__info-icons info-icons-section offset-section">
        <div class="_container">
          <h6 class="full-width"><span>The RAPIDLY GROWING<br />DISTRIBUTOR</span></h6>
          <div>
              3 Oaks Gaming is a rapidly growing iGaming distributor that works alongside renowned high-value studios to deliver premium easily-accessible iGaming solutions across regulated markets. With our dedication to top-quality player-preferred entertainment, we empower casinos to captivate audiences and unlock massive revenue potential in any market.
          </div>
          <IconsSlider/>
        </div>
      </section>
      <!-- COMPANY VISION ======================================== END -->

      <!-- GAMES LATEST RELEASES ================================= -->
      <MoveLine :title="'OUR SLOTS'" v-if="latestGames.length">
        <span>LATEST RELEASES</span>
        <span><img src="@/assets/img/svg/move-1.svg" alt="3oaks"></span>
        <span>LATEST RELEASES</span>
        <span><img src="@/assets/img/svg/move-2.svg" alt="3oaks"></span>
        <span>LATEST RELEASES</span>
        <span><img src="@/assets/img/svg/move-3.svg" alt="3oaks"></span>
      </MoveLine>
      <div class="offset-section _container" v-if="latestGames.length">
        <GamesSlider
            :games="latestGames"
            :sliderCardComponent="GameSliderCard"
        />
      </div>
      <!-- GAMES LATEST RELEASES ================================= END -->

      <!-- GAMES COMING SOON ===================================== -->
      <section v-if="hasComingSoonGames" class="offset-section _container">
        <h6 class="offset-title">COMING <span>SOON</span></h6>
        <GamesSlider
            :games="comingSoonGames"
            :sliderCardComponent="GameSliderCardWithDate"
        />
      </section>
      <!-- GAMES COMING SOON ===================================== END -->

      <!-- NEWS LATEST =========================================== -->
      <LatestNewsBlock/>
      <!-- NEWS LATEST =========================================== END -->

      <!-- PARTNERS BLOCK ======================================== -->
      <!-- section class="home-page__partners-section offset-section">
        <div class="_container">
          <h3 class="offset-title">
            Get to know more about partner companies. To become our partner,
            contact our team.
          </h3>

          <PartnersBlock />

          <a href="/partners" class="page-button">
            <div></div><div>view all</div><div></div>
          </a>

        </div>
      </section -->
      <!-- PARTNERS BLOCK ======================================== END -->

      <!-- VACANCIES BLOCK ======================================= -->
      <div class="home-page__move-lines" v-if="vacancies.length > 0">
        <MoveLine :title="'JOIN OUR TEAM'">
          <span>VACANCIES</span>
          <span><img src="@/assets/img/svg/move-1.svg" alt="3oaks"></span>
          <span>VACANCIES</span>
          <span><img src="@/assets/img/svg/move-2.svg" alt="3oaks"></span>
          <span>VACANCIES</span>
          <span><img src="@/assets/img/svg/move-3.svg" alt="3oaks"></span>
        </MoveLine>
      </div>
      <VacanciesBlock
        v-if="vacancies.length > 0"
        :items="vacancies"
        :account-url="accountUrl"
      />
      <!-- VACANCIES BLOCK ======================================= END -->

      <!-- GIVE YOUR PLAYERS MORE BLOCK =========================== -->
      <ContactUs/>
      <!-- GIVE YOUR PLAYERS MORE BLOCK =========================== END -->

    </div>
  </main>
</template>


<script>
import {appFetchJSON} from '@/lib/request_utils';
import {settings} from "@/lib/settings";

import MoveLine from '@/components/move_line';
import ContactUs from '@/components/contact_us';
import GamesSlider from '@/components/games_slider';
import GamesSliderCardWithDate from '@/components/games_slider_card_with_date';
import LatestNewsBlock from "@/pages/home/latest_news_block";

import MainBanner from './main_banner';
import IconsSlider from './icons_slider';
import PartnersBlock from './partners_block';
import VacanciesBlock from './vacancies_block';
import GamesSliderCard from '@/components/games_slider_card';
import {markRaw} from "vue";

export default {
    name: 'Home',
    components: {
        MainBanner,
        IconsSlider,
        MoveLine,
        GamesSlider,
        LatestNewsBlock,
        PartnersBlock,
        VacanciesBlock,
        ContactUs,
        //SliderControl,
        //GameSliderCardWithDate,
        //GameSliderCard
    },

    data() {
        return {
            latestGames: [],
            GameSliderCard: markRaw(GamesSliderCard),
            GameSliderCardWithDate: markRaw(GamesSliderCardWithDate),
            //sliderCardComponent: GameSliderCardWithDate,
            comingSoonGames: [],
            hasComingSoonGames: Boolean,
            bannerObjects: [],
            vacancies: [],
        }
    },

    computed: {
        accountUrl() {
            return settings.WORKABLE_ACCOUNT_URL;
        }
    },

    mounted() {
        this.getMainBannerObjects();
        this.getLatestGames();
        this.getComingSoonGames();
        this.getVacancies();
    },

    methods: {
        getLatestGames() {
            let options = {
                params: {
                    limit: 10
                }
            }
            appFetchJSON(`/games/latest`, options)
                .then(res => {
                    this.latestGames = res.data?.items || [];
                })
                .catch(res => {
                    this.latestGames = [];
                });
        },

        getComingSoonGames() {
            let options = {
                params: {
                    limit: 10,
                }
            }
            appFetchJSON(`/games/coming_soon`, options)
                .then(res => {
                    this.comingSoonGames = res.data?.items || [];
                    this.hasComingSoonGames = this.comingSoonGames.length > 0;
                })
                .catch(err => {
                    this.comingSoonGames = [];
                    this.hasComingSoonGames = false;
                });
        },

        getMainBannerObjects() {
            let options = {
                params: {
                    limit: 5,
                    provider: '3oaks'
                }
            }
            appFetchJSON(`/games/latest`, options)
                .then(res => {
                    this.bannerObjects = res.data.items || [];
                })
                .catch(res => {
                    this.bannerObjects = [];
                    console.log('------------ TODO GET GAME HANDLE ERROR!', res)
                });
        },

        getVacancies() {
            const options = {
                method: 'GET',
                params: {
                    items_num: 3,
                }
            };
            return appFetchJSON(`/vacancies`, options)
                .then(res => {
                    this.vacancies = res?.data?.['items'] || [];
                })
        },
    }
}
</script>

<style scoped>

</style>
