import {createApp} from 'vue';
import {createHead} from '@vueuse/head';
import VueCookies from 'vue3-cookies';

import App from './app.vue';
import router from './router';
import {appFetchJSON} from "@/lib/request_utils";
import {settings} from "@/lib/settings";

const app = createApp(App);

app.use(VueCookies);
app.use(createHead());

appFetchJSON('/site_settings')
    .then(res => {
        const resData = res.data;
        settings.SERVICE_NAME = resData?.['SERVICE_NAME'];
        settings.GDPR.DATA_VERSION = resData?.['GDPR_COOKIE_DATA_VERSION'] || 1;
        settings.RESPONSIBLE_GAMING.DATA_VERSION = resData?.['RESPONSIBLE_GAMING'] || 1;
        settings.WORKABLE_ACCOUNT_URL = resData?.['WORKABLE_ACCOUNT_URL'];
    })
    .finally(() => {
        app.use(router);
        app.mount('body');
    });

