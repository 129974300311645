<template>
  <div class="tournament-page__prizes-section" v-if="isVisible">
    <div class="tournament-page__section-title">Prizes</div>
    <table class="tournament-page__table prizes">
      <thead>
      <tr>
        <th></th>
        <th></th>
        <th>#</th>
        <th>Prize</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="item in groupedPrizes"
          :class="{'top3': item.medalIconPlace <= 3, 'top10': item.medalIconPlace >= 4}">
        <td></td>
        <td>
          <img v-if="item.medalIconPlace === 1" src="@/assets/img/tournaments/medal-1.png"/>
          <img v-if="item.medalIconPlace === 2" src="@/assets/img/tournaments/medal-2.png"/>
          <img v-if="item.medalIconPlace === 3" src="@/assets/img/tournaments/medal-3.png"/>
          <img v-if="item.medalIconPlace >= 4" src="@/assets/img/tournaments/medal-common.png"/>
        </td>
        <td>{{ item.place }}</td>
        <td>
          <TournamentPrize
              :prize="item.prize"
              :date-locale="dateLocale"
              :date-time-zone="dateTimeZone"
          />
        </td>
        <td></td>
      </tr>
      </tbody>

    </table>
  </div>
</template>

<script>

import TournamentPrize from "@/pages/tournament/tournament_prize";

export default {
    name: "TournamentPrizesSection",
    components: {TournamentPrize},
    props: {
        prizesByPlace: {
            type: Object,
            default: [],
        },
        dateLocale: {
            type: String,
            default: undefined,
        },
        dateTimeZone: {
            type: String,
            default: undefined,
        }
    },

    computed: {
        isVisible() {
            const prizesByPlace = this.prizesByPlace;
            return Array.isArray(prizesByPlace) && prizesByPlace.length > 0;
        },

        groupedPrizes() {
            const prizesByPlace = [...this.prizesByPlace];
            if (prizesByPlace.length < 2) {
                return prizesByPlace;
            }

            const grouped = [[prizesByPlace[0]]];
            for (let i = 0; i < prizesByPlace.length - 1; i++) {
                const prizeA = prizesByPlace[i];
                const prizeB = prizesByPlace[i + 1];
                if (Object.entries(prizeA.prize).toString() !== Object.entries(prizeB.prize).toString()) {
                    grouped.push([]);
                }
                grouped[grouped.length - 1].push(prizeB);
            }
            const result = [];
            grouped.forEach((item) => {
                let place;
                if (item.length > 1) {
                    const rangeFirstPlace = item[0].place;
                    const rangeLastPlace = item[item.length - 1].place;
                    place = `${rangeFirstPlace}-${rangeLastPlace}`;
                } else {
                    place = item[0].place;
                }
                result.push({place: place, medalIconPlace: item[0].place, prize: item[0].prize});
            });
            return result
        }
    },
}
</script>

<style scoped>

</style>