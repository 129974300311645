<template>
  <main>
    <div objname="fiat" class="fiat-grid">
      <SummaryGrid
          v-if="fiatGridOptions !== null"
          :options="fiatGridOptions"
          :user="user" />
    </div>
    <div objname="crypto" class="crypto-grid">
      <SummaryGrid
        v-if="cryptoGridOptions !== null"
        :options="cryptoGridOptions"
        :user="user" />
    </div>
  </main>
</template>


<script>
import { appFetch } from '@/lib/request_utils';
import SummaryGrid from './grid';
import {isMobile} from "@/lib/browser";

const useFixedColumns = !isMobile.any();

let fullSchema = [
    {
        "name": "uid",
        "type": "text",
        "title": "pk",
        "width": "0"
    }, {
        "name": "symbol",
        "type": "text",
        "title": "Code",
        "width": "100"
    }, {
        "name": "title",
        "type": "text",
        "title": "Title",
        "width": "200",
        "cssClass": "summary-currency-title-column"
    }, {
        "name": "rate",
        "type": "text",
        "title": "Rate (* / EUR)",
        "width": "170",
        "cssClass": "summary-currency-rate-column"
    }, {
        "name": "description",
        "type": "text",
        "title": "Note",
        "width": "200",
        "cssClass": "summary-currency-description-column"
    }
];


export default {
    name: 'SummaryTabCurrencies',
    components: {
        SummaryGrid
    },
    props: {
        user: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            tab: 'games',
            fiatGridOptions: null,
            cryptoGridOptions: null
        }
    },
    methods: {
        loadCurrencies() {
            appFetch(`/summary/currencies/`, {
                method: 'GET',
                credentials: 'include',
                params: {
                }
            }).then(
                res => res.json()
            ).then(res => {
                let schema = this.prepareSchema(res.data);
                this.fiatGridOptions = {...{
                    title: 'FIAT',
                    csvFileName: 'currencies_fiat',
                    pkColumn: 'uid',
                    fixedColumnsNumber: useFixedColumns ? 1 : 0,
                    activeTab: 'currencies',
                    gridID: 'currencies',
                    data: schema.fiatData,
                    columns: schema.columns,
                    nestedHeaders: schema.nestedHeaders
                }};
                this.cryptoGridOptions = {...{
                    title: 'CRYPTO',
                    csvFileName: 'currencies_crypto',
                    pkColumn: 'uid',
                    fixedColumnsNumber: useFixedColumns ? 1 : 0,
                    activeTab: 'currencies',
                    gridID: 'currencies',
                    data: schema.cryptoData,
                    columns: schema.columns,
                    nestedHeaders: schema.nestedHeaders
                }};
            }).catch(res => {
                console.log('------------ TODO GET GAME HANDLE ERROR!', res)
            });
        },

        prepareSchema(res) {
            let allowedSchema = [],
                nestedColumns = [],
                fiatData = [],
                cryptoData = [];

            fullSchema.forEach(c => {
                if (res.columns.indexOf(c.name) > -1) {
                    allowedSchema.push({...{}, ...c})
                    nestedColumns.push({
                        colspan: 1,
                        title: "&nbsp;",
                        width: c['width']
                    }) ;
                }
            });

            res.rows.forEach(d => {
                if (d.type === 'fiat') {
                    fiatData.push(d);
                } else if (d.type === 'crypto') {
                    cryptoData.push(d);
                }
                delete d.type;
            })

            return {
                columns: allowedSchema,
                nestedHeaders: nestedColumns,
                fiatData: fiatData,
                cryptoData: cryptoData
            }
        }
    },
    mounted() {
        this.loadCurrencies();
    },
    computed: {

    },
}
</script>


<style scoped>
.fiat-grid, .crypto-grid {
    float: left;
}
</style>
