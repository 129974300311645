<template>
  <main class="page games-page">
    <div class="page__body games-page__body">

      <div class="first-screen offset-section_small first-screen_left">
        <div class="_container">
          <!-- NAVIGATION ============================================= -->
          <BreadCrumbs :links="[{title: 'Games'}]"
          />
          <!-- NAVIGATION ============================================= END -->

          <!-- PAGE DESCRIPTION ======================================= -->
          <div class="first-screen__body">
            <div class="first-screen__left">
              <div class="page-heading page-heading_colors">
                <h1>
                  <span>3 OAKS <em>GAMES</em></span>
                </h1>
              </div>
              <div class="page-description page-description_offset _icon-arrow-2">
                <p>
                    3 Oaks slots are crafted with entertainment at the forefront. They seamlessly combine engaging mechanics, balanced maths, captivating graphics and immersive music, resulting in the ultimate experience.
                </p>
              </div>
            </div>
            <div class="first-screen__right"></div>
          </div>
          <!-- PAGE DESCRIPTION ======================================= END -->
        </div>
      </div>

      <div class="_container" ref="gameGridContainer">
        <div class="games-grid">
          <div class="tabs-block _scr-item _tabs">
            <div class="tabs-block__body">
              <div class="tabs-block__block _tabs-block _active"
                   data-role="tab0"
              >
                <GamesGrid :items="items" :is-loading="isLoading" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <Paging
              :total-pages="totalPages"
              :current-page="currentPage"
              @on-page-click="onPageClick"
          />
        </div>
      </div>
    </div>
  </main>
</template>


<script>
import {appFetchJSON, processFetchError} from '@/lib/request_utils';
import BreadCrumbs from '@/components/breadcrumbs';
import Paging from '@/components/paging';
import GamesGrid from './games_grid';
import {loadImage, scrollToElement} from "@/lib/common_utils";
import EventBus from "@/lib/event_bus";


export default {
    name: 'Games',
    props: {
        user: Object,
        isClientArea: Boolean,
    },
    components: {
        BreadCrumbs,
        GamesGrid,
        Paging
    },

    data() {
        return {
            items: [],
            currentPage: 1,
            totalPages: 0,
            isLoading: false,
        }
    },

    mounted() {
        this.updateData();
    },

    watch: {
        '$route.query'() {
            this.updateData();
        }
    },

    methods: {
        updateData() {
            this.isLoading = true;
            this.updateCurrentPage();
            this.getGames().finally(() => {
                this.isLoading = false;
            });
        },

        getGames() {
            const options = {params: {}};
            options.params['page_num'] = this.currentPage;
            let pageItemsNum = 10;
            const innerWidth = window.innerWidth;
            if (innerWidth >= 839 && innerWidth < 1144) {
                pageItemsNum = 9;
            } else if (innerWidth >= 1144 && innerWidth < 1920) {
                pageItemsNum = 12;
            } else if (innerWidth >= 1920) {
                pageItemsNum = 15;
            }
            options.params['page_items_num'] = pageItemsNum;
            return appFetchJSON(`/games/`, options)
                .then(res => {
                    let items = res.data?.items || [];
                    items.forEach((item) => {
                        if (item['has_page']) {
                            item['page_url'] = this.gamePageURL(item);
                        }
                    });
                    this.items = items;
                    this.totalPages = res.data['total_pages'];
                })
                .catch(err => {
                    processFetchError(this.$router, err);
                });
        },

        gamePageURL(item) {
            let routeParams = {name: 'game', params: {name: item.name}};
            return this.$router.resolve(routeParams).fullPath;
        },

        updateCurrentPage() {
            this.currentPage = Number(this.$route.query?.page) || 1;
        },

        onPageClick(pageNum) {
            let routeData = {
                name: 'games',
                query: {...this.$route.query || {}, page: pageNum}
            };
            this.$router.replace(routeData);
            this.$nextTick(() => {
                scrollToElement(this.$refs.gameGridContainer, 0)
                    .then(() => {
                        EventBus.emit(EventBus.EVENTS.HEADER_HIDE);
                    });
            });
        }
    },
}
</script>



