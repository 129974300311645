class BaseLocalStorage {
    constructor() {
        this.DATA_VERSION = 1;
    }

    get storageKey() {
        throw new Error('not implemented')
    }

    get settings() {
        const cSettings = localStorage.getItem(this.storageKey) || '{}';
        return JSON.parse(cSettings) || {};
    }

    setSettings(data) {
        const cSettings = {
            version: this.DATA_VERSION,
            data: data,
        }
        localStorage.setItem(this.storageKey, JSON.stringify(cSettings))
    }

    get isProcessedByUser() {
        return this.settings.version === this.DATA_VERSION;
    }
}

class GDPR extends BaseLocalStorage {
    get storageKey() {
        return 'gdpr:cookie_settings';
    }
}

class ResponsibleGaming extends BaseLocalStorage {
    get storageKey() {
        return 'responsible_gaming';
    }
}

class Settings {
    constructor() {
        this.inited = false;
        this.SERVICE_NAME = '';
        this.GDPR = new GDPR();
        this.RESPONSIBLE_GAMING = new ResponsibleGaming();
        this.WORKABLE_ACCOUNT_URL = '';
    }
}

export const settings = new Settings();