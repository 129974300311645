<template>
  <div class="link-card">

    <a :href="item.url" class="_popup-link" target="_blank">
      <div class="link-card__title">
        <h5>{{ item.title }}</h5>
      </div>

      <div class="link-card__description">
        <p>{{ item.department }} <br /> {{ address }}</p>
      </div>

      <div class="link-card__date _icon-arrow-3">
        <DateWidget :iso-date-string="item.published_on" />
      </div>
    </a>

  </div>
</template>

<script>

import DateWidget from '@/components/date_widget';

export default {
    name: "VacanciesBlockCard",
    components: {
        DateWidget,
    },

    props: {
        item: Object,
    },

    computed: {
        publishedOn() {
            const itemPublishedOn = this.item?.['published_on'];
            if (!itemPublishedOn) return;
            return new Date(itemPublishedOn)
        },

        address() {
            const item = this.item;
            const data = [];
            ['city', 'country'].forEach(attr => {
                const val = item?.[attr];
                if (val) {
                    data.push(val);
                }
            });
            return data.join(', ');
        }
    }
}
</script>

<style scoped>

</style>