<template>
  <main class="page contacts-page">
    <div class="page__body contacts-page__body">

      <div class="first-screen offset-section_small first-screen_left">
        <div class="_container">
          <!-- BREAD CRUMBS ============================================= -->
          <BreadCrumbs
              :links="[{title: 'Contacts'}]"
          />
          <!-- BREAD CRUMBS ========================================= END -->

          <!-- PAGE DESCRIPTION ========================================= -->
          <div class="first-screen__body">
            <div class="first-screen__left">
              <div class="page-heading page-heading_colors">
                <h1>
                  <span>GET IN <em>TOUCH</em></span>
                </h1>
              </div>
              <div class="page-description page-description_offset _icon-arrow-2">
                <p>
                  Please feel free to contact us for more information about
                  3 Oaks company and our Products & Services
                </p>
              </div>
            </div>
            <div class="first-screen__right"></div>
          </div>
          <!-- PAGE DESCRIPTION ====================================== END -->
        </div>
      </div>

      <!-- SEND MESSAGE FORM ============================================ -->
      <div class="_container offset-section">
        <div class="contacts-page__content">
          <div class="page-form">
            <form class="form">
              <div class="form__wrapper">

                <div class="form__item required" :class="{ _error: !!errors.name }">
                  <label class="form__label" for="formNamePage">
                    Name
                  </label>
                  <input class="form__input _req" id="formNamePage" type="text"
                         name="name" maxlength="50"
                         :disabled="requestInProgress"
                         v-model.trim="name"
                         :class="{ _error: !!errors.name }">
                  <div class="form__error _icon-error">
                    {{ (errors.name || []).join(', ') }}
                  </div>
                </div>

                <div class="form__item" :class="{ _error: !!errors.phone }">
                  <label class="form__label" for="formPhonePage">
                    Phone number
                  </label>
                  <input class="form__input _phone" id="formPhonePage"
                         type="text" name="phone" maxlength="16"
                         :disabled="requestInProgress"
                         v-model.trim="phone"
                         :class="{ _error: !!errors.phone }">
                  <div class="form__error _icon-error">
                    {{ (errors.phone || []).join(', ') }}
                  </div>
                </div>

                <div class="form__item required" :class="{ _error: !!errors.company }">
                  <label class="form__label" for="formCompany">
                    Company
                  </label>
                  <input class="form__input _req" id="formCompany" type="text"
                         name="company" maxlength="50"
                         :disabled="requestInProgress"
                         v-model.trim="company"
                         :class="{ _error: !!errors.company }">
                  <div class="form__error _icon-error">
                    {{ (errors.company || []).join(', ') }}
                  </div>
                </div>

                <div class="form__item required" :class="{ _error: !!errors.email }">
                  <label class="form__label" for="formEmail">
                    Email
                  </label>
                  <input class="form__input _email _req" id="formEmail"
                         type="text" name="email" maxlength="50"
                         :disabled="requestInProgress"
                         v-model.trim="email"
                         :class="{ _error: !!errors.email }">
                  <div class="form__error _icon-error">
                    {{ (errors.email || []).join(', ') }}
                  </div>
                </div>

                <div class="form__item required" :class="{ _error: !!errors.message }">
                  <label class="form__label" for="formMessagePage">
                    Message
                  </label>
                  <textarea class="form__input textarea" name="message"
                            id="formMessagePage" maxlength="500"
                            :disabled="requestInProgress"
                            v-model.trim="message"
                            :class="{ _error: !!errors.message }"
                  ></textarea>
                  <div class="form__error _icon-error">
                    {{ (errors.message || []).join(', ') }}
                  </div>
                </div>
              </div>

              <div class="form__controls">
                <div class="form__submit">
                  <button type="button" class="page-button"
                          v-if="!requestInProgress"
                          :disabled="requestInProgress"
                          @click="onSendMessageBtnClick"
                  >
                    <span></span><span>SEND</span><span></span>
                  </button>
                  <span v-if="requestInProgress" class="text-mc-lighten loading-dots">Sending</span>
                </div>
                <div class="form__policy">
                  <div class="checkbox" :class="{_error: agreement === false}">
                    <input id="formAgreement" checked
                           type="checkbox" name="agreement"
                           class="form__input checkbox__input _req"
                           v-model="agreement"
                           :disabled="requestInProgress"
                    >
                    <label for="formAgreement" class="checkbox__label">
                      <span>I agree to the terms of the <a href="/privacy-notice" target="_blank">privacy policy</a></span>
                    </label>

                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- SEND MESSAGE FORM ======================================== END -->

      <!-- MAP BLOCK ==================================================== -->
      <!--div class="map-block _ibg">
        <img src="@/assets/img/map.png" alt="3oaks">
      </div-->
      <!-- MAP BLOCK ================================================ END -->

    </div>
  </main>
</template>


<script>
import {showMessageModal} from '@/lib/modal_utils';
import {appFetch} from '@/lib/request_utils';
import BreadCrumbs from '@/components/breadcrumbs';
import SocialLinks from '@/components/layout/embedded_page/social_links';

export default {
    name: 'Contacts',
    components: {
        BreadCrumbs,
        SocialLinks
    },

    data() {
        return {
            salesEmail: process.env.VUE_APP_SALES_EMAIL,
            mediaEmail: process.env.VUE_APP_MEDIA_EMAIL,
            infoEmail: process.env.VUE_APP_INFO_EMAIL,
            name: '',
            phone: '',
            company: '',
            email: '',
            message: '',
            agreement: null,
            errors: {
                name: '',
                phone: '',
                company: '',
                email: '',
                message: '',
            },
            requestInProgress: false,
        };
    },

    methods: {
        onSendMessageBtnClick() {
            if (!this.agreement) {
                this.agreement = false;
                return;
            }

            this.requestInProgress = true;

            let options = {
                method: 'POST',
                body: JSON.stringify({
                    name: this.name,
                    phone: this.phone,
                    company: this.company,
                    email: this.email,
                    message: this.message,
                    agreement: this.agreement
                }),
            }

            appFetch(`/contacts/`, options)
                .then(
                    res => res.json()
                )
                .then(res => {
                    this.onSendMessageSuccess(res);
                })
                .catch(res => {
                    this.onSendMessageError(res);
                });
        },

        onSendMessageSuccess(res) {
            this.errors = res.errors || {};
            if (res.msg === 'message sent') {
                showMessageModal({
                    title: 'Info',
                    text: 'Thank you for the request! ' +
                        'Our manager will contact you as soon as possible.'
                });
                this.resetForm();
            }
            this.requestInProgress = false;
        },

        onSendMessageError(res) {
            showMessageModal({
                title: 'Oops! Something went wrong',
                text: 'We will work on fixing that right away.'
            });
            this.requestInProgress = false;
            console.log('------------ TODO POST CONTACTS HANDLE ERROR!', res);
        },

        resetForm() {
            this.name = '';
            this.phone = '';
            this.company = '';
            this.email = '';
            this.message = '';
            this.agreement = null;
            this.errors.name = '';
            this.errors.phone = '';
            this.errors.company = '';
            this.errors.email = '';
            this.errors.message = '';
        },
    }
}
</script>


