<template>
  <div class="page-form">
    <div class="form__wrapper">
      <div class="form__item">
        <p class="" v-html="options.text"></p>
      </div>
    </div>
    <div class="form__controls">
      <div class="form__submit">
        <button type="button" class="page-button" @click="$emit('close')">
          <span></span><span>OK</span><span></span>
        </button>
      </div>
    </div>
  </div>
</template>


<script>
export default {
    name: "MessageForm",
    props: {
        options: {
            type:  Object,
            default: {
                text: ''
            }
        }
    },
}
</script>

