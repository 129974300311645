<template>
  <div class="game-card">
    <a v-if="item.has_page" class="game-card__body" :href="`/game/${item.name}`">
      <div class="game-card__image _ibg">
        <img :src="item.icon_file" alt="3oaks">
      </div>
      <div class="game-card__description _icon-arrow-3">
        <p>{{ item.title_text }}</p>
      </div>
    </a>
    <div v-if="!item.has_page" class="game-card__body">
      <div class="game-card__image _ibg">
        <img :src="item.icon_file" alt="3oaks">
      </div>
      <div class="game-card__description">
        <p>{{ item.title_text }}</p>
      </div>
    </div>
  </div>
</template>


<script>
export default {
    name: "GamesSliderCard",
    props: {
        item: {
            type: Object,
            default: {
                title_text: '',
                icon_file: ''
            }
        }
    }
}
</script>
