<template>
    <div class="panel">
        <div class="bg-left"></div>
        <div class="bg-right"></div>
        <div class="state-logo">
            <img src="@/assets/img/game-verification/accorns-logo.svg" />
            <img class="text-logo" src="@/assets/img/game-verification/3oaks-gaming-text-logo.svg" />
            <img src="@/assets/img/game-verification/verification-success.svg" />
        </div>
        <div class="state-content">
            <div class="title">
                <img src="@/assets/img/game-verification/title-icon-success.svg" />
                <span>THIS GAME IS AUTHENTIC</span>
            </div>
            <div>To validate that this title is an authentic 3 Oaks game, please follow these two essential steps:</div>
            <div class="text-splitter"></div>
            <div class="step">
                <img src="@/assets/img/game-verification/badge-step-1.svg" />
                <span>
                    Ensure that this page's URL begins with <b>"{{ verifyUrl }}"</b>.
                </span>
            </div>
            <div>
                <span class="text-danger">CAUTION!</span>
                If this page's URL does not precisely begin with
                <b>"{{ verifyUrl }}"</b>, it indicates that you
                are engaging in a duplicated version of the game which is not authorised by 3 Oaks.
                Your funds are at risk, and we strongly recommend requesting a refund immediately.
                Always choose to play authentic 3 Oaks games.
            </div>

            <div class="step">
                <img src="@/assets/img/game-verification/badge-step-2.svg" />
                <span>Validate that your most recently played game with 3 Oaks on this site was:</span>
            </div>

            <div class="verification-info-table">
                <div>
                    <img v-if="iconUrl !== undefined" :src="iconUrl" class="game-icon" />
                    <span v-else>no icon</span>
                </div>
                <div>
                    <span v-if="gameTitle">{{ gameTitle }}</span>
                    <span v-else>no data</span>
                </div>
                <div>
                    <span v-if="sessionDuration"> Game Session: {{ sessionDuration }}</span>
                    <span v-else>no data</span>
                </div>
            </div>

            <div>
                <span class="text-important">IMPORTANT:</span>
                <br />
                <br />
                if you have successfully validated both Step 1 and Step 2, you can be confident that
                the game you are playing is authentic. Congratulations!
                <br />
                <br />
                If you are unable to validate either Step 1 or Step 2, you are engaging in a duplicated version
                of the game which is not authorised by 3 Oaks and your funds are at risk.
                <br />
                <br />
                To report any non-authentic games, please write us at  <a href="mailto:{{ supportEmail }}">{{ supportEmail }}</a>
            </div>
        </div>
    </div>
</template>

<script>
import { settings } from '@/lib/settings';


export default {
    name: "GameVerificationValid",
    props: {
        gameTitle: String,
        iconUrl: String,
        sessionDuration: String,
        supportEmail: String,
    },

    computed: {
        verifyUrl() {
            return `${settings.SERVICE_NAME}/verify`;
        },
    }
};
</script>
