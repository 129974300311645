<template>
  <div class="tournament-page__leaderboard-section" v-if="isVisible">
    <div class="tournament-page__section-title">Leaderboard</div>
    <div class="fixed-header">
      <table class="tournament-page__table">
        <thead>
        <tr>
          <th></th>
          <th></th>
          <th>#</th>
          <th>Player</th>
          <th>Score</th>
          <th>Prize</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="item in leaderboardData"
            :class="{top3: item.place <= 3, top10: item.place >= 4 && item.place <= 10}">
          <td></td>
          <td>
            <img v-if="item.place === 1" src="@/assets/img/tournaments/medal-1.png"/>
            <img v-if="item.place === 2" src="@/assets/img/tournaments/medal-2.png"/>
            <img v-if="item.place === 3" src="@/assets/img/tournaments/medal-3.png"/>
            <img v-if="item.place >= 4 && item.place <= 10" src="@/assets/img/tournaments/medal-common.png"/>
          </td>
          <td>{{ item.place }}</td>
          <td>{{ item.nick }}</td>
          <td>{{ item.score }}</td>
          <td>
            <TournamentPrize
                :prize="item.prize"
                :date-locale="dateLocale"
                :date-time-zone="dateTimeZone"
            />
          </td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import TournamentPrize from "@/pages/tournament/tournament_prize";

export default {
    name: "TournamentLeaderboardSection",

    components: {
        TournamentPrize,
    },

    props: {
        leaderboardData: {
            type: Array,
            default: [],
        },
        dateLocale: {
            type: String,
            default: undefined,
        },
        dateTimeZone: {
            type: String,
            default: undefined,
        }
    },

    computed: {
        isVisible() {
            return this.leaderboardData.length > 0;
        }
    }
}
</script>

<style scoped>

</style>