<template>
  <Error500 />
</template>


<script>

import Error500 from "@/pages/errors/error500";

export default {
    name: 'ErrorUserLoad',
    components: {
        Error500,
    },
}
</script>
