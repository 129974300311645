<template>
  <section class="offset-section" v-if="articles.length > 0">
    <div class="_container">
      <h6 class="offset-title">RECOMMENDED <span>FOR</span> YOU</h6>
      <div id="touch-slider-1" class="news-container touch-slider _swiper" ref="swiper">
        <div class="swiper-wrapper">
          <NewsCard
              v-for="item of articles"
              :title="item.title"
              :image-url="item.image"
              :category="item['category_title']"
              :publish-date="item['c_at']"
              :article-url="item.path"
              :preload="true"
              class="swiper-slide"
          />
        </div>
      </div>
    </div>

    <div class="_container">
      <div class="_container">
        <a href="/news" class="page-button">
          <div></div>
          <div>see all</div>
          <div></div>
        </a>
      </div>
    </div>
  </section>
</template>

<script>

import NewsCard from "@/components/news_card";
import {appFetchJSON} from "@/lib/request_utils";
import Swiper from "@/assets/js/Swiper";
import {isMobile} from "@/lib/browser";


export default {
    name: "NewsRecommendedBlock",
    props: {
        article: Object,
    },
    components: {
        NewsCard,
    },
    data() {
        return {
            articles: [],
        }
    },

    watch: {
        article: function (newVal, oldVal) {
            if (newVal !== undefined) {
                this.fetchNews().then(() => {
                    this.initSlider();
                });
            }
        }
    },



    methods: {
        initSlider() {
            let swiper = new Swiper(
                this.$refs.swiper,
                {
                    observer: true,
                    observeParents: true,
                    simulateTouch: true,
                    grabCursor: true,
                    slidesPerView: 3,
                    breakpoints: {
                        320: {
                            spaceBetween: 8,
                            slidesPerView: 1.5,
                        },
                        480: {
                            spaceBetween: 12,
                            slidesPerView: 2.5,
                        },
                        780: {
                            spaceBetween: 16,
                            slidesPerView: 2.5,
                        },
                        992: {
                            spaceBetween: 16,
                            slidesPerView: 2.9,
                        },
                        1920: {
                            spaceBetween: 16,
                            slidesPerView: 3,
                        }
                    }
                }
            );
            this.swiper = swiper;
        },

        fetchNews() {
            let url = '/news/articles/recommended';
            let options = {
                params: {
                    category_uid: this.article['category_uid'],
                    excluded_articles_uids: [this.article.uid]

                }
            };
            return appFetchJSON(url, options)
                .then(res => {
                    let articles = res.data.items || [];
                    articles.forEach((value) => {
                        let routeData = {name: 'news-article', params: {article: value.slug}};
                        let route = this.$router.resolve(routeData);
                        value.path = route.path;
                    });
                    this.articles = articles;
                })
                .catch(err => {
                    this.articles = [];
                    console.log('------------ TODO GET NEWS HANDLE ERROR!', err)
                });
        },
    }
}
</script>

<style scoped>

</style>