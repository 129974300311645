<template>
  <div class="map-block _ibg">
    <img src="@/assets/img/map.png" alt="3oaks">
    <div class="map-point man">
      <img src="@/assets/img/svg/oaks.svg" alt="3oaks">
    </div>
  </div>
</template>


<script>
export default {
    name: "BlockMap"
}
</script>

<!-- style scoped>

.map-point {
    width: 50px;
    height: 50px;
    background: url("~@/assets/img/svg/map-point.svg") center no-repeat;
    position: absolute;
}

.map-point img {
    height: 20px;
    width: 20px;
    left: 15px;
    top: 8px;
}

.man {
    bottom: 57%;
    left: calc(47.5% - 125px);
}

</style -->