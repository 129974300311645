<template>
  <main class="page login-page">
    <div class="first-screen _container">
      <div class="page-heading">
        <h6>Login</h6>
      </div>
      <LoginForm @close="onLoginFormClose"/>
    </div>
  </main>
</template>


<script>
import LoginForm from '@/components/forms/login';


export default {
    name: 'Login',
    components: {LoginForm},

    methods: {
      onLoginFormClose(e) {
        if (e && e.pageReload) this.$router.go();
      }
    }

}
</script>
