<template>
  {{ dateString }}
</template>

<script>
export default {
    name: "DateWidget",
    props: {
        isoDateString: String,
        locale: {
            type: String,
            default: 'en',
        },
        options: {
            type: Object,
            default: {day: 'numeric', month: 'short', year: 'numeric'},
        },
        onlyTime: Boolean,
        onlyTimeZone: Boolean,
        useTimeZone: {
            type:String,
            default: undefined,
        },
        useClientTimeZone: Boolean,

    },

    computed: {
        dateString() {
            if (this.useClientTimeZone && this.useTimeZone) {
                console.warn(
                    'DateWidget Warning: useClientTimeZone and useTimeZone are both set. useTimeZone win.');
            }

            const options = this.options;
            if (this.useTimeZone) {
                options.timeZone = this.useTimeZone;
            }else if (this.useClientTimeZone) {
                options.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            }

            let result;
            if (this.onlyTimeZone) {
                if (!options.timeZoneName) {
                    options.timeZoneName = 'short';
                }
                result = new Date(this.isoDateString).toLocaleDateString(this.locale, options);
                result = result.split(',')[1].trim();
            } else if (this.onlyTime) {
                result = new Date(this.isoDateString).toLocaleTimeString(this.locale, options);
            } else {
                result = new Date(this.isoDateString).toLocaleDateString(this.locale, options);
            }
            return result;
        }
    }
}
</script>