<template>
  <div class="ntp-tournament__footer">
      <h2 class="ntp-section-title in-footer">
        <span>Contacts</span>
      </h2>
      <div class="ntp-footer-content">
        <div class="ntp-footer-col">
          <div class="ntp-contacts-content">
            <div v-html="tournament.contact_information"></div>
            <p>© {{ year }} 3 Oaks Gaming. All Rights Reserved</p>
          </div>
        </div>
        <div class="ntp-footer-col">
          <div class="ntp-footer-social-links">
            <a href="https://www.facebook.com/3-Oaks-Gaming-108423585065971"
               target="_blank"
               rel="nofollow noopener noreferrer"
               class="ntp-footer-social-link">
              <img src="@/assets/img/tournaments/social-facebook-icon.svg" alt="Facebook link" />
            </a>
            <a href="https://www.linkedin.com/company/3-oaks-gaming"
               target="_blank"
               rel="nofollow noopener noreferrer"
               class="ntp-footer-social-link">
              <img src="@/assets/img/tournaments/social-linkedin-icon.svg" alt="LinkedIn link" />
            </a>
          </div>
        </div>
      </div>
  </div>


<!--    <section class="footer-section">-->
<!--        <div class="contacts-title">CONTACTS</div>-->
<!--        <div class="content-wrapper">-->
<!--            <div v-html="tournament.contact_information"></div>-->
<!--            <div class="social-icons">-->
<!--            <a href="https://www.facebook.com/3-Oaks-Gaming-108423585065971" target="_blank" rel="nofollow noopener noreferrer">-->
<!--                <img src="@/assets/img/tournaments/social-facebook-icon.svg"/>-->
<!--            </a>-->
    <!--          <a href="#" target="_blank" rel="nofollow noopener noreferrer">-->
    <!--            <img src="@/assets/img/tournaments/social-twitter-icon.svg"/>-->
    <!--          </a>-->
    <!--          <a href="#" target="_blank" rel="nofollow noopener noreferrer">-->
    <!--            <img src="@/assets/img/tournaments/social-instagram-icon.svg"/>-->
    <!--          </a>-->
<!--            <a href="https://www.linkedin.com/company/3-oaks-gaming" target="_blank" rel="nofollow noopener noreferrer">-->
<!--                <img src="@/assets/img/tournaments/social-linkedin-icon.svg"/>-->
<!--            </a>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="footer__bottom">-->
<!--            <div>-->
<!--                <p><a href="/privacy-notice" target="_blank">Privacy Policy</a></p>-->
<!--            </div>-->
<!--            <div>-->
<!--                <p>© {{ year }} 3 Oaks Gaming. All Rights Reserved</p>-->
<!--            </div>-->
<!--        </div>-->
<!--    </section>-->
</template>

<script>
export default {
    name: "TournamentFooterSection",
    props: {
        tournament: {},
    },

    data() {
        return {
            year: new Date().getFullYear()
        }
    },
}
</script>

