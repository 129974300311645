<template>
  <span v-html="itemHTMLValue"></span>
</template>

<script>
export default {
    name: "TournamentPrizeComponent",
    props: {
        prize: {
            type: Object,
            default: {},
        },
        dateLocale: {
            type: String,
            default: undefined,
        },
        dateTimeZone: {
            type: String,
            default: undefined,
        }
    },

    computed: {

        prizeTypeValueProcessors() {
            return {
                'MONEY': this.prizeTypeValueProcessorMoney,
                'FREEBET': this.prizeTypeValueProcessorFreeBet,
                'CUSTOM': this.prizeTypeValueProcessorCustom,
                'PROGRESSIVE': this.prizeTypeValueProcessorProgressive,
            }
        },

        itemHTMLValue() {
            const prize = this.prize;
            const prizeType = prize.type;
            const prizeTypeValueProcessor = this.prizeTypeValueProcessors[prizeType];
            if (!prizeTypeValueProcessor) {
                return '';
            }

            return prizeTypeValueProcessor(prize);
        }
    },

    methods: {
        getTwoDigestFloat(value) {
            return (parseFloat(value) / 100).toFixed(2);
        },

        prizeTypeValueProcessorMoney(item) {
            const value = this.getTwoDigestFloat(item.value);
            return `${item.currency}&nbsp;${value}`;
        },

        prizeTypeValueProcessorFreeBet(item) {
            const currency = item.currency;
            let value = '';
            const freeBetType = item['freebet_type'];

            if (freeBetType === 'fixed') {
                const roundBet = this.getTwoDigestFloat(item["round_bet"]);
                const rounds = item["rounds"];
                value = `${rounds}&nbsp;FB x ${currency}&nbsp;${roundBet}`;

            } else if (freeBetType === 'flexible') {
                const totalBet = this.getTwoDigestFloat(item["total_bet"]);
                value = `Flexible Free Bets for ${currency}&nbsp;${totalBet}`;

            } else if (freeBetType === 'feature_freespins') {
                const totalBet = this.getTwoDigestFloat(item["total_bet"]);
                value = `FreeSpin Feature with ${currency}&nbsp;${totalBet}`;
            }

            if (value) {
                const gameTitle = item['game_title'];
                if (gameTitle) {
                  value = `${value}<br /> Game: ${gameTitle}`;
                }

                // const endDateOptions = {
                //     day: 'numeric', month: 'numeric', year: 'numeric',
                //     hour: '2-digit', minute: '2-digit', timeZoneName: 'short',
                //     timeZone: this.dateTimeZone,
                // }
                // const endDate = new Date(item['end_date'])
                //     .toLocaleDateString(this.dateLocale || 'uk', endDateOptions);
                // value += `Game: ${gameTitle}<br> Ends At: ${endDate}
            }

            return value;
        },

        prizeTypeValueProcessorCustom(item) {
            // let value = parseFloat(item.value) || 0;
            // if (value % 2 !== 0) {
            //     value = this.getTwoDigestFloat(value);
            // }
            // return `${value} ${item.name}`;
            return item.name;
        },

        prizeTypeValueProcessorProgressive(item) {
            let value = this.getTwoDigestFloat(item.value);
            return `${item.currency}&nbsp;${value}`;
        },
    }
}
</script>

<style scoped>

</style>