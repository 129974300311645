<template>
  <section class="offse-section" ref="root" v-if="this.reviews.length > 0">
    <h6 class="offset-title">REVIEWS</h6>

    <div class="partners-page__reviews">

      <!-- ===================================== REVIEW CARD ===================================== -->
      <div
          v-for="item in reviews"
          class="review-card"
          :class="{loading: loading}"
      >
        <div class="logo">
          <img :src="item['partner']?.['logo_img']" :alt="item['partner']?.['title']">
          <span v-if="!item['partner']['is_visible'] && item['is_visible']">*partner is hidden</span>
          <span v-if="item['partner']['is_visible'] && !item['is_visible']">*review is hidden</span>
          <span v-if="!item['partner']['is_visible'] && !item['is_visible']">*partner and review are hidden</span>
        </div>
        <div class="body">
          <p v-html="item['text']"></p>
        </div>
        <b>{{ item['author'] }}</b>
      </div>
      <!-- ===================================== REVIEW CARD ===================================== -->
    </div>
    <Paging
        v-if="showPagination"
        :total-pages="totalPages"
        :current-page="currentPage"
        @on-page-click="onPageClick"
    />
  </section>
</template>

<script>
import {appFetchJSON, processFetchError} from "@/lib/request_utils";
import Paging from "@/components/paging";
import {scrollToElement} from "@/lib/common_utils";
import EventBus from "@/lib/event_bus";
import {nextTick} from "vue";

const ITEMS_PER_PAGE = 10;

export default {
    name: "PartnersReviewsListBlock",

    components: {
        Paging,
    },

    data() {
        return {
            reviews: [],
            currentPage: 1,
            totalPages: 1,
            isCategoriesContainerActive: false,
            loading: false,
            showPagination: false,
        }
    },

    mounted() {
        this.updateCurrentPage();
        this.updateData();
    },

    watch: {
        '$route.query.page'() {
            this.updateCurrentPage();
        },

        currentPage() {
            this.updateData();
        }
    },

    methods: {
        updateData() {
            this.loading = true;
            this.fetchReviews()
                .then(() => this.updatePaginationVisibility())
                .finally(() => {
                    nextTick(() => this.loading = false)
                });
        },

        updateCurrentPage() {
            this.currentPage = Number(this.$route.query?.page) || 1;
        },

        updatePaginationVisibility() {
            this.showPagination = this.totalPages > 1;
        },

        fetchReviews() {
            const url = '/partners/reviews';
            const options = {
                params: {
                    page_num: this.currentPage,
                    page_items_num: ITEMS_PER_PAGE,
                }
            };

            return appFetchJSON(url, options)
                .then(res => {
                    const data = res.data;
                    this.reviews = data?.['items'] || [];
                    this.totalPages = data?.['total_pages'];
                })
                .catch(err => {
                    processFetchError(this.$router, err);
                });
        },

        onPageClick(pageNum) {
            if (pageNum === 1) {
                pageNum = undefined;
            }
            let routeData = {
                name: this.$route.name.toString(),
                query: {...this.$route.query || {}, page: pageNum}
            };
            this.$router.replace(routeData);
            this.$nextTick(() => {
                scrollToElement(this.$refs.root, 0).then(() => {
                    EventBus.emit(EventBus.EVENTS.HEADER_HIDE);
                });
            });
        },
    }
}
</script>

<style scoped>
.logo span {
    color: #f5b404;
    font-size: 1em;
    margin-top: 0.2em;
    padding: 0 1em;
    position: absolute;
}
</style>