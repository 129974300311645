<template>
  <div v-if="viewPagesList.length" class="pagging">
    <a class="pagging__arrow _icon-arrow-1"
       @click.prevent="previousPage"
       :class="{ disabled: currentPage <= 1 || isViewLocked }"></a>
    <ul class="pagging__list">
      <li v-for="pageNum in viewPagesList">
        <a @click.prevent="setPage(pageNum, $event)"
           class="pagging__item"
           :class="{
            _active: pageNum === currentPage,
            disabled: currentPage === pageNum || isViewLocked || pageNum === collapseSymbol
           }"
        >
          {{ pageNum }}
        </a>
      </li>
    </ul>
    <a class="pagging__arrow _icon-arrow-1"
       :class="{ disabled: currentPage >= totalPages || isViewLocked }"
       @click.prevent="nextPage"
    ></a>
  </div>
</template>


<script>

const VISIBLE_PAGES_MIN_VALUE = 5

export default {
    name: "Paging",
    props: {
        totalPages: Number,
        currentPage: Number,
        visiblePagesNumber: {
            type:  Number,
            default: 7,
            validator: (v => v >= VISIBLE_PAGES_MIN_VALUE),
        },
        locked: Boolean
    },
    emits: ["onPageClick"],
    data() {
        return {
            viewPagesList: [],
            isViewLocked: false,
            collapseSymbol: '...',
        }
    },
    watch: {
        totalPages() {
            this.fillPagesList();
        },
        currentPage() {
            this.fillPagesList();
        },
        visiblePagesNumber() {
            this.fillPagesList();
        },
        locked(oldVal, newVal) {
            this.isViewLocked = newVal;
        }
    },
    mounted() {
        this.fillPagesList();
    },

    methods: {
        setPage(pageNum, event) {
            pageNum = Number(pageNum);
            this.isViewLocked = true;
            if (1 < pageNum < this.totalPages && pageNum !== this.currentPage) {
                this.$emit("onPageClick", pageNum);
            }
        },
        previousPage() {
            this.setPage(this.currentPage - 1);
        },
        nextPage() {
            this.setPage(this.currentPage + 1);
        },

        fillPagesList() {
            this.viewPagesList.length = 0;
            if (!this.currentPage || !this.totalPages || !this.visiblePagesNumber) {
                return;
            }
            if (this.totalPages <= 1) {
                return;
            }

            if (this.visiblePagesNumber >= this.totalPages) {
                for (let i = 1; i <= this.totalPages; i++) {
                    this.viewPagesList.push(i);
                }
            } else if (this.currentPage <= this.visiblePagesNumber - 3) {
                for (let i = 1; i <= this.visiblePagesNumber - 2; i++) {
                    this.viewPagesList.push(i);
                }
                this.viewPagesList.push(this.collapseSymbol, this.totalPages);
            } else if (this.currentPage >= this.totalPages - (this.visiblePagesNumber - 4)) {
                this.viewPagesList.push(1, this.collapseSymbol);
                for (let i = this.totalPages - (this.visiblePagesNumber - 3); i <= this.totalPages; i++) {
                    this.viewPagesList.push(i);
                }
            } else {
                this.viewPagesList.push(1, this.collapseSymbol);
                let shiftSize = Math.max(Math.floor((this.visiblePagesNumber - 4) / 2), 1);
                for (let i = this.currentPage - shiftSize; i <= this.currentPage + shiftSize; i++) {
                    this.viewPagesList.push(i);
                }
                this.viewPagesList.push(this.collapseSymbol, this.totalPages);
            }
            this.isViewLocked = false;
        }
    }

}
</script>

<style scoped>

</style>
