<template>
  <main class="page game-page">
    <div class="page__body game-page__body">
      <div class="no-print first-screen">
        <div class="_container" :class="{'offset-section': item.is_playable}">
          <!-- BREAD CRUMBS ============================================= -->
          <BreadCrumbs
              :links="[
                  {title: 'Games', href: '/games'},
                  {title: item.title_text}]"
          />
          <!-- BREAD CRUMBS ========================================= END -->

          <!-- RUNNER =================================================== -->
          <div class="first-screen__body" v-if="item.is_playable">
            <div class="first-screen__center">
              <GameRunner
                  :banner="item.banner_file"
                  :languages="item.languages"
                  :gameUrl="item.game_url"
                  :lang="this.$route.query.lang"
              />
            </div>
          </div>
          <!-- RUNNER =============================================== END -->
        </div>
      </div>

      <!-- GAME DESCRIPTION ============================================= -->
      <div class="_container offset-section">
        <h6 class="offset-title" v-html="item.title_html"></h6>
        <div class="_container-description">
          <div class="column">
            <div class="_text-box" :class="{ 'expanded': isGameDescriptionBlockExpanded }" ref="gameDescriptionTextBox">
              <b>Release: {{ item.release_date }}</b>
              <div v-html="item.description" ref="gameDescription"></div>
            </div>
            <a href="" class="page-button" v-if="!isGameDescriptionBlockExpanded"
              @click.prevent="onGameDescriptionExpandButtonClick">
              <div></div>
              <div>READ MORE</div>
              <div></div>
            </a>
          </div>
          <div class="column">
            <div class="game-page__card">
              <div class="game-page__card-image">
                <img :src="item.logo_file" alt="3oaks">
              </div>
              <ul class="list">
                <li v-for="spec of item.specification">
                  <img src="@/assets/img/svg/oaks.svg" alt="3oaks">
                  <span>{{ spec }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- GAME DESCRIPTION ========================================= END -->

      <!-- SLIDER SCREENSHOTS =========================================== -->
      <div v-if="hasGalleryImages">
        <MoveLine :title="'GALLERY'">
          <span>SCREENSHOTS</span>
          <span><img src="@/assets/img/svg/move-1.svg" alt="3oaks"></span>
          <span>SCREENSHOTS</span>
          <span><img src="@/assets/img/svg/move-2.svg" alt="3oaks"></span>
          <span>SCREENSHOTS</span>
          <span><img src="@/assets/img/svg/move-3.svg" alt="3oaks"></span>
        </MoveLine>
        <div class="offset-section_small">
          <div class="print-only _container">
            <h6 class="offset-title new-page">SCREENSHOTS</h6>
          </div>
          <ScreenshotSlider :images="item.gallery_images"/>
        </div>
      </div>
      <!-- SLIDER SCREENSHOTS ======================================= END -->

      <!-- OTHER GAMES ================================================== -->
      <div class="offset-section _container" v-if="otherGames.length">
        <h6 class="offset-title">OTHER <span>GAMES</span></h6>
        <GamesSlider
            :games="otherGames"
            :sliderCardComponent="GameSliderCard"
        />
      </div>
      <!-- OTHER GAMES ============================================== END -->

      <!-- MEDIA PARTNERS ABOUT THIS GAME =============================== -->
      <!-- <section class="offset-section">-->
      <!--   <NewsSlider>-->
      <!--   Media <span> partners </span> about this game-->
      <!--   </NewsSlider>-->
      <!-- </section>-->
      <!-- MEDIA PARTNERS ABOUT THIS GAME =========================== END -->

    </div>
  </main>
</template>


<script>
import {markRaw} from "vue";

import {appFetchJSON, processFetchError} from '@/lib/request_utils';
import {showLoginModal} from '@/lib/modal_utils';

import BreadCrumbs from '@/components/breadcrumbs';
import MoveLine from '@/components/move_line';
import ScreenshotSlider from '@/components/screenshot_slider';
import GameRunner from '@/components/game_runner';
import GamesSlider from '@/components/games_slider';
import GamesSliderCard from '@/components/games_slider_card';
import NewsSlider from '@/components/news_slider';


export default {
    name: 'Game',
    components: {
        BreadCrumbs,
        GameRunner,
        MoveLine,
        ScreenshotSlider,
        GamesSlider,
        GamesSliderCard,
        NewsSlider
    },

    data() {
        return {
            item: {
                is_playable: undefined,
                title_html: undefined,
                logo_file: undefined,
                specification: undefined,
                gallery_images: undefined
            },
            otherGames: [],
            isGameDescriptionBlockExpanded: false,
            GameSliderCard: markRaw(GamesSliderCard)
        }
    },

    created() {
        let anchors = [
            '#description-anchor', '#promo-tools-anchor', '#runner-anchor', '#math-anchor',
            '#paytable-anchor', '#feature-anchor', '#print'];
        if (anchors.includes(window.location.hash)) {
            setTimeout(() => {
                showLoginModal({
                    onClose: e => {
                        if (e.status === 'close') {
                            history.replaceState(
                                {}, document.title,
                                window.location.href.split('#')[0]);
                        }
                    }
                });
            }, 2000)
        }

        window.addEventListener("resize", this.onResize.bind(this));
    },

    destroyed() {
        window.removeEventListener("resize", this.onResize.bind(this));
    },

    mounted() {
        this.getGame().then(() => {
            this.updateExpandableDescriptionState();
        });
        this.getOtherGames();
    },

    computed: {
        hasGalleryImages() {
            return this.item.gallery_images
                && this.item.gallery_images.length > 0;
        },
    },

    methods: {
        getGame() {
            return appFetchJSON(`/games/${this.$route.params.name}`)
                .then(res => {
                    this.item = res.data;
                })
                .catch(err => {
                    processFetchError(this.$router, err);
                });
        },

        getOtherGames() {
            let options = {
                params: {
                    except_game: this.$route.params.name,
                    limit: 10
                }
            }
            return appFetchJSON(`/games/`, options)
                .then(res => {
                    this.otherGames = res.data;
                })
                .catch(err => {
                    this.otherGames = [];
                });
        },

        onGameDescriptionExpandButtonClick() {
            this.isGameDescriptionBlockExpanded = !this.isGameDescriptionBlockExpanded;
        },

        onResize() {
            if (this.resizeTimeout) {
                clearInterval(this.resizeTimeout);
            }
            this.resizeTimeout = setTimeout(() => {
                this.resizeTimeout = undefined;
                this.updateExpandableDescriptionState();
            }, 600);
        },

        updateExpandableDescriptionState() {
            if (this.isGameDescriptionBlockExpanded) return;
            this.$nextTick(() => {
                const gameDescriptionRef = this.$refs.gameDescription;
                const gameDescriptionTextBoxRef = this.$refs.gameDescriptionTextBox;
                const gameDescriptionRefRect = gameDescriptionRef.getBoundingClientRect();
                const gameDescriptionTextBoxRefRect = gameDescriptionTextBoxRef.getBoundingClientRect();
                if (gameDescriptionRefRect.height <= gameDescriptionTextBoxRefRect.height) {
                    this.isGameDescriptionBlockExpanded = true;
                }
            })
        }
    },
}
</script>
