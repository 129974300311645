<template>
  <div class="offset-section" ref="root" :key="componentKey" v-if="categories.length > 0">
    <div class="tabs-block _scr-item _tabs">
      <div class="_tabs-mobile">
        <div
            class="tabs-mobile__value _icon-arrow-1"
            ref="categories-select-box"
            @click.prevent="onSelectBoxClick">
          {{ currentCategory.title }}
        </div>
        <nav
            v-if="categories.length > 1"
            class="tabs-block__nav tabs-mobile__options"
            :class="{active: isCategoriesContainerActive}"
            ref="categories-container">
          <router-link
              v-for="item of categories"
              :to="{name: $route.name.toString(), query: {...$route.query || {}, category: item.slug}}"
              class="tabs-block__item _tabs-item"
              :class="{_active: currentCategory === item}"
          >
            {{ item.title }}
            <sup v-if="!item['is_visible']">hidden</sup>
          </router-link>
        </nav>
      </div>

      <div class="tabs-block__body">
        <div class="tabs-block__block _tabs-block _active">
          <div class="page-partners" v-if="partners.length > 0">
            <div class="page-partners__container" ref="pagePartnersContainerRef">
              <template v-for="(item, idx) in partners">
                <a
                    class="page-partners__item"
                    :class="{'disabled': !item['link'], 'loading': loading}"
                    :href="item['link'] || '#'"
                    :hidden="item['is_hidden']"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                >
                  <div :ref="(el) => {addLogoImgRef(el, idx)}"></div>
                  <span class="badge" v-if="!item['is_visible']">hidden</span>
                </a>
              </template>

            </div>
            <div class="show-container" v-if="isExpandButtonVisible">
              <div
                  data-show-items="18"
                  data-opposite="HIDE"
                  data-content="SHOW MORE"
                  class="_show-more _icon-arrow-1"
                  :class="{'_active': isExpanded}"
                  @click.prevent="onShowMoreButtonClick"
              >
                {{ this.isExpanded ? 'HIDE' : 'SHOW MORE' }}
              </div>
            </div>
          </div>
          <div v-if="partners.length === 0">
            There are no partners in the current category
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {appFetchJSON, processFetchError} from "@/lib/request_utils";
import {loadImage, scrollToElement} from "@/lib/common_utils";
import {nextTick} from "vue";

const VISIBLE_ROWS_NUM = 2;

export default {
    name: "PartnersListBlock",
    data() {
        return {
            categories: [],
            categoryBySlug: {},
            currentCategory: '',
            partners: [],
            isExpanded: false,
            componentKey: 0,
            columnsNum: 1,
            loading: false,
            logoImgRefs: {},
            isCategoriesContainerActive: false,
            defaultCategory: {
                title: 'All',
                position: -1,
                slug: 'all',
                is_visible: true,
            },
        }
    },

    created() {
        window.addEventListener("resize", this.onResize);
    },

    destroyed() {
        window.removeEventListener("resize", this.onResize);
    },

    mounted() {
        this.fetchCategories().then(() => this.updateCurrentCategory());
    },

    computed: {
        visibleItemsNumber() {
            return this.columnsNum * VISIBLE_ROWS_NUM;
        },

        isExpandButtonVisible() {
            return this.partners.length > this.visibleItemsNumber;
        }
    },

    watch: {
        '$route.query'() {
            this.isCategoriesContainerActive = false;
            this.updateCurrentCategory();
        },

        currentCategory() {
            this.isExpanded = false;
            this.loading = true;
            this.fetchPartners()
                .then(() => this.updatePartnersUI())
                .finally(() => {
                    nextTick(() => this.loading = false)
                });
        }
    },

    methods: {

        updateCurrentCategory() {
            const categorySlug = this.$route.query?.category;
            this.currentCategory = this.categoryBySlug[categorySlug] || this.defaultCategory;
        },

        fetchCategories() {
            const url = '/partners/categories';
            return appFetchJSON(url)
                .then(res => {
                    let categories = res.data?.['items'] || [];
                    categories.push(this.defaultCategory);
                    categories.sort(this.sortCategories);
                    this.categoryBySlug = {};
                    categories.forEach((value) => {
                        this.categoryBySlug[value.slug] = value;
                    });
                    this.categories = categories;
                })
                .catch(err => {
                    processFetchError(this.$router, err);
                });
        },

        sortCategories(a, b) {
            if (a.position > b.position) return 1;
            if (a.position < b.position) return -1;
            if (a.title > b.title) return 1;
            if (a.title < b.title) return -1;
            return 0;
        },

        fetchPartners() {
            const options = {params: {}};
            const categoryUid = this.currentCategory.uid;
            if (categoryUid !== undefined) {
                options.params.category = categoryUid;
            }
            const url = '/partners';
            return appFetchJSON(url, options)
                .then(res => {
                    const partners = res.data?.['items'] || []
                    const images = [];
                    partners.forEach((item) => {
                        const imgObj = item.imgObj = new Image();
                        imgObj.alt = item['title'];
                        images.push(loadImage(imgObj, item['logo_img']))
                    });
                    return Promise.all(images).finally(() => {
                        this.logoImgRefs = {};
                        this.partners = partners;
                    });
                })
                .catch(err => {
                    processFetchError(this.$router, err);
                });
        },

        appendPreloadedImages() {
            nextTick(() => {
                const partners = this.partners;
                for (let i = 0; i < partners.length; i++) {
                    const refEl = this.logoImgRefs[i];
                    refEl.innerHTML = '';
                    refEl.appendChild(partners[i].imgObj);
                }
            })
        },

        onShowMoreButtonClick() {
            if (!this.isExpandButtonVisible) return;
            this.isExpanded = !this.isExpanded;
            this.updatePartnersVisibility();

            if (!this.isExpanded) {
                scrollToElement(document.body, 0);
            }
        },

        updatePartnersVisibility() {
            this.partners.forEach((item) => item['is_hidden'] = false);
            const partners = this.partners;
            for (let i = this.visibleItemsNumber; i < partners.length; i++) {
                partners[i]['is_hidden'] = !this.isExpanded;
            }
        },

        updateColumnsNum() {
            try {
                const gridCSS = window.getComputedStyle(this.$refs.pagePartnersContainerRef);
                this.columnsNum = gridCSS
                    .getPropertyValue("grid-template-columns")
                    .split(" ")
                    .length;
            } catch (e) {
                console.log(e);
            }
        },

        onResize() {
            if (this.resizeTimeout) {
                clearInterval(this.resizeTimeout);
            }
            this.resizeTimeout = setTimeout(() => {
                this.resizeTimeout = undefined;
                this.updatePartnersUI();
            }, 600);
        },

        updatePartnersUI() {
            this.$nextTick(() => {
                this.updateColumnsNum();
                this.componentKey++;
                this.updatePartnersVisibility();
                this.appendPreloadedImages();
            });
        },

        addLogoImgRef(el, idx) {
            this.logoImgRefs[idx] = el;
        },

        onSelectBoxClick() {
            this.isCategoriesContainerActive = !this.isCategoriesContainerActive;
        },
    }

}
</script>

<style scoped>
.tabs-block__item sup {
    color: #f5b404;
    /*opacity: 0.5;*/
    font-size: 0.6em;
    margin-top: -1.5em;
}

.page-partners__container a .badge {
    color: #f5b404;
    font-size: 0.7em;
    margin-top: -1.3em;
    /*opacity: 0.5;*/
    position: absolute;
    border-radius: 0.5em;
    padding: 0 0.5em;
}
</style>