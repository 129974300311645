<template>
  <nav class="breadcrumbs">
    <ul class="breadcrumbs__list">
      <li>
        <a href="/" class="breadcrumbs__link small-text _icon-house"></a>
      </li>
      <li v-for="link of links.slice(0, links.length-1)">
        <a :href="link.href" itemprop="item"
           class="breadcrumbs__link small-text"
        >{{ link.title }}</a>
      </li>
      <li>
        <span class="breadcrumbs__link small-text">
            {{ links[links.length-1].title }}
        </span>
      </li>
    </ul>
  </nav>
</template>


<script>
import { computed, reactive } from 'vue';
import { useHead } from '@vueuse/head';

const title = reactive({
    text: ''
});

export default {
    name: "BreadCrumbs",
    props: {
        'links': Array,
        'navTitle': String
    },

    watch: {
        links() {
            this.updateTitle();
        },
        navTitle() {
            this.updateTitle();
        }
    },
    setup() {
        useHead({
            title: computed(() => title.text)
        })
    },

    mounted() {
        this.updateTitle();
    },

    methods: {
        updateTitle() {
            let text = this.navTitle
                || this.links[this.links.length-1].title
                || '';
            if(text) text += ' - ';
            title.text = text + '3 Oaks Gaming';
        }
    }
}
</script>
