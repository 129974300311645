<template>
  <div class="move-lines">
    <div class="move-lines__title"><p>{{ title }}</p></div>
    <div class="move-lines__body" ref="moveLineBody">
      <slot v-for="i in slotRepeat" />
    </div>
  </div>
</template>


<script>
import { gsap } from '@/assets/js/gsap.min';

export default {
    name: 'MoveLine',
    props: {
        title: String,
        slotRepeat: {
            type: Number,
            default: 4
        }
    },
    mounted() {
        gsap.set(this.$refs.moveLineBody, {xPercent: -50})
        gsap.to(this.$refs.moveLineBody, {
            xPercent: -10,
            scrollTrigger: {
                trigger: this.$refs.moveLineBody,
                start: 'top bottom',
                end: 'bottom top',
                scrub: 1
            }
        })
    }
}
</script>
