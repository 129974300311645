<template>
    <div class="ntp-tournament__countdown"
         v-if="isTournamentNew"
         :class="{'ntp-is-stand-mode': isDemoStand}">
    <div class="ntp-countdown-dual">
      <div>
        <h5 class="ntp-countdown-title ntp-small">
          <img src="@/assets/img/tournaments/time-plate-start-icon.svg" alt="decor icon flag" />
          <span>Start</span>
        </h5>
        <div class="ntp-countdown-number ntp-small">
          <DateWidget
              :iso-date-string="tournament.start_at"
              :options="{day: 'numeric', month: 'numeric', year: 'numeric'}"
              :locale="dateLocale"
              :use-time-zone="dateTimeZone"
          />
        </div>
      </div>
      <div>
        <h5 class="ntp-countdown-title ntp-small">
          <img src="@/assets/img/tournaments/time-plate-finish-icon.svg" alt="decor icon flag" />
          <span>End</span>
        </h5>
        <div class="ntp-countdown-number ntp-small">
          <DateWidget
              :iso-date-string="tournament.end_at"
              :options="{day: 'numeric', month: 'numeric', year: 'numeric'}"
              :locale="dateLocale"
              :use-time-zone="dateTimeZone"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="ntp-tournament__countdown"
       :class="{'ntp-is-stand-mode': isDemoStand}"
       v-if="isTournamentInProgress">
    <h5 class="ntp-countdown-title">
      <img src="@/assets/img/tournaments/time-plate-finish-icon.svg" alt="decor icon flag" />
      <span>Ends in:</span>
    </h5>
    <div class="ntp-countdown-numbers">
      <span class="ntp-countdown-number">
        {{ String(timerData.days * 24 + timerData.hours).padStart(2, '0') }}
      </span>
      <span class="ntp-countdown-number">:</span>
      <span class="ntp-countdown-number">
        {{ String(timerData.minutes).padStart(2, '0') }}
      </span>
      <span class="ntp-countdown-number">:</span>
      <span class="ntp-countdown-number">
        {{ String(timerData.seconds).padStart(2, '0') }}
      </span>
    </div>
  </div>

  <div class="ntp-tournament__countdown"
       v-if="isTournamentFinished"
       :class="{'ntp-is-stand-mode': isDemoStand}">
    <h5 class="ntp-countdown-title">
      <img src="@/assets/img/tournaments/time-plate-finish-icon.svg" alt="decor icon flag" />
      <span>Finished</span>
    </h5>
    <div class="ntp-countdown-numbers">
      <span class="ntp-countdown-number">
        <DateWidget
              :iso-date-string="tournament.end_at"
              :options="{day: 'numeric', month: 'numeric', year: 'numeric'}"
              :locale="dateLocale"
              :use-time-zone="dateTimeZone"
          />
      </span>
    </div>
  </div>
</template>

<script>

import DateWidget from "@/components/date_widget";

export default {
    name: "TournamentTimePlateComponent",
    components: {
        DateWidget,
    },
    props: {
        tournament: Object,
        dateLocale: {
            type: String,
            default: undefined,
        },
        dateTimeZone: {
            type: String,
            default: undefined,
        },
        isPlayerAuth: {
          type: Boolean
        },
        isDemoStand: {
          type: Boolean
        }
    },

    data() {
        return {
            timerData: {
                total: 0,
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
                expired: false,
            },
            timer: undefined
        }
    },

    computed: {
        isTournamentNew() {
            const tournamentStatus = this.tournament?.status;
            return tournamentStatus === 'NEW'
                || tournamentStatus === 'PRE_NOTIFICATION';
        },

        isTournamentInProgress() {
            const tournamentStatus = this.tournament?.status;
            return tournamentStatus === 'STARTED' && !this.timerData.expired;
        },

        isTournamentFinished() {
            const tournamentStatus = this.tournament?.status;
            return tournamentStatus === 'EXPIRED'
                || tournamentStatus === 'POST_PROCESSING'
                || tournamentStatus === 'POST_NOTIFICATION'
                || tournamentStatus === 'FINISHED'
                || (tournamentStatus === 'STARTED' && this.timerData.expired);
        },
    },

    watch: {
        'tournament.status'(oldVal, newVal) {
            this.checkTimer();
        }
    },

    mounted() {
        this.checkTimer();
    },

    methods: {
        updateTimerData() {
            const total = Math.max(Date.parse(this.tournament.end_at) - Date.now(), 0);
            if (total > 0) {
                const days = Math.floor(total / (1000 * 60 * 60 * 24));
                const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
                const minutes = Math.floor((total / 1000 / 60) % 60);
                const seconds = Math.floor((total / 1000) % 60);
                this.timerData = {total, days, hours, minutes, seconds};
            } else if (!this.timerData.expired) {
                const timerData = this.timerData;
                timerData.total = 0;
                timerData.days = 0;
                timerData.hours = 0;
                timerData.minutes = 0;
                timerData.seconds = 0;
                timerData.expired = true;
            }
        },

        checkTimer() {
            if (this.isTournamentInProgress && !this.timer) {
                this.updateTimerData();
                this.timer = setInterval(() => {
                    if (!this.timerData.expired) {
                      this.updateTimerData();
                    } else {
                        clearInterval(this.timer);
                    }
                }, 1000);
            }
        },
    }


}
</script>

<style scoped>

</style>