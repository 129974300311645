<template>
  <div v-if="game.name" class="game-guide-download-container">
    <img :src="game.logo_file" :alt="game.title_text"/>
    <span v-html="game.title_html"></span>
  </div>
</template>

<script>
import {appFetchJSON} from "@/lib/request_utils";

export default {
    name: "GameGuideFileDownload",
    data() {
        return {
            gameName: '',
            game: {},
        }
    },

    mounted() {
        this.gameName = this.$route.query.game_name;
        this.fetchData();
    },

    methods: {
        fetchData() {
            const fetchUrl = `/games/${this.gameName}/short_info`;
            return appFetchJSON(fetchUrl)
                .then((res) => {
                    this.game = res.data;
                })
        }
    }
}
</script>

<style scoped>

.game-guide-download-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

img {
    width: 100px;
    height: 100px;
}
</style>