import {markRaw} from 'vue';
import EventBus from '@/lib/event_bus';
import LoginForm from '@/components/forms/login';
import ForgotPasswordForm from '@/components/forms/forgot_password';
import MessageForm from '@/components/forms/message';


const LoginFormComponent = markRaw(LoginForm);
const ForgotPasswordComponent = markRaw(ForgotPasswordForm);
const MessageFormComponent = markRaw(MessageForm);


export const showLoginModal = (options) => {
    if (!options) {
        options = {};
    }
    if (!options.title) {
        options.title = 'LOGIN';
    }
    EventBus.emit(EventBus.EVENTS.MODAL_OPEN_REQUEST, {
        component: LoginFormComponent,
        options: options
    });
};

export const showForgotPasswordModal = (options) => {
    if (!options) {
        options = {};
    }
    if (!options.title) {
        options.title = 'FORGOT PASSWORD';
    }
    EventBus.emit(EventBus.EVENTS.MODAL_OPEN_REQUEST, {
        component: ForgotPasswordComponent,
        options: options
    });
}

export const showMessageModal = (options) => {
    EventBus.emit(EventBus.EVENTS.MODAL_OPEN_REQUEST, {
        component: MessageFormComponent,
        options: options
    });
}
