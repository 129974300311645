<template>
  <div v-if="isVisible" class="gdpr-cookie-banner">
    <div
      v-if="IS_MANAGEMENT_BLOCK_ENABLED && isManagementBlockVisible"
      class="gdpr-cookie-banner__management-block"
    >
      <div class="gdpr-cookie-banner__management-block__description-container">
        <div v-if="cookiesDescription.strictly">
          These cookies are essential for you to browse the website and use its
          features, such as accessing secure areas of the site. Cookies that
          allow web shops to hold your items in your cart while you are shopping
          online are an example of strictly necessary cookies. These cookies
          will generally be first-party session cookies. While it is not
          required to obtain consent for these cookies, what they do and why
          they are necessary should be explained to the user.
        </div>
      </div>
      <div class="gdpr-cookie-banner__management-block__settings-container">
        <div class="checkbox">
          <input
            id="strictly"
            disabled
            type="checkbox"
            class="form__input checkbox__input _req"
            v-model="gdprCookiesSettings.strictly"
          />
          <label for="strictly" class="checkbox__label">
            <span>Strictly necessary cookies</span>
          </label>
        </div>

        <div class="checkbox">
          <input
            id="preferences"
            type="checkbox"
            class="form__input checkbox__input _req"
            v-model="gdprCookiesSettings.preferences"
          />
          <label for="preferences" class="checkbox__label">
            <span>Preferences cookies</span>
          </label>
        </div>

        <div class="checkbox">
          <input
            id="statistics"
            type="checkbox"
            class="form__input checkbox__input _req"
            v-model="gdprCookiesSettings.statistics"
          />
          <label for="statistics" class="checkbox__label">
            <span>Statistics cookies</span>
          </label>
        </div>

        <div class="checkbox">
          <input
            id="marketing"
            type="checkbox"
            class="form__input checkbox__input _req"
            v-model="gdprCookiesSettings.marketing"
          />
          <label for="marketing" class="checkbox__label">
            <span>Marketing cookies</span>
          </label>
        </div>
      </div>
    </div>
    <div class="gdpr-cookie-banner__accept-block">
      <div
        v-if="!(IS_MANAGEMENT_BLOCK_ENABLED && isManagementBlockVisible)"
      >
        <span>
          This website uses cookies to get the best experience on our website.
          <a v-if="!!privacyNoticeURL" :href="privacyNoticeURL" target="_blank"
            >Learn More</a
          >
        </span>
      </div>
      <div class="gdpr-cookie-banner__accept-block__buttons">
        <button
          v-if="IS_MANAGEMENT_BLOCK_ENABLED && !isManagementBlockVisible"
          class="no-border"
          @click.prevent="toggleManagementBlockVisibility()"
        >
          Manage Cookies
        </button>
        <button
          v-if="IS_MANAGEMENT_BLOCK_ENABLED && isManagementBlockVisible"
          @click.prevent="saveSettings"
        >
          Save Choices
        </button>
        <button v-if="IS_MANAGEMENT_BLOCK_ENABLED" @click.prevent="onDeclineButtonClick">
          Nesessary Cookies Only
        </button>
        <button v-if="!IS_MANAGEMENT_BLOCK_ENABLED" @click.prevent="onDeclineButtonClick">
          OK
        </button>
        <button
          v-if="IS_MANAGEMENT_BLOCK_ENABLED"
          @click.prevent="onAllowAllButtonClick"
        >
          Allow All
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { settings } from "@/lib/settings";

const IS_MANAGEMENT_BLOCK_ENABLED = false;

export default {
  name: "GDPRCookieBanner",
  props: {
    privacyNoticeURL: String,
  },

  data() {
    return {
      isVisible: false,
      isManagementBlockVisible: false,
      gdprCookiesSettings: {
        strictly: true,
        preferences: true,
        statistics: true,
        marketing: true,
      },

      cookiesDescription: {
        strictly: false,
        preferences: false,
        statistics: false,
        marketing: false,
      },
    };
  },

  mounted() {
    this.refreshVisibility();
  },

  setup() {
    return { IS_MANAGEMENT_BLOCK_ENABLED };
  },

  methods: {
    toggleManagementBlockVisibility() {
      // this.isAcceptBlockVisible = !this.isAcceptBlockVisible;
      this.isManageBlockVisible = !this.isManagementBlockVisible;
    },

    onDeclineButtonClick() {
      const gdprCookiesSettings = this.gdprCookiesSettings;
      gdprCookiesSettings.strictly = true;
      gdprCookiesSettings.preferences = false;
      gdprCookiesSettings.statistics = false;
      gdprCookiesSettings.marketing = false;
      this.saveSettings();
    },

    onAllowAllButtonClick() {
      const gdprCookiesSettings = this.gdprCookiesSettings;
      gdprCookiesSettings.strictly = true;
      gdprCookiesSettings.preferences = true;
      gdprCookiesSettings.statistics = true;
      gdprCookiesSettings.marketing = true;
      this.saveSettings();
    },

    saveSettings() {
      settings.GDPR.setSettings({ ...this.gdprCookiesSettings });
      this.refreshVisibility();
    },

    refreshVisibility() {
      this.isVisible = !settings.GDPR.isProcessedByUser;
    },
  },
};
</script>
