import mitt from 'mitt';


const emitter = mitt();
const EVENTS = {
    MODAL_OPEN_REQUEST: 'MODAL_OPEN_REQUEST',
    USER_LOADED: 'USER_LOADED',
    USER_LOADING_ERROR: 'USER_LOADING_ERROR',
    BODY_LOCK_REQUEST: 'BODY_LOCK_REQUEST',
    BODY_UNLOCK_REQUEST: 'BODY_UNLOCK_REQUEST',
    HEADER_HIDE: 'HEADER_HIDE'
};
const EVENTS_LIST = Object.values(EVENTS);

export default {
    EVENTS: EVENTS,
    on: (event, callable) => {
        if(EVENTS_LIST.indexOf(event) < 0) {
            throw 'UNKNOWN EVENT!';
        }
        emitter.on(event, callable);
    },
    off: (event, callable) => {
        if(EVENTS_LIST.indexOf(event) < 0) {
            throw 'UNKNOWN EVENT!';
        }
        emitter.off(event, callable);
    },
    emit: (event, options) => {
        if(EVENTS_LIST.indexOf(event) < 0) {
            throw 'UNKNOWN EVENT!';
        }
        emitter.emit(event, options);
    }
};
