<template>
  <div>
    <div class="icons-section no-print">
      <div ref="swiper" class="icons-section__wrapper touch-slider _swiper">
        <div class="swiper-wrapper">
          <div class="icon-item swiper-slide" v-for="item of item.features">
            <div class="icon-item__head">
              <img v-if="item.image" :src="item.image" alt="3oaks">
              <img v-else src="@/assets/img/svg/oaks.svg" alt="3oaks">
            </div>
            <div class="icon-item__title">
              <p>{{ item.name }}</p>
            </div>
            <p>{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="icons-section print-only">
      <div class="icon-item" v-for="item of item.features">
        <div class="icon-item__head">
          <img v-bind:src="item.image" alt="3oaks">
        </div>
        <div>
          <div class="icon-item__title">
            <p>{{ item.name }}</p>
          </div>
          <p>{{ item.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Swiper from '@/assets/js/Swiper';
import {isMobile} from "@/lib/browser";

export default {
    name: "GameFeaturesSlider",
    props: {
        item: Object
    },

    data() {
        return {
            swiper: null
        }
    },

    mounted() {
        this.initSlider();
    },

    methods: {
        initSlider() {
            if ( !(this.item.features && this.item.features.length > 0)) {
                return
            }

            let swiper = new Swiper(this.$refs.swiper, {
                observer: true,
                speed: 900,
                observeParents: true,
                slidesPerView: 1.3,
                autoHeight: true,
                grabCursor: true,
                slideActiveClass: '_active-slide',
                breakpoints: {
                    320: {
                        spaceBetween: 16,
                        slidesPerView: 1.3,
                    },
                    480: {
                        spaceBetween: 24,
                        slidesPerView: 2.2,
                    },
                    780: {
                        spaceBetween: 32,
                        slidesPerView: 2.5,
                    },
                    992: {
                        spaceBetween: 40,
                        slidesPerView: 3,
                    },
                    1920: {
                        spaceBetween: 48,
                        slidesPerView: 4,
                    }
                }
            });

            swiper.init();
            this.swiper = swiper;

        },
    }
}
</script>
