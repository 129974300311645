<template>
  <main class="page reset-password-page">
    <div class="first-screen _container">
      <div class="reset-password__wrapper">
        <h6 class="full-width">CHANGE <span>PASSWORD</span></h6>
        <!-- RESET PASSWORD FORM ====================================== -->
        <div class="_container offset-section">
          <div class="contacts-page__content">
            <div class="page-form">
              <form class="form">
                <div class="form__wrapper">
                  <div class="form__item"
                       :class="{ _error: !!errors.password1 }"
                  >
                    <label class="form__label" for="formPassword1">
                      New password
                    </label>
                    <input id="formPassword1" name="password1"
                           class="form__input _req _password"
                           placeholder="New password"
                           minlength="4" v-model="password1"
                           :class="{ _error: !!errors.password1 }"
                           :type="showPassword1? 'text': 'password'">
                    <div class="form__icon _icon-hide form__viewpass"
                         @click="onShowPassword1ButtonClick"
                         :class="{ _active: showPassword1 }"></div>
                    <div class="form__error _icon-error">
                      {{ (errors.password1 || []).join(', ') }}
                    </div>
                  </div>

                  <div class="form__item"
                       :class="{ _error: !!errors.password2 }"
                  >
                    <label class="form__label" for="formPassword2">
                      Confirm new password
                    </label>
                    <input id="formPassword2" name="password2"
                           class="form__input _req _password"
                           placeholder="Confirm new password"
                           minlength="4" v-model="password2"
                           :class="{ _error: !!errors.password2 }"
                           :type="showPassword2? 'text': 'password'">
                    <div class="form__icon _icon-hide form__viewpass"
                         @click="onShowPassword2ButtonClick"
                         :class="{ _active: showPassword2 }"></div>
                    <div class="form__error _icon-error">
                      {{ (errors.password2 || []).join(', ') }}
                    </div>
                  </div>

                  <div class="form__item">
                    <input type="hidden" name="token"
                           class="form__input _req _password"
                           v-model="token">
                    <div class="form__error _icon-error"></div>
                  </div>
                </div>

                <div class="form__controls">
                  <div class="form__submit">
                    <button type="button" class="page-button"
                            @click="onResetButtonClick">
                      <span></span>
                      <span>CONFIRM CHANGE PASSWORD</span>
                      <span></span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- RESET PASSWORD FORM ====================================== END -->
      </div>
    </div>
  </main>
</template>


<script>
import Auth from '@/lib/auth';
import { showMessageModal } from '@/lib/modal_utils';

export default {
    name: 'ResetPassword',
    props: {
        token: String
    },

    data() {
        return {
            password1: '',
            password2: '',

            showPassword1: false,
            showPassword2: false,
            errors: {
                password1: '',
                password2: ''
            }
        };
    },

    methods: {
        onShowPassword1ButtonClick() {
            this.showPassword1 = !this.showPassword1;
        },

        onShowPassword2ButtonClick() {
            this.showPassword2 = !this.showPassword2;
        },

        onResetButtonClick() {
            let errors = {};
            if (this.password1 === '') {
                errors['password1'] = ['This field is required.']
            }
            if (this.password2 === '') {
                errors['password2'] = ['This field is required.']
            }
            if (this.password1 !== this.password2) {
                errors['password2'] = ['Incorrect']
            }
            this.errors = errors;
            if(Object.entries(this.errors).length > 0) return;

            Auth.confirmResetPassword({
                password: this.password1,
                token: this.token,
                success: this.onResetSuccess,
                error: this.onResetError
            });

        },

        onResetSuccess(res) {
            this.errors = res.errors || {};

            if (res.msg === 'password reset') {
                showMessageModal({
                    title: 'INFO',
                    text: 'Your password was successfully changed',
                    onClose: e => {this.$router.push('/')}
                });
            }
        },

        onResetError(res) {
            console.log('------------ TODO CONFIRM PASSWORD HANDLE ERROR!')
        },
    }
}

</script>

<!-- style scoped>

h3 {
    padding: 30px 0;
}

label {
    display: inherit;
}
.form__viewpass {
    color: #999;
}


.form__submit {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
}

.reset-password__wrapper {
    max-width: 500px;
    margin-bottom: 180px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 128px;
    text-align: center;
}

.reset-password__wrapper h4 {
    margin-bottom: 24px;
}

.reset-password__wrapper p {
    margin-bottom: 40px;
}

.reset-password-page {
    display: block;
}

</style -->