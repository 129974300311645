<template>
  <main class="page documentation-user">
    <div class="page__body documentation-user__body">
      <div class="first-screen offset-section_small first-screen_left">
        <div class="_container">
          <!-- BREAD CRUMBS ============================================= -->
          <BreadCrumbs
              :links="[{title: 'Documents'}]"
          />
          <!-- BREAD CRUMBS ========================================= END -->

          <!-- PAGE DESCRIPTION ======================================= -->
          <div class="first-screen__body">
            <div class="first-screen__left">
              <div class="page-heading page-heading_colors">
                <h1><span>DOCUMENTATION</span></h1>
              </div>
              <div class="page-description page-description_offset _icon-arrow-2">
                <p>
                  Players love our slots! Our games are crafted with a
                  precise attention to every detail of the gameplay experience,
                  maximizing players’ retention and casino profit
                </p>
              </div>
            </div>
            <div class="first-screen__right"></div>
          </div>
          <!-- PAGE DESCRIPTION ======================================= END -->
        </div>
      </div>

      <!-- =================================== DOCUMENTS INTEGRATION SECTION ===================================  -->
      <DocumentsIntegrationSection/>
      <!-- =================================== DOCUMENTS INTEGRATION SECTION ===================================  END-->

      <!-- =================================== SUPPORTING MATERIALS SECTION ===================================  -->
      <SupportingMaterialsSection/>
      <!-- =================================== SUPPORTING MATERIALS SECTION =================================== END -->

    </div>
  </main>

  <!--  documents:-->
  <!--  <div>-->
  <!--    {{ documents }}-->
  <!--  </div>-->
</template>

<script>

import BreadCrumbs from "@/components/breadcrumbs";
import DocumentsIntegrationSection from "./integration_documentation_section";
import SupportingMaterialsSection from "./supporting_materials_section"

export default {
    name: 'Documents',
    components: {
        BreadCrumbs,
        DocumentsIntegrationSection,
        SupportingMaterialsSection,
    }
}
</script>