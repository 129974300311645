<template>
  <main class="page login-page">
    <div class="first-screen _container" v-if="!user.is_authorized">
      <div class="page-heading">
        <h6>Login</h6>
      </div>
      <p>Please, login to download file</p>
      <LoginForm :events-only="true" @login-success="onLoginSuccess"/>
    </div>

    <div class="first-screen _container" v-if="user.is_authorized">
      <div class="page-heading page-heading_colors">
        <h1><span>{{ fileTypeTitle }} <em>Download</em></span></h1>
      </div>

      <div>
        <div v-if="fileType === 'game_guide'">
          <GameGuideFileDownload />
        </div>
        <div>
          <p v-if="firstDownload">
            Download will start automatically in a few seconds. <br/>
            If nothing happens you can try to <a href="#" @click.prevent="startDownload">download</a> one more time.
          </p>

          <p v-if="!firstDownload">
            Looks like this file has already been downloaded by you. <br/>
            But you are welcome to <a href="#" @click.prevent="startDownload">download</a> this file one more time. <br/>
          </p>

          <div>
            <a href="#" class="page-button" @click="startDownload">
              <div></div>
              <div v-if="!firstDownload">DOWNLOAD AGAIN</div>
              <div v-if="firstDownload">DOWNLOAD</div>
              <div></div>
            </a>

            <a href="/" class="page-button">
              <div></div>
              <div>BACK TO HOME</div>
              <div></div>
            </a>
          </div>
        </div>
      </div>
    </div>

  </main>
</template>


<script>
import LoginForm from '@/components/forms/login';
import GameGuideFileDownload from "@/pages/files_download/game_guide_file_download";

const COOKIE_KEY_NAME = 'downloaded-files';

export default {
    name: 'FilesDownload',
    components: {
        GameGuideFileDownload,
        LoginForm
    },

    props: {
        user: Object,
    },

    data() {
        return {
            targetURL: '',
            firstDownload: true,
        }
    },

    mounted() {
        this.init();
    },

    computed: {
        fileType() {
            return this.$route.query.type;
        },

        fileTypeTitle() {
            if (this.fileType === 'game_guide') {
                return 'Game Guide';
            }
            return 'File';
        },
    },

    methods: {
        init() {
            let targetURL = this.$route.query.url;
            if (!targetURL) {
                return;
            }

            if (targetURL.startsWith('/')) {
                targetURL = `${window.location.origin}${targetURL}`;
            }

            this.targetURL = targetURL;

            this.firstDownload = !this.isDownloaded(targetURL);

            if (this.firstDownload && this.user.is_authorized) {
                this.startDownload();
            }
        },

        onLoginSuccess() {
            this.$router.go();
        },

        startDownload() {
            if (this.user.is_authorized) {
                this.setDownloaded(this.targetURL);
                window.location.href = this.targetURL;
            }
        },

        isDownloaded() {
            const filesList = this.getDownloadedFilesList();
            return filesList && filesList.length && filesList.indexOf(this.targetURL) >= 0;
        },

        setDownloaded() {
            const filesList = this.getDownloadedFilesList();
            const targetURL = this.targetURL;
            if (filesList.indexOf(targetURL) === -1) {
                filesList.push(targetURL);
                this.$cookies.set('downloaded-files', JSON.stringify(filesList));
            }
        },

        getDownloadedFilesList() {
            const filesListCookieVal = this.$cookies.get(COOKIE_KEY_NAME) || '[]';
            let filesList;
            try {
                filesList = JSON.parse(filesListCookieVal);
            } catch (err) {
                console.warn('FilesDownload: unable to parse cookie');
                filesList = [];
            }
            return filesList;
        },

        clean() {
            this.$cookies.remove(COOKIE_KEY_NAME);
        }
    }

}
</script>

<style scoped>
p {
    margin: 40px 0;
}

p a {
    color: #f5b404
}

.page-button {
    margin-right: 20px;
    margin-top: 12px;
}
</style>