<template>
  <main objname="certificates" class="games-grid">
    <SummaryGrid
        v-if="gridOptions !== null"
        :options="gridOptions"
        :user="user"/>
  </main>
</template>


<script>
import {appFetch} from '@/lib/request_utils';
import SummaryGrid from './grid';
import {isMobile} from "@/lib/browser";

const useFixedColumns = !isMobile.any();

let fullSchema = [
    {
        "name": "uid",
        "type": "text",
        "title": "pk",
        "width": "0"
    },
    {
        "name": "game_id",
        "type": "text",
        "title": "Game ID",
        "cssClass": "summary-left-align-column",
        "width": "200"
    },
    {
        "name": "platform_game_id",
        "type": "text",
        "title": "Platform Game ID",
        "width": "100"
    },
    {
        "name": "title_en",
        "type": "text",
        "title": "English Title",
        "width": "150",
        "cssClass": "summary-left-align-column",
        "fixed": useFixedColumns
    },

]


export default {
    name: 'SummaryTabCertificates',
    components: {
        SummaryGrid
    },
    props: {
        user: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            tab: 'games',
            gridOptions: null
        }
    },
    methods: {
        loadData() {
            let options = {
                method: 'GET',
                credentials: 'include',
                params: {}
            }
            appFetch(`/summary/certificates`, options)
                .then(res => res.json())
                .then(res => {
                    let resData = res.data;
                    let schema = this.prepareSchema(resData);
                    this.gridOptions = {
                        ...{
                            title: '3 OAKS GAMES',
                            csvFileName: 'certificates',
                            pkColumn: 'uid',
                            fixedColumnsNumber: useFixedColumns ? 3 : 0,
                            activeTab: 'certificates',
                            gridID: 'certificates'
                        },
                        ...schema
                    };

                    this.processData(resData);
                })
                .catch(res => {
                    console.log('------------ TODO GET GAME HANDLE ERROR!', res)
                });
        },

        prepareSchema(res) {
            let allowedSchema = [],
                registeredColumns = [],
                nestedColumns = [];

            let columns = res.columns;
            fullSchema.forEach(c => {
                if (columns.indexOf(c.name) > -1) {
                    allowedSchema.push({...c});
                    registeredColumns.push(c.name);
                    nestedColumns.push({
                        colspan: 1,
                        title: "&nbsp;",
                        width: c.width
                    });
                }
            });

            columns.forEach(c => {
                if (registeredColumns.indexOf(c) < 0) {
                    allowedSchema.push({
                        type: 'color_marked_links',
                        width: 100,
                        name: c,
                        title: c,
                    });
                    nestedColumns.push({
                        colspan: 1,
                        title: "&nbsp;",
                        width: 50
                    });
                }
            });

            return {
                columns: allowedSchema,
                nestedHeaders: nestedColumns,
                data: res.rows
            }
        },

        processData(res) {
            let columns = res.columns;
            let registeredColumns = [];
            fullSchema.forEach(c => {
                if (columns.indexOf(c.name) > -1) {
                    registeredColumns.push(c.name);
                }
            });

            let rows = res.rows;
            columns.forEach(c => {
                if (registeredColumns.indexOf(c) < 0) {
                    rows.forEach(rowData => {
                        let objLinks = rowData[c]?.links || [];
                        objLinks.forEach(l => {
                            if (l.type !== 'media') {
                                l.target = '_blank';
                                l.isDownloadable = false;
                            }
                        })
                    });
                }
            });
        }
    },
    mounted() {
        this.loadData();
    },
    computed: {},
}
</script>
