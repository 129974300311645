<template>
  <div class="game-runner" :class="{desktop: !isMobile, mobile: isMobile}">

    <div class="button-controls">
      <ul class="button-controls__list">
        <li class="language-item">
          <span @click="onLanguageButtonClick">
            <img src="@/assets/img/svg/earth.svg" alt="3oaks">
          </span>
        </li>
        <li class="feature-trigger-item">
          <span>
            <img src="@/assets/img/svg/star.svg" alt="3oaks">
          </span>
        </li>
      </ul>
    </div>

    <div class="languages-controls" :class="{active: isLanguageControlActive}">
      <div class="radio" v-for="lang of languages">
        <input type="radio" name="lang"
               :checked="lang.code === selectedLang"
               :value="lang.code"
               :id="`lang-${lang.code}`"
               @click="onLanguageItemClick"
        >
        <label :for="`lang-${lang.code}`">{{ lang.name }}</label>
      </div>
    </div>
    <div v-if="isMobile">
      <a :href="localizedGameUrl" :target="bannerHrefTarget">
        <img v-if="banner" class="banner" :src="banner" alt="3oaks">
        <div v-else class="banner-mock"></div>
      </a>
    </div>
    <iframe class="game-play-frame"
            allowtransparency="true"
            ref="iframe"
            :src="localizedGameUrl"
            v-if="!isMobile"></iframe>

  </div>
</template>

<script>
import {isMobile} from "@/lib/browser";


export default {
    name: "GameRunner",
    props: {
        banner: String,
        languages: Array,
        gameUrl: String,
        lang: String,
        openInNewTab: Boolean
    },

    data() {
        return {
            isLanguageControlActive: false,
            selectedLang: null,
            isMobile: isMobile.any(),
        }
    },

    created() {
        this.selectedLang = this.lang || 'en';
    },

    mounted() {
        window.addEventListener('blur', this.onLanguagePanelBlur);
    },

    computed: {
        localizedGameUrl() {
            let href = new URL(this.gameUrl);
            href.searchParams.set('lang', this.selectedLang);
            return href.toString();
        },

        bannerHrefTarget() {
            if (this.openInNewTab) {
                return '_blank';
            }
            return '_self'
        }
    },

    methods: {
        onLanguagePanelBlur() {
            this.isLanguageControlActive = false;
        },

        onLanguageItemClick(e) {
            this.selectedLang = e.target.value;

            let href = new URL(window.location.href);
            href.searchParams.set('lang', this.selectedLang);
            history.replaceState(
                {}, document.title, href.toString());

            if(this.isMobile) {
                window.location.href = this.localizedGameUrl;
            }
        },

        onLanguageButtonClick() {
            this.isLanguageControlActive = !this.isLanguageControlActive;
        }
    }
}
</script>
