<template>
  <div class="move-lines__title" ref="root"><p>SUPPORTING MATERIALS</p></div>

  <div class="_container offset-section">
    <div class="filter">
      <form class="filter-form" action="#" data-message="success" @submit.prevent="onSearchClick">
        <div class="filter-form__wrapper">
          <div class="filter-form__search">

            <div class="search">
              <input
                  v-model="searchFilters.title"
                  autocomplete="off"
                  type="text"
                  name="form[]"
                  data-value="Search: By game`s name"
                  class="search-form__input"
                  @keyup.enter="onSearchClick"
              />
              <button type="submit" class="search-form__btn"></button>
            </div>

          </div>
          <div class="filter-form__checks">
            <div class="checkbox" v-for="item of documentTypes">
              <input
                  type="checkbox"
                  class="checkbox__input _req"
                  :id="item.key"
                  :name="item.key"
                  :value="item.key"
                  v-model="searchFilters.types"
                  @change="onSearchClick"
              />
              <label :for="item.key" class="checkbox__label">
                <span>{{ item.title }}</span>
              </label>
            </div>
          </div>
        </div>
      </form>
      <div class="filter__body">
        <DocumentCard
            v-for="item in documents"
            :title="item.title"
            :url="item.url"
            :target="target"
        />
      </div>
    </div>
    <div>
      <Paging
          :total-pages="totalPages"
          :current-page="currentPage"
          @on-page-click="onPageClick"
      />
    </div>
  </div>
</template>

<script>
import {isMozilla} from "@/lib/browser";
import {appFetchJSON, processFetchError} from "@/lib/request_utils";
import {scrollToElement} from "@/lib/common_utils";

import DocumentCard from "@/components/document_card";
import Paging from "@/components/paging"
import EventBus from "@/lib/event_bus";

export default {
    name: "SupportingMaterialsSection",
    components: {
        DocumentCard,
        Paging
    },
    data() {
        return {
            documents: [],
            documentTypes: [],
            totalPages: 0,
            currentPage: 1,
            searchFilters: {
                types: [],
                title: '',
                pageNum: 1,
            },
            scrollToSearchField: false,
        }
    },

    watch: {
        '$route.query'() {
            this.updateData();
        }
    },

    mounted() {
        this.updateData();
    },

    computed: {
        target() {
            return isMozilla() ? '_blank' : '_self';
        }
    },

    methods: {
        fetchDocuments() {
            const params = {
                page_num: this.searchFilters.pageNum
            }
            if (this.searchFilters.types.length) params.types = this.searchFilters.types;
            if (this.searchFilters.title) params.title = this.searchFilters.title
            const options = {
                params: params,
            }
            return appFetchJSON(`/documents`, options)
                .then(res => {
                    this.documents = res.data?.documents || [];
                    this.totalPages = res.data?.['total_pages'] || 0;
                })
                .catch(err => {
                    processFetchError(this.$router, err);
                });
        },

        fetchDocumentTypes() {
            return appFetchJSON('/documents/types')
                .then(res => {
                    this.documentTypes = res.data;
                })
                .catch(err => {
                    this.documentTypes = [];
                });
        },

        search(scrollToSearchField) {
            scrollToSearchField = !!scrollToSearchField;
            const searchFilters = this.searchFilters;
            const filterData = {
                page: searchFilters.pageNum,
                title: searchFilters.title || '',
                types: searchFilters.types,
                scroll: scrollToSearchField
            }
            const routeData = {
                path: this.$route.fullPath,
                query: {...this.$route.query || {}, ...filterData},
            }
            this.$router.push(routeData);
        },

        onSearchClick(event) {
            this.searchFilters.pageNum = 1;
            // this.scrollToSearchField = false;
            this.search();
        },

        onPageClick(pageNum) {
            this.searchFilters.pageNum = pageNum;
            this.scrollToSearchField = true;
            this.search(true);
        },

        updateFiltersFromRouteQuery() {
            const searchFilters = this.searchFilters;
            const routeQuery = this.$route.query;
            searchFilters.pageNum = Number(routeQuery?.page || 1);
            searchFilters.title = String(routeQuery?.title || '');
            let types = routeQuery?.types || [];
            if (typeof types === 'string' || types instanceof String) {
                types = [types];
            }
            searchFilters.types = types;
        },

        updateScrollToSearchField() {
            const scrollParam = this.$route.query?.scroll;
            this.scrollToSearchField = scrollParam && scrollParam === 'true';
        },

        updateData() {
            this.updateFiltersFromRouteQuery();
            this.updateScrollToSearchField();
            this.fetchDocumentTypes().then(() => {
                if (this.scrollToSearchField) {
                    scrollToElement(this.$refs.root, 0).then(() => {
                        EventBus.emit(EventBus.EVENTS.HEADER_HIDE);
                    });
                }
                this.fetchDocuments().then(() => {
                    this.currentPage = this.searchFilters.pageNum;
                });
            });
        }
    },

}
</script>
