<template>
  <div
      v-if="!player.is_authorized"
      class="login-form"
      :class="activeTabIndex === 1 ? 'green-bg': 'grey-bg'"
  >
    <div class="tabs">
      <div
          class="tab"
          :class="{tab__active: activeTabIndex === 0}"
          @click.prevent="onTabClick(0)">
        Log In
      </div>
      <div
          class="tab"
          :class="{tab__active: activeTabIndex === 1}"
          @click.prevent="onTabClick(1)">
        Sign Up
      </div>
    </div>
    <div class="tabs-content">

      <div class="tab-content" v-if="activeTabIndex === 0">
        <div class="form-item" :class="{error: errors.name}">
          <span v-if="errors.name" class="form-error">{{ errors.name }}</span>
          <input type="text" placeholder="Nickname" v-model="name"/>
        </div>
        <div class="form-item" :class="{error: errors.email}">
          <span v-if="errors.email" class="form-error">{{ errors.email }}</span>
          <input type="text" placeholder="Email" v-model="email">
        </div>
        <div v-if="errorsCommon" class="form-error-common">{{ errorsCommon }}</div>
        <button class="gradient" @click.prevent="onLoginButtonClick()">Log in</button>
        <div>Not a member? <a href="#" @click.prevent="onTabClick(1)">Sign up</a></div>
      </div>

      <div class="tab-content" v-if="activeTabIndex === 1">
        <div class="form-item" :class="{error: errors.name}">
          <span v-if="errors.name" class="form-error">{{ errors.name }}</span>
          <input type="text" placeholder="Nickname" v-model="name"/>
        </div>
        <div class="form-item" :class="{error: errors.email}">
          <span v-if="errors.email" class="form-error">{{ errors.email }}</span>
          <input type="text" placeholder="Email" v-model="email">
        </div>
        <div class="form-item" :class="{error: errors.company}">
          <span v-if="errors.company" class="form-error">{{ errors.company }}</span>
          <input type="text" placeholder="Company" v-model="company">
        </div>
        <div v-if="errorsCommon" class="form-error-common">{{ errorsCommon }}</div>
        <button class="bordered" @click.prevent="onSignUpButtonClick()">Sign up</button>
        <div>Already registered? <a href="#" @click.prevent="onTabClick(0)">Log in</a></div>
      </div>

    </div>
  </div>

  <div class="user-profile-plate" v-if="player.is_authorized">
    <span>Welcome,</span>
    <span class="user-name">
      <img src="@/assets/img/tournaments/user-profile-plate-icon.png"/>
      {{ player.name }}
    </span>
    <div><a href="#" @click.prevent="onLogoutClick">Log out</a></div>
  </div>
</template>

<script>

import {appFetchJSON, processFetchError} from "@/lib/request_utils";

export default {
    name: "TournamentLoginForm",

    props: {
        user: Object,
        player: Object,
        tournament_uid: Number,
    },

    mounted() {

    },

    data() {
        return {
            activeTabIndex: 0,
            name: '',
            email: '',
            company: '',
            errors: {
                name: '',
                email: '',
                company: ''
            },
            errorsCommon: '',
        }
    },

    methods: {
        onTabClick(tabIndex) {
            this.activeTabIndex = tabIndex;
            this.resetErrors();
        },

        onLoginButtonClick() {
            const url = `/tournaments/login`;
            const options = {
                params: {
                    tournament_uid: this.tournament_uid,
                    email: this.email,
                    name: this.name,
                }
            }
            appFetchJSON(url, options)
                .then(res => {
                    this.$router.go();
                })
                .catch(this.processErrors);
        },

        onSignUpButtonClick() {
            const url = `/tournaments/signup`;
            const options = {
                params: {
                    tournament_uid: this.tournament_uid,
                    email: this.email,
                    name: this.name,
                    company: this.company,
                }
            }
            appFetchJSON(url, options)
                .then(res => {
                    this.$router.go();
                })
                .catch(this.processErrors);
        },

        onLogoutClick() {
            const url = `/tournaments/logout`;
            appFetchJSON(url)
                .then(res => {
                    this.$router.go();
                })
                .catch(this.processErrors);
        },

        processErrors(err) {
            console.log(err);
            this.resetErrors();
            const res = err.response;
            if (res) {
                res.json().then((data) => {
                    const errors = (data || {}).errors;
                    if (typeof errors === 'string' || errors instanceof String) {
                        this.errorsCommon = errors;
                        return;
                    }

                    if (Array.isArray(errors)) {
                        this.errorsCommon = errors[0];
                        return;
                    }

                    if (errors) {
                        try {
                            for (let [key, val] of Object.entries(errors)) {
                                if (Array.isArray(val)) {
                                    val = val[0];
                                }
                                this.errors[key] = val;
                            }
                        } catch (err) {
                            processFetchError(this.$router, err);
                        }
                        return;
                    } else {
                        processFetchError(this.$router, err);
                    }
                });
            } else {
                processFetchError(this.$router, err);
            }
        },

        resetErrors() {
            this.errors = {};
            this.errorsCommon = '';
        }
    }

}
</script>

<style scoped>

</style>