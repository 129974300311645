<template>
  <main></main>
</template>


<script>

import {isMobile} from "@/lib/browser";
import "jsuites/dist/jsuites.css";
import jexcel from '@/lib/patches/jspreadsheet-ce';
import "@/lib/patches/jspreadsheet-ce/jspreadsheet.css";
import {appFetch} from '@/lib/request_utils';


export default {
    name: 'SummaryGrid',
    components: {
    },

    props: {
        options: Object,
        activeTab: String,
        user: Object
    },

    data() {
        return {
            grid: null,
            observer: null
        }
    },

    created () {
        window.addEventListener('scroll', this.onScroll);
    },

    destroyed () {
        window.removeEventListener('scroll', this.onScroll);
    },

    mounted() {
        this.initGrid();
        this.initObserver();
    },

    methods: {
        initGrid() {
            let el = this.$el;
            let options = {...{
                    isMobile: isMobile.any(),
                    about: false,
                    wordWrap: true,
                    editable: false,
                    rowDrag: false,
                    allowInsertRow: false,
                    allowDeleteRow: false,
                    allowInsertColumn: false,
                    allowDeleteColumn: false,
                    allowRenameColumn: false,
                    stripHTML: false,
                    bodyBackground: '#fff',
                    includeHeadersOnDownload: true,
                    includeHeadersOnCopy: true,
                    csvHeaders: true,
                    csvDelimiter: '\t',
                    stripHTMLOnCopy: true,

                    allowComments: this.user.is_staff,
                }, ...this.options};

            // URL query update
            options.onselection = function (e) {
                //$(e).parent().trigger('change_select', arguments);
            };

            this.grid = jexcel(el, options);
        },

        initObserver() {
            let gridID = this.options.gridID;
            let self = this;
            this.observer = new MutationObserver((mutations) => {
                let outputData = [],
                    processDict = {};
                mutations.forEach((mObject) => {
                    let record_id = mObject.target.data.row,
                        field = mObject.target.data.col,
                        comment = mObject.target.title,
                        cssList = mObject.target.style,
                        css = {},
                        object_name = gridID,
                        cellValue = {
                            object_name: object_name,
                            record_id: record_id,
                            field: field
                        };

                    for (let idx = 0; idx < cssList.length; idx++) {
                        let cssAttr = cssList[idx];
                        if (['white-space', 'overflow', 'position',
                            'left', 'border-right-color',
                            'border-right-style', 'border-right-width',
                            'border-right', 'border-left-color',
                            'border-left-style', 'border-left-width', 'border-left',
                            'overflow-x', 'overflow-y'].includes(cssAttr)) {
                            continue;
                        }
                        let attrValue = mObject.target.style[cssAttr];
                        if ('text-align' === cssAttr && attrValue === 'center') {
                            continue;
                        }
                        css[cssAttr] = attrValue;
                    }

                    if (Object.keys(css).length > 0) {
                        cellValue['css'] = css;
                    }
                    if (comment) {
                        cellValue['comment'] = comment
                    }
                    processDict[[record_id,field]] = cellValue
                });

                let kList = Object.keys(processDict);
                for (let i = 0; i < kList.length; i++) {
                    outputData.push(processDict[kList[i]]);
                }
                self.setSummaryOption({items:outputData});
                //$(obj.el).parent().trigger('change_cell_attribute', {items:outputData});

            });

            let td_list = this.$el.querySelectorAll('td');
            for (let i=0; i < td_list.length; i++) {
                if (td_list[i].data) {
                    this.observer.observe(td_list[i],
                        { attributes : true,
                            attributeFilter : ['style', 'title'] });
                }
            }
        },

        setSummaryOption(outputData) {
            if (!this.user.is_staff) {
                return;
            }
            outputData['page_name'] = this.options.activeTab;

            appFetch(`/summary/set-options`, {
                method: 'POST',
                contentType: "application/json; charset=utf-8",
                body: JSON.stringify(outputData),
            }).then(
                res => res.json()
            ).catch(res => {
                console.log('------------ HANDLE ERROR!', res)
            });
        },

        onScroll(e) {
            this.grid && this.freezeTitle();
        },

        freezeTitle: function () {
            let rect = this.grid.el.getBoundingClientRect(),
                titleEl = this.$el.querySelector('.jexcel_grid_title');
            if (rect.height - 130 + rect.top > 0) {
                titleEl.removeAttribute('disabled', false);
            } else {
                titleEl.setAttribute('disabled', true);
            }
        }

    },

}
</script>

<style scoped>
.jexcel_grid_title[disabled] {
    visibility: hidden;
}
</style>


