<template>
  <transition name="active">
    <div class="popup" :class="{show: !!component}" @click.stop>
      <div class="popup__content">
        <div class="popup__body">
          <div class="popup__title">
            <h4>{{ options.title }}</h4>
            <div class="popup__close _icon-plus"
                 @click="onCloseBtnClick"></div>
          </div>
          <component
            :is="component"
            :options="options"
            @close="onModalComponentClose"
        />
        </div>
      </div>
    </div>
  </transition>
</template>


<script>
import EventBus from '@/lib/event_bus'

export default {
    name: 'ModalRoot',
    components: {},

    data() {
        return {
            component: null,
            options: {}
        }
    },

    watch: {
        component(value) {
            if (value) {
                EventBus.emit(EventBus.EVENTS.BODY_LOCK_REQUEST);
            } else {
                EventBus.emit(EventBus.EVENTS.BODY_UNLOCK_REQUEST);
            }
        }
    },

    created() {
        EventBus.on(EventBus.EVENTS.MODAL_OPEN_REQUEST, (
            { component, options = {title: ''}}) => {
              this.component = component
              this.options = options
          })
        document.addEventListener('keyup', this.onKeyup)
    },

    beforeDestroy() {
        document.removeEventListener('keyup', this.onKeyup)
    },

    methods: {
        close() {
            this.component = null;
            if (this.options.onClose) {
                this.options.onClose()
            }
        },

        onCloseBtnClick() {
            this.close();
        },

        onKeyup(e) {
            if (e.keyCode === 27) this.close();
        },

        onModalComponentClose(e) {
            this.close();
            if (e && e.pageReload) this.$router.go();
        },
    }
}
</script>

