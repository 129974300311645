<template>
  <div class="page-menu" :class="{_open: isOpened}">
    <div class="page-menu__wrapper">
      <div class="page-menu__left-side"></div>
      <div class="page-menu__border-side"></div>
      <div class="page-menu__content">
        <div class="_container page-menu__container">
          <div class="page-menu__menu">
            <ul class="page-menu__list">
              <li v-for="menuItem of menuList.slice(
                         0, Math.round(menuList.length/2))">
                <span class="split-link">
                  <a class="normal _text" :href="menuItem.path"
                      :target="menuItem.target" @click="onItemClick(menuItem)"
                  >
                    {{ menuItem.title }}
                  </a>
                  <a class="normal-hovered _hover" :href="menuItem.path"
                      :target="menuItem.target" @click="onItemClick(menuItem)"
                  >
                    {{ menuItem.title }}
                  </a>
                </span>
              </li>
            </ul>
            <ul class="page-menu__list">
              <li v-for="menuItem of menuList.slice(
                         Math.round(menuList.length/2), menuList.length)">
                <span class="split-link">
                  <a class="normal _text" :href="menuItem.path"
                      :target="menuItem.target"  @click="onItemClick(menuItem)"
                  >
                    {{ menuItem.title }}
                  </a>
                  <a class="normal-hovered _hover" :href="menuItem.path"
                      :target="menuItem.target" @click="onItemClick(menuItem)"
                  >
                    {{ menuItem.title }}
                  </a>
                </span>
              </li>
            </ul>
          </div>
          <a v-if="user.is_authorized" class="logout-button"
             @click="onLogoutBtnClick"
          >
            <span class="_icon-back"></span><span>Log out</span>
          </a>

          <a v-if="!user.is_authorized" class="login-button"
             @click="onLoginBtnClick"
          >
            LOGIN
          </a>
          <div class="page-menu__social-links">
            <p>Networks</p>
            <SocialLinks />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Auth from '@/lib/auth';
import { showLoginModal } from '@/lib/modal_utils'
import SocialLinks from './social_links'

export default {
    name: 'Menu',
    emits: ['onClick'],
    props: {
        user: Object,
        menuList: Array,
        isOpened: Boolean
    },
    components: {
        SocialLinks
    },
    methods: {
        onLoginBtnClick() {
            showLoginModal();
        },

        onLogoutBtnClick() {
            Auth.logout({
                success: this.onLogoutSuccess,
                error: this.onLogoutError,
            });
        },

        onLogoutSuccess() {
            this.$router.go();
        },

        onLogoutError: function () {
            console.log('------------ TODO AUTH LOGOUT HANDLE ERROR!')
        },

        onItemClick(item) {
            this.$emit('onClick', item);
        }
    }
}

</script>
