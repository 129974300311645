import {gsap} from "@/assets/js/gsap.min";

export const scrollToElement = (el, timeout, shift) => {
    timeout = timeout || 50;
    shift = parseInt(shift) || 0;
    const rect = el.getBoundingClientRect();
    const cords = rect.top + window.scrollY + shift;
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            window.scrollTo({
                top: cords,
                behavior: 'smooth'
            });
            let intervalCounter = 0;
            const intervalTimeout = 50;
            const intervalTTL = 2000;
            const interval = setInterval(() => {
                let diff = Math.abs(cords - window.scrollY);
                if (diff < 0.5 || intervalCounter >= intervalTTL) {
                    clearInterval(interval);
                    resolve();
                }
                intervalCounter += intervalTimeout;
            }, intervalTimeout);
        }, timeout);
    });

};

export const loadImage = (imgObj, imgSrc) => {
    return new Promise((resolve, reject) => {
        imgObj.onload = () => {
            resolve({img: imgObj, status: true});
        };
        imgObj.onerror = () => {
            resolve({img: imgObj, status: false});
        };

        if (!imgSrc) {
            resolve({img: imgObj, status: false});
        } else {
            imgObj.src = imgSrc;
        }
    });
};

export const loadImages = (imagesData) => {
    const imgProms = [];
    imagesData.forEach(item => {
        imgProms.push(loadImage(item.imgObj, item.imgSrc));
    });
    return Promise.all(imgProms);
}

export const loadAndAppendImageAnimated = (imgContainer, imgObj, imgSrc) => {
    return loadImage(imgObj, imgSrc).then(() => {
        imgContainer.appendChild(imgObj);
        gsap.fromTo(
            imgObj,
            {opacity: 0},
            {duration: 0.2, opacity: 1}
        );
    });
};

export const getDate = (dateString) => {
    if (dateString === undefined) {
        return dateString;
    }

    if (typeof dateString === 'string' || dateString instanceof String) {
        dateString = new Date(dateString);
    }

    if (dateString instanceof Date) {
        dateString.setHours(0);
        dateString.setMinutes(0);
        dateString.setSeconds(0);
        dateString.setMilliseconds(0);
    } else {
        throw new Error(`Parameter should be instance of Date or String, ${dateString}, ${typeof dateString}`);
    }
    return dateString;
};
