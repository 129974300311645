import Crypto from "crypto";
import { appFetch } from '@/lib/request_utils';


export default {
    login: ({email, password, success, error}) => {
        let hashPass;
        if ( password !== '' ) {
            let sha256 = Crypto.createHash("sha256");
            sha256.update(password);
            hashPass = sha256.digest("base64");
        }

        appFetch('/user/login', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                email: email,
                password: hashPass
            })
        }).then(
            res => res.json()
        ).then(res => {
            success(res);
        }).catch(error);
    },

    logout: ({success, error}) => {
        appFetch('/user/logout', {
            method: 'POST',
            credentials: 'include'
        }).then(
            res => res.json()
        ).then(res => {
            success(res);
        }).catch(error);
    },

    getUser: ({success, error}) => {
        appFetch('/user', {
            method: 'GET',
            credentials: 'include'
        }).then(
            res => res.json()
        ).then(res => {
            success(res);
        }).catch(error);
    },

    sendEmailForReset: ({email, success, error}) => {
        appFetch('/user/send_email_for_reset', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                email: email
            })
        }).then(
            res => res.json()
        ).then(res => {
            success(res);
        }).catch(error);
    },

    confirmResetPassword: ({password, token, success, error}) => {
        let hashPass;
        if ( password !== '' ) {
            let sha256 = Crypto.createHash("sha256");
            sha256.update(password);
            hashPass = sha256.digest("base64");
        }

        appFetch('/user/reset_password_confirm', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                token: token,
                password: hashPass
            })
        }).then(
            res => res.json()
        ).then(res => {
            success(res);
        }).catch(error);
    }
}