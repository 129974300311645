<template>
    <div class="ntp-container-y">
      <h2 class="ntp-section-title">
        <span>Leaderboard</span>
      </h2>
      <div class="ntp-container-x" :class="{'ntp-is-stand-mode': isStandMode}">
          <TournamentLeaderboardComponent
            :leaderboard-data="leaderboardData"
            :date-locale="dateLocale"
            :date-time-zone="dateTimeZone"
            :is-stand-mode="isStandMode"
          />
      </div>
    </div>
</template>

<script>

import TournamentLeaderboardComponent from "@/pages/tournament/components/tournament_leaderboard_component";


export default {
    name: "TournamentLeaderboardSection",
    props: {
        leaderboardData: {
            type: Array,
            default: [],
        },
        dateLocale: {
            type: String,
            default: undefined,
        },
        dateTimeZone: {
            type: String,
            default: undefined,
        },
        isStandMode: {
            type: Boolean
        }
    },
    components: {
        TournamentLeaderboardComponent,
    }
}
</script>

