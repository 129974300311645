<template>
    <div class="game-verification-page">
        <div v-if="isLoading">loading</div>
        <GameVerificationValid
            v-if="isValid"
            :icon-url="data.iconUrl"
            :game-title="data.gameTitle"
            :session-duration="data.sessionDuration"
            :support-email="data.supportEmail"
        />
        <GameVerificationInvalid v-if="isInvalid" />
    </div>
</template>

<script>
import { appFetchJSON, processFetchError } from '@/lib/request_utils';

import GameVerificationValid from "./game_verification_valid";
import GameVerificationInvalid from "./game_verification_invalid";

const STATE_LOADING = 'loading';
const STATE_VALID = 'valid';
const STATE_INVALID = 'invalid';

export default {
    name: "GameVerification",
    components: {
        GameVerificationValid,
        GameVerificationInvalid,
    },

    data() {
        return {
            state: STATE_LOADING,
            data: {
                gameTitle: undefined,
                iconUrl: undefined,
                sessionDuration: undefined,
                supportEmail: undefined,
            }
        }
    },

    computed: {
        isLoading() {
            return this.state === STATE_LOADING;
        },
        isValid() {
            return this.state === STATE_VALID;
        },
        isInvalid() {
            return this.state === STATE_INVALID;
        }
    },

    async mounted() {
        await this.updateVerificationState();
    },

    methods: {
        async updateVerificationState() {
            const validationKey = this.$route.query.key;

            const options = {
                params: {
                    key: validationKey
                }
            }

            return appFetchJSON(`/games/verify`, options)
                .then(res => {
                    const rData = res?.data;
                    const isAuthentic = rData?.['is_authentic'];
                    if (isAuthentic) {
                        const data = this.data;
                        data.gameTitle = rData['game_title'];
                        data.iconUrl = rData['icon_url'];
                        data.sessionDuration = rData['session_duration'];
                        data.supportEmail = rData['support_email'];
                        this.state = STATE_VALID
                    } else {
                        this.state = STATE_INVALID;
                    }
                })
                .catch(err => {
                    processFetchError(this.$router, err);
                });

        },
    }

};
</script>

<style>
body {
    background-color: black;
}
</style>

<style lang="scss">

</style>