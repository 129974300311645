<template>
  <main class="page article-page">
    <div class="page__body article-page__body">

      <div class="first-screen offset-section_small first-screen_left">
        <div class="_container">

          <!-- ==================================== BREADCRUMBS ========================================= -->
          <BreadCrumbs :links="breadCrumbsLinks"/>
          <!-- ==================================== BREADCRUMBS ========================================= -->

        </div>
      </div>

      <div class="_container">
        <NewsArticleBlock :article="article"/>
      </div>

      <!-- =================================== NEWS SECTION ===================================  -->
      <NewsRecommendedBlock :article="article"/>
      <!-- =================================== NEWS SECTION ===================================  -->
    </div>
  </main>
</template>

<script>

import {appFetchJSON, processFetchError} from "@/lib/request_utils";

import BreadCrumbs from "@/components/breadcrumbs";
import NewsArticleBlock from "@/pages/news_article/news_article_block";
import NewsRecommendedBlock from "@/pages/news_article/news_recommended_block";

export default {
    name: "NewsArticle",

    components: {
        BreadCrumbs,
        NewsArticleBlock,
        NewsRecommendedBlock,
    },

    data() {
        return {
            article: {},
            categoryUID: undefined,
            breadCrumbsLinks: [{title: 'News', href: '/news'}],
        }
    },

    mounted() {
        this.fetchItem().then(() => {
            this.updateBreadCrumbs();
        });
    },

    methods: {
        fetchItem() {
            let url = `/news/articles/${this.$route.params.article}`;
            return appFetchJSON(url)
                .then(res => {
                    this.article = res.data.items[0];
                    this.categoryUID = this.article["category_uid"];
                })
                .catch(err => {
                    processFetchError(this.$router, err);
                });
        },
        updateBreadCrumbs() {
            this.breadCrumbsLinks = [
                {title: 'News', href: '/news'},
                {title: this.article.title}
            ];
        },
    }
}
</script>

<style scoped>

</style>