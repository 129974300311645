<template>
    <div class="home-page__links-section offset-section vacancies-block">
        <div id="touch-slider-2" class="links-container _container _container_remove touch-slider _swiper" ref="swiper">
            <div class="swiper-wrapper">
                <VacanciesBlockCard
                    v-for="i in items"
                    :item="i"
                    class="swiper-slide"
                />
            </div>
        </div>
        <div class="_container">
            <a :href="accountUrl" class="page-button" target="_blank">
                <div></div>
                <div>view all</div>
                <div></div>
            </a>
        </div>
    </div>
</template>

<script>
import Swiper from "@/assets/js/Swiper";
import {isMobile} from "@/lib/browser";

import VacanciesBlockCard from './vacancies_block_card'

export default {
    name: "VacanciesBlock",
    components: {
        VacanciesBlockCard,
    },
    props: {
        items: Array,
        accountUrl: String,
    },

    mounted() {
        this.initSlider();
    },

    methods: {
        initSlider() {
            let swiper = new Swiper(
                this.$refs.swiper,
                {
                    observer: true,
                    observeParents: true,
                    slidesPerView: 'auto',
                    simulateTouch: true,
                    grabCursor: true,
                }
            );

            // TODO: research
            const matchMedia = window.matchMedia('(max-width: 1144px)')
            matchMedia.addListener(() => {
                swiper.init()
            })

            if (window.innerWidth <= 1144) {
                swiper.init()
            }

            if (isMobile.any()) {
                swiper.init()
            }

            this.swiper = swiper;
        }
    }
}
</script>
