<template>
  <a :href="url" class="pdf-card" :target="target" :rel="rel">
    <div v-if="showBadge" class="pdf-card__title">
      <span class="badge">unpublished</span>
    </div>
    <div v-if="showCardTitle && !showBadge" class="pdf-card__title">
      <span>Document</span>
    </div>
    <div class="pdf-card__body" :class="iconClass ? iconClass : '_icon-download'">
      <p v-html="title"></p>
    </div>
  </a>
</template>

<script>

export default {
    name: "DocumentCard",
    props: {
        title: String,
        url: String,
        showCardTitle: Boolean,
        iconClass: String,
        showBadge: Boolean,
        target: String,
    },

    computed: {
        rel() {
            if (this.target === '_blank') {
                const url = this.url || '';
                if (!(url.startsWith('/') || url.startsWith(window.location.origin))) {
                    return 'nofollow noopener noreferrer';
                }
            }
            return '';
        }
    }
}
</script>