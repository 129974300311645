<template xmlns="http://www.w3.org/1999/html">
  <main class="tournament-page" ref="root" v-if="isPreloadComplete">

    <component is="style">
      .tournament-name-custom {
      {{ titleStyle }}
      }
      .tournament-description-custom {
      {{ descriptionStyle }}
      }
    </component>

    <div class="tournament-page__background" :style="bgStyle"></div>
    <div class="tournament-page__header">
      <div class="top-bg">
        <div class="bg-image" :style="bannerStyle"></div>
        <div class="title">
          <span
              class="tournament-name"
              :class="{'tournament-name-default': !titleStyle, 'tournament-name-custom': !!titleStyle}"
              v-html="tournamentTitle"
          ></span>
          <span
              class="tournament-description"
              :class="{'tournament-description-default': !descriptionStyle, 'tournament-description-custom': !!descriptionStyle}"
              v-html="tournamentDescription"
          ></span>
        </div>
      </div>

      <TournamentLoginForm :user="user" :player="player" :tournament_uid="tournament.uid"/>

    </div>

    <TournamentTimePlate
        :tournament="tournament"
        :date-locale="dateLocale"
        :date-time-zone="dateTimeZone"
    />

    <TournamentLeaderboardSection
        :leaderboard-data="leaderboardData"
        :date-locale="dateLocale"
        :date-time-zone="dateTimeZone"
    />

    <TournamentGamesSection
        :player="player"
        :tournament="tournament"
        @on-login-click="onGameSectionLoginClick"
    />

    <TournamentPrizesSection
        :prizes-by-place="prizesByPlace"
        :date-locale="dateLocale"
        :date-time-zone="dateTimeZone"
    />

    <TournamentFooterSection :tournament="tournament"/>

  </main>

</template>

<script>

import TournamentLoginForm from "@/pages/tournament/tournament_login_form";
import {appFetchJSON, processFetchError} from "@/lib/request_utils";
import DateWidget from "@/components/date_widget";
import TournamentTimePlate from "@/pages/tournament/tournament_time_plate";
import TournamentLeaderboardSection from "@/pages/tournament/tournament_leaderboard_section";
import TournamentPrizesSection from "@/pages/tournament/tournament_prizes_section";
import TournamentFooterSection from "@/pages/tournament/tournament_footer_section";
import {scrollToElement} from "@/lib/common_utils";
import TournamentGamesSection from "@/pages/tournament/tournament_games_section";

const TOURNAMENT_STATUS_UPDATE_INTERVAL = 20000;

export default {
    name: "Tournament",
    props: {
        user: Object,
    },

    components: {
        TournamentGamesSection,
        TournamentPrizesSection,
        TournamentLeaderboardSection,
        TournamentTimePlate,
        TournamentFooterSection,
        DateWidget,
        TournamentLoginForm,
    },

    data() {
        return {
            tournament: {},
            leaderboardData: [],
            isLeaderboardInited: false,
            prizesByPlace: [],
            isPrizePoolInited: false,
            player: {},
            currentGame: undefined,
            dateLocale: 'uk',
            dateTimeZone: 'UTC',
            isPreloadComplete: false,
        }
    },

    mounted() {
        const authPromise = this.auth();
        const tournamentPromise = this.fetchItem().then(() => authPromise);
        const preloaderCompletePromise = Promise.all(
            [authPromise, tournamentPromise]);
        preloaderCompletePromise
            .then(() => this.checkTournamentData())
            .then(() => {
                setInterval(() => {
                    this.checkTournamentData();
                }, TOURNAMENT_STATUS_UPDATE_INTERVAL);
            })
            .finally(() => {
                this.isPreloadComplete = true;
            })
    },

    computed: {

        tournamentTitle() {
            return this.tournament?.title || '3OAKS Tournament';
        },

        tournamentDescription() {
            let description = this.tournament?.description || 'Are you ready?';
            if (!this.tournament.is_published) {
                description = `${description} (NOT PUBLISHED)`;
            }
            return description;
        },

        bgStyle() {
            const bgImageURL = this.tournament.bg_image_url;
            if (bgImageURL) {
                return `background-image: url('${bgImageURL}') !important;`;
            }
            return ''
        },
        bannerStyle() {
            const bannerURL = this.tournament.banner_url;
            if (bannerURL) {
                return `background-image: url('${bannerURL}') !important;`;
            }
            return ''
        },
        titleStyle() {
            return this.tournament?.title_style || ''
        },

        descriptionStyle() {
            return this.tournament?.description_style || ''
        },

        needUpdateLeaderboard() {
            const tournament = this.tournament;
            return (tournament && tournament.status === 'STARTED')
                || !this.isLeaderboardInited
        },

        needUpdatePrizePool() {
            const tournament = this.tournament;
            return (tournament && tournament.status === 'STARTED' && this.hasProgressivePrize)
                || !this.isPrizePoolInited
        }
    },

    methods: {

        auth() {
            const url = `/tournaments/player`;
            return appFetchJSON(url)
                .then(res => {
                    this.player = res.data || {};
                })
                .catch(err => {
                    processFetchError(this.$router, err);
                });
        },

        fetchItem() {
            const tournamentNameOrDomain = this.$route.params.tournamentNameOrDomain;
            const url = `/tournaments/${tournamentNameOrDomain}`;
            const params = {};
            if (this.$route.query.domain) {
                params.domain = '1';
            }
            return appFetchJSON(url, {params: params, credentials: 'include'})
                .then(res => {
                    const tournament = res.data?.data;
                    if (!tournament) {
                        return res
                    }

                    const games = tournament?.games || [];
                    tournament.gamesMap = {};
                    games.forEach((item) => {
                        tournament.gamesMap[item['name']] = item;
                    });

                    this.tournament = tournament;
                })
                .catch(err => {
                    processFetchError(this.$router, err);
                });
        },

        onGameSectionLoginClick() {
            scrollToElement(this.$refs.root);
        },

        sortByPlace(a, b) {
            const aPlace = a.place;
            const bPlace = b.place;
            if (aPlace > bPlace) return 1;
            if (aPlace < bPlace) return -1;
            return 0;
        },

        checkTournamentData() {
            if (this.checkTournamentDataPromise) {
                return;
            }
            const tournament = this.tournament;
            if (!tournament) {
                return;
            }

            const url = `/tournaments/public`;
            const params = {
                campaign_uid: tournament.campaign_uid,
                campaign_type: tournament.campaign_type,
                currency: tournament.currency,
                prize_pool: this.needUpdatePrizePool,
                leaderboard: this.needUpdateLeaderboard,
            }

            this.checkTournamentDataPromise = appFetchJSON(url, {params: params})
                .then((res) => {
                    const data = res.data || {};
                    if (data.status && data.status !== tournament.status) {
                        tournament.status = data.status
                    }

                    this.updatePrizePoolData(data['prize_pool']);
                    this.isPrizePoolInited = true;
                    this.updateLeaderboardData(data['leaderboard']);
                    this.isLeaderboardInited = true;
                })
                .finally(() => {
                    this.checkTournamentDataPromise = undefined;
                })
            return this.checkTournamentDataPromise;
        },

        updatePrizePoolData(data) {
            const prizes = data?.prizes;
            if (!Array.isArray(prizes) || prizes.length === 0) {
                return;
            }
            prizes.sort(this.sortByPlace);
            const tournament = this.tournament;
            prizes.forEach((item) => {
                const prize = item.prize;
                const prizeGameName = prize['game_name'];
                if (prizeGameName) {
                    prize['game_title'] = tournament.gamesMap?.[prizeGameName]?.['title_text'] || '';
                }

                const prizeType = prize.type;
                if (['MONEY', 'PROGRESSIVE', 'FREEBET'].indexOf(prizeType) !== -1) {
                    prize['currency'] = tournament.currency;
                    if (prizeType === 'PROGRESSIVE') {
                        this.hasProgressivePrize = true;
                        prize.progressive = data.progressive;
                    }
                }
            })
            this.prizesByPlace = prizes;
        },

        updateLeaderboardData(data) {
            if (!Array.isArray(data) || data.length === 0) {
                return;
            }

            data.forEach((item) => {
                item.place = parseInt(item.place);

                this.prizesByPlace.forEach(prizeItem => {
                    if (prizeItem.place === item.place) {
                        item.prize = prizeItem.prize;
                    }
                });

                const score = Math.floor(parseFloat(item.score * 100)) / 100;
                item.score = score.toFixed(2);
            });


            //mock
            // const dataLen = data.length;
            // for (let i=0; i < 100; i++) {
            //     const item = {...data[0]};
            //     item.place += dataLen + i;
            //     data.push(item);
            // }

            data.sort(this.sortByPlace);

            this.leaderboardData = data;
        }
    }
}
</script>

<style scoped>

</style>