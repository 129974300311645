<template>
  <div class="_cards-grid" v-if="campaigns.length > 0">
    <div class="_cards-grid__item" v-for="item of campaigns">
      <!-- ============================== GAME CARD ============================== -->
      <CampaignGridCard :class="{loading: loading}" :campaign="item" />
      <!-- ============================== GAME CARD ============================== -->
    </div>
  </div>
  <div v-if="!campaigns.length && !loading">
    There are no campaigns in the list
  </div>
</template>

<script>

import CampaignGridCard from "@/pages/campaigns/campaign_grid_card";

export default {
    name: "CampaignGrid",
    components: {
        CampaignGridCard,
    },
    props: {
        campaigns: Array,
        loading: Boolean,
    },
    data() {
        return {
            dateOptions: {day: 'numeric', month: 'short'}
        }
    }
}
</script>

<style scoped>
</style>