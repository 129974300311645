<template>
    <section class="ntp-tournament__rules" v-if="ruleBlocks.length > 0 && isStandMode">
        <div class="ntp-rules-content"
            :class="{'ntp-is-stand-mode': isStandMode}">
            <div
                v-for="item in ruleBlocks"
                class="ntp-rules-content-item"
            >
                <img v-if="item.iconUrl" :src="item.iconUrl" class="ntp-rules-content-image" />
                <img v-else src="@/assets/img/tournaments/demo-stand/rules-default-icon.svg" class="ntp-rules-content-image" />
                <div class="ntp-rules-content-text" v-html="item.description"></div>
            </div>
        </div>
    </section>
    <section class="ntp-tournament__rules" v-if="ruleBlocks.length > 0 && !isStandMode">
          <div class="ntp-rules-content">
              <div
                  v-for="item in ruleBlocks"
                  class="ntp-rules-content-item"
              >
                  <img v-if="item.iconUrl" :src="item.iconUrl" class="ntp-rules-content-image" />
                  <img v-else src="@/assets/img/tournaments/demo-stand/rules-default-icon.svg" class="ntp-rules-content-image" />
                  <div class="ntp-rules-content-text" v-html="item.description"></div>
              </div>
          </div>
      </section>
</template>

<script>
export default {
    name: "TournamentRulesSection",
    props: {
        tournament: {},
        isStandMode: {
          type: Boolean
        }
    },

    computed: {
        ruleBlocks () {
            const tournament = this.tournament || {};
            const data = [];
            let description;
            for (let i = 1; i <= 4; i++) {
                description = tournament[`rule_block_${i}_description`];
                if (description) {
                    data.push(
                        {
                            iconUrl: tournament[`rule_block_${i}_icon_url`],
                            description: description,
                        }
                    );
                }
            }
            return data;
        }
    }

}
</script>

