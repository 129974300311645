<template>
  <main class="page campaigns-user">
    <div class="page__body campaigns-user__body">
      <div class="first-screen offset-section_small first-screen_full">
        <div class="_container">

          <!-- ==================================== BREADCRUMBS ========================================= -->
          <BreadCrumbs
              :links="[{title: 'Campaigns'}]"
          />
          <!-- ==================================== BREADCRUMBS ========================================= -->

          <div class="first-screen__body">
            <div class="first-screen__left">
              <div class="page-heading page-heading_colors">
                <h1>
                  <span>Campaigns <em>Roadmap</em></span>
                </h1>
              </div>

              <div class="page-description _icon-arrow-2 page-description_offset" ref="page-scroll-root">
                <p>
                  We are showing our roadmap because we want our players & partners know that we still have much of
                  delight & fun to offer in the coming future. We guarantee consistently growing excitement from our
                  games!
                </p>
              </div>
            </div>
            <div class="first-screen__right"></div>
          </div>
        </div>
      </div>

      <div class="_container">
        <div class="news-grid">

          <div class="tabs-block _scr-item _tabs">

            <div class="_tabs-mobile">

              <div
                  class="tabs-mobile__value _icon-arrow-1"
                  @click.prevent="onSelectBoxClick"
              >
                {{ this.searchFilters.status }}
              </div>

              <nav
                  class="tabs-block__nav tabs-mobile__options"
                  :class="{'active': isStatusesContainerActive}"
              >
                <router-link
                    v-for="item of STATUSES"
                    :to="{name: 'client-area-campaigns', query: {status: item}}"
                    class="tabs-block__item _tabs-item"
                    :class="{_active: item === this.searchFilters.status}"
                >
                  {{ STATUSES_NAMES[item] }}
                </router-link>
              </nav>
            </div>

            <div class="tabs-block__body">
              <div class="tabs-block__block _tabs-block _active">
                <CampaignGrid
                    :campaigns="campaigns"
                    :loading="loading"
                />
              </div>
            </div>

          </div>

        </div>
      </div>

      <div>
        <Paging
            :total-pages="totalPages"
            :current-page="searchFilters.pageNum"
            @on-page-click="onPageClick"
        />
      </div>

    </div>
  </main>
</template>

<script>

import BreadCrumbs from "@/components/breadcrumbs";
import Paging from "@/components/paging";
import {appFetchJSON} from "@/lib/request_utils";
import CampaignGrid from "@/pages/campaigns/campaign_grid";
import {scrollToElement} from "@/lib/common_utils";

const STATUS_PREVIOUS = 'previous'
const STATUS_LIVE = 'live'
const STATUS_UPCOMING = 'upcoming'
const STATUSES = [STATUS_UPCOMING, STATUS_LIVE, STATUS_PREVIOUS];
const STATUSES_NAMES = {
    [STATUS_UPCOMING]: 'Upcoming',
    [STATUS_LIVE]: 'Live',
    [STATUS_PREVIOUS]: 'Previous'
}


export default {
    name: "Campaigns",
    components: {
        BreadCrumbs,
        CampaignGrid,
        Paging,
    },

    data() {
        return {
            campaigns: [],
            campaignStatus: '',
            totalPages: 0,
            searchFilters: {
                status: '',
                pageNum: 1,
            },
            loading: false,
            isStatusesContainerActive: false,
        }
    },

    setup() {
        return {
            STATUS_PREVIOUS,
            STATUS_LIVE,
            STATUS_UPCOMING,
            STATUSES,
            STATUSES_NAMES,
        }
    },

    created() {
        window.addEventListener("resize", this.onResize);
    },

    destroyed() {
        window.removeEventListener("resize", this.onResize);
    },


    mounted() {
        this.getData();
    },

    watch: {
        '$route.query'() {
            this.isStatusesContainerActive = false;
            this.getData();
            this.$nextTick(() => {
                scrollToElement(this.$refs["page-scroll-root"], 0);
            });
        }
    },

    methods: {
        fetchCampaigns() {
            let itemsPerPage = 12;
            let innerWidth = window.innerWidth;
            if (innerWidth < 641) {
                itemsPerPage = 8;
            } else if (innerWidth <= 768) {
                itemsPerPage = 9;
            } else if (innerWidth < 1144) {
                itemsPerPage = 8;
            } else if (innerWidth <= 1919) {
                itemsPerPage = 9;
            }

            let options = {
                method: 'GET',
                params: {
                    status: this.searchFilters.status,
                    page_num: this.searchFilters.pageNum,
                    items_per_page: itemsPerPage,
                }
            };
            return appFetchJSON(`/campaigns`, options)
                .then(res => {
                    const data = res.data;
                    let campaigns = data['campaigns'];
                    let promises = [];
                    campaigns.forEach(c => {
                        let routerResolveParams = {name: 'client-area-campaigns-campaign', params: {name: c.slug}};
                        c.url = this.$router.resolve(routerResolveParams).fullPath;

                        let promise = new Promise((resolve, reject) => {
                            let logoImage = c.logoImage = new Image();
                            logoImage.onload = resolve;
                            logoImage.onerror = resolve;
                            logoImage.src = c.logo;
                        });
                        promises.push(promise);
                    });
                    return Promise.all(promises).then(() => data);
                })
                .then(data => {
                    this.campaigns = data['campaigns'];
                    this.totalPages = data['total_pages'];
                });
        },

        onPageClick(pageNum) {
            let routeData = {
                name: 'client-area-campaigns',
                query: {...this.$route.query || {}, page: pageNum}
            };
            this.$router.replace(routeData);
        },

        updateFilterData() {
            this.searchFilters.status = this.$route.query.status || STATUS_LIVE;
            this.searchFilters.pageNum = Number(this.$route.query.page) || 1;
        },

        getData() {
            this.loading = true;
            this.updateFilterData();
            return this.fetchCampaigns().then(() => {
                this.$nextTick(() => {
                    this.loading = false;
                });
            });
        },

        onSelectBoxClick() {
            this.isStatusesContainerActive = !this.isStatusesContainerActive;
        },

        onResize() {
            if (this.resizeTimeout) {
                clearInterval(this.resizeTimeout);
            }
            this.resizeTimeout = setTimeout(() => {
                this.resizeTimeout = undefined;
                this.getData().then(() => {
                    const pageNum = this.searchFilters.pageNum || 0;
                    const totalPages = this.totalPages || 0;
                    if (pageNum > totalPages) {
                        this.onPageClick(totalPages)
                    }
                });
            }, 600);
        },
    }
}
</script>

<style scoped>

</style>