<template>
  <div class="page-form">
    <form class="form">
      <div class="form__wrapper">
        <div class="form__item" :class="{ _error: !!errors.email }">
          <label class="form__label" for="formEmailLogin">
            Email
          </label>
          <input id="formEmailLogin" type="text" name="email"
                 class="form__input _email _req"
                 placeholder="Your email"
                 v-model="email" :class="{ _error: !!errors.email }">
          <div class="form__error _icon-error">
            {{ (errors.email || []).join(', ') }}
          </div>
        </div>
        <div class="form__item" :class="{ _error: !!errors.password }">
          <label class="form__label" for="formPassword">
            Password
          </label>
          <input  id="formPassword" name="password"
                  class="form__input _req _password"
                  placeholder="Your password"
                  minlength="4" v-model="password"
                  :class="{ _error: !!errors.email }"
                  :type="showPassword? 'text': 'password'" >
          <div class="form__icon _icon-hide form__viewpass"
               @click="onShowPasswordBtnClick"
               :class="{ _active: showPassword }"></div>
          <div class="form__error _icon-error">
            {{ (errors.password || []).join(', ') }}
          </div>
        </div>
      </div>
      <div class="form__controls">
        <div class="form__submit">
          <button type="button" class="page-button" @click="onLoginBtnClick">
            <span></span><span>Login</span><span></span>
          </button>
        </div>
        <div class="form__policy">
          <a href = "#" @click="onForgotBtnClick">
            Forgot password?
          </a>
        </div>
      </div>
    </form>
  </div>
</template>


<script>
import Auth from '@/lib/auth';
import { showForgotPasswordModal } from '@/lib/modal_utils';

export default {
    name: "LoginForm",

    events: ['close', 'loginSuccess'],

    props: {
        eventsOnly: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            email: '',
            password: '',
            showPassword: false,
            errors: {
                email: '',
                password: ''
            }
        };
    },

    methods: {
        onLoginBtnClick() {
            Auth.login({
                email: this.email,
                password: this.password,
                success: this.onLoginSuccess,
                errors: this.onLoginError
            });
        },

        onLoginSuccess(res) {
            this.errors = res.errors || {};
            if (res.msg === 'login successful') {
                if (this.eventsOnly) {
                     this.$emit('loginSuccess');
                } else if (this.$route.matched.some((route) => route.name === 'client-area')) {
                    this.$emit('close', {pageReload: true});
                } else {
                    window.location.href = this.$router.resolve({name: 'client-area'}).fullPath;
                }
            }
        },

        onLoginError() {
            console.log('------------ TODO AUTH LOGIN HANDLE ERROR!')
        },

        onForgotBtnClick() {
            this.$emit('close', {pageReload: false});
            showForgotPasswordModal();
        },

        onShowPasswordBtnClick() {
            this.showPassword = !this.showPassword;
        },
    }
}
</script>

