<template>
  <div>
    <div class="_container">
      <h6 class="offset-title"><slot/></h6>
      <div id="touch-slider-2" class="news-container touch-slider _swiper">
        <!-- =================== NEWS CARD ANIMATION =================== -->
        <div class="news-card">
          <div class="news-card__image _ibg">
            <a href="article.html">
              <img src="/assets/img/news-1.png" alt="3oaks">
            </a>
          </div>
          <div class="news-card__tags">
            <ul>
              <li>General</li>
              <li>4 Aug 2021</li>
            </ul>
          </div>
          <div class="news-card__description">
            <h5>3 Oaks grows latam footprint with doradobet</h5>
          </div>
          <a class="news-card__read-more _icon-arrow-2" href="article.html">
            READ MORE
          </a>
        </div>
        <!-- =================== NEWS CARD ANIMATION =================== -->

        <!-- =================== NEWS CARD ANIMATION =================== -->
        <div class="news-card">
          <div class="news-card__image _ibg">
            <a href="article.html">
              <img src="/assets/img/news-2.png" alt="3oaks">
            </a>
          </div>
          <div class="news-card__tags">
            <ul>
              <li>General</li>
              <li>4 Aug 2021</li>
            </ul>
          </div>
          <div class="news-card__description">
            <h5>3 Oaks grows latam footprint with doradobet</h5>
          </div>
          <a class="news-card__read-more _icon-arrow-2" href="article.html">
            READ MORE
          </a>
        </div>
        <!-- =================== NEWS CARD ANIMATION =================== -->

        <!-- =================== NEWS CARD ANIMATION =================== -->
        <div class="news-card">
          <div class="news-card__image _ibg">
            <a href="article.html">
              <img src="/assets/img/news-3.png" alt="3oaks">
            </a>
          </div>

          <div class="news-card__tags">
            <ul>
              <li>General</li>
              <li>4 Aug 2021</li>
            </ul>
          </div>

          <div class="news-card__description">
            <h5>3 Oaks grows latam footprint with doradobet</h5>
          </div>

          <a class="news-card__read-more _icon-arrow-2" href="article.html">
            READ MORE
          </a>
        </div>
        <!-- =================== NEWS CARD ANIMATION =================== -->

      </div>
    </div>
    <div class="_container">
      <a href="/news" class="page-button">
        <div></div><div>see all</div><div></div>
      </a>
    </div>
  </div>
</template>


<script>

export default {
    name: "NewsSlider",
    components: {},
}
</script>
