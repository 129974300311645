<template>
  <div class="search-form__grid-item">
    <div class="select select_search-form__select">
      <select
          name="form[]"
          class="search-form__select"
          data-default="1"
          style="display: none;"
      >
        <option
            v-for="item in options"
            :value="item.value"
            :selected="item.value === modelValue"
        >
          {{ item.title }}
        </option>
      </select>
      <div class="select__item">
        <div class="select__title">
          <div class="select__value _icon-arrow-1" @click.prevent="onSelectClick">
            <span v-html="selectedItemTitle"></span>
          </div>
        </div>
        <div class="select__options" ref="selectOptions">
          <div
              v-for="item in options"
              :data-value="item.value"
              class="select__option"
              :class="{'_hidden': item.value === modelValue}"
              @click.prevent="onSelectOptionClick(item)"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <div class="select select_search-form__select"></div>
  </div>
</template>

<script>
import {_slideToggle} from '@/assets/js/files/functions';

export default {
    name: "SearchFormSelect",

    emits: ['update:modelValue'],

    props: {
        options: {
            required: true,
            type: Array,
            default: [],
        },
        modelValue: String
    },

    computed: {
        selectedItemTitle() {
            let title = '';
            this.options.forEach((item) => {
                if (item.value === this.modelValue) {
                    title = item.title;
                }
            });
            return title;
        }
    },

    methods: {
        toggleSelectOptions(speed) {
            speed = speed || 100;
            _slideToggle(this.$refs.selectOptions, speed);
        },

        onSelectClick() {
            this.toggleSelectOptions();
        },

        onSelectOptionClick(item) {
            this.toggleSelectOptions();
            this.$emit('update:modelValue', item.value);
        },
    }
}
</script>

<style scoped>
._hidden {
    display: none;
}
</style>