<template>
    <div
        v-if="isVisible"
        @wheel.prevent
        @touchmove.prevent
        @scroll.prevent
        @click.prevent
        class="responsible-gaming-view"
    >
        <div class="responsible-gaming-view__form">
            <div class="responsible-gaming-view__text" v-if="!isDeclined">
                <div class="responsible-gaming-view__title">
                    3 Oaks Gaming actively promotes<br />responsible gambling
                </div>
                <div class="responsible-gaming-view__description">
                    To access our content, please confirm that you meet the legal age requirement
                </div>
            </div>
            <div class="responsible-gaming-view__actions"  v-if="!isDeclined">
                <button class="button-simple-borders" @click="onAcceptButtonClick">
                    Yes, I am 18 years or older
                </button>
                <button class="button-simple" @click="onDeclineButtonClick">
                    No
                </button>
            </div>
            <div class="responsible-gaming-view__declined" v-if="isDeclined">
                Unfortunately, this page can only be accessed by individuals who are 18 years or older.
            </div>
        </div>
    </div>
</template>

<script>
import { settings } from "@/lib/settings";

export default {
    name: "ResponsibleGamingView",

    data() {
        return {
            isVisible: false,
            isDeclined: false,
        };
    },

    mounted() {
        this.refreshVisibility();
    },

    methods: {
        onDeclineButtonClick() {
            this.isDeclined = true;
        },

        onAcceptButtonClick() {
            settings.RESPONSIBLE_GAMING.setSettings();
            this.refreshVisibility();
        },

        refreshVisibility() {
            this.isVisible = !settings.RESPONSIBLE_GAMING.isProcessedByUser;
        },
    },
};
</script>
