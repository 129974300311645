<template>
    <div class="pdf-card-container touch-slider _swiper" ref="swiper">
        <div class="swiper-wrapper">
            <DocumentCard
                v-for="item of documents"
                :title="item.title"
                :url="item.url"
                :show-card-title="true"
                class="swiper-slide"
                target="_blank"
                :show-badge="item['is_visible'] === false"
            />
        </div>
    </div>
</template>


<script>

import { isMozilla, isMobile } from "@/lib/browser";
import { appFetchJSON } from "@/lib/request_utils";

import Swiper from '@/assets/js/Swiper';
import DocumentCard from '@/components/document_card'

export default {
    name: "DocumentsSlider",
    components: {
        DocumentCard,
    },
    props: {
        documents: Array,
    },

    data() {
        return {
            swiper: null,
        }
    },

    mounted() {
        this.initSlider();
    },

    methods: {
        initSlider() {
            let swiper = new Swiper(this.$refs.swiper, {
                init: false,
                observer: true,
                observeParents: true,
                slidesPerView: 'auto',
                simulateTouch: true,
                grabCursor: true,
            });

            const matchMedia = window.matchMedia('(max-width: 1144px)')
            matchMedia.addListener(() => {
                swiper.init()
            })
            if (window.innerWidth <= 1144) {
                swiper.init()
            }
            if (isMobile.any()) {
                swiper.init()
            }
            this.swiper = swiper;
        }
    }
}
</script>
